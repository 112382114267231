import { JobStatuses } from "@legacy/core/utils/enums"

import useObjectDetailsRoutes from "@hooks/useObjectDetailsRoute"
import useSlugExtractor from "@hooks/useSlugExtractor"
import useUser from "@hooks/useUser"

import getJobStatusColorClass from "@utils/getJobStatusColorClass"
import getJobStatusOrSubStatusLabel from "@utils/getJobStatusOrSubStatusLabel"

import { Table } from "@organisms"
import { Cells, Columns } from "@organisms/Table/Table.types"
import { GetObjectDetailsRouteFunction, Tab } from "@organisms/Table/TableViewControls/TableViewTab/TableViewTab.types"

import { JOBS_ROUTES } from "@routes/jobs"

export default function useJobTabs(): [Tab<Job>[], Tab<Job>[]] {
    const { user } = useUser()

    const [tabCountEndpoint, tabListEndpoint] = useSlugExtractor([JOBS_ROUTES.COUNT, JOBS_ROUTES.LIGHTWEIGHT_LIST])

    const getTabObjectDetailsRoute = useObjectDetailsRoutes("Job") as GetObjectDetailsRouteFunction

    const headerByColumn = {
        id: "ID",
        service_name: "Service",
        state_label: "State",
        status_label: "Status",
        job_type: "Job Type",
        assigned_technicians: "Techs",
        date_received: "Date Received",
        client_name: "Client",
        service_location_address: "Location",
        estimated_arrival_time: "Scheduled For",
        estimated_duration: "Duration",
        last_scheduled_at: "Scheduled",
        last_status_transition_time: "Status Changed",
        last_modified_at: "Last Modified",
        last_active_at: "Active",
        last_paused_at: "Paused",
        last_on_hold_at: "On Hold",
        last_completed_at: "Completed",
        last_finalized_at: "Finalized",
        last_cancelled_at: "Cancelled",
    }

    const sortingEnabledByColumn = {
        id: true,
        service_name: true,
        state_label: false,
        status_label: true,
        job_type: false,
        assigned_technicians: false,
        date_received: true,
        client_name: true,
        service_location_address: true,
        estimated_arrival_time: true,
        estimated_duration: true,
        last_scheduled_at: true,
        last_status_transition_time: false,
        last_modified_at: true,
        last_active_at: true,
        last_paused_at: true,
        last_on_hold_at: true,
        last_completed_at: true,
        last_finalized_at: true,
        last_cancelled_at: true,
    }

    const widthByColumn = {
        id: 96,
        service_name: 224,
        state_label: 128,
        status_label: 240,
        job_type: 128,
        assigned_technicians: 80,
        date_received: 144,
        client_name: 224,
        service_location_address: 288,
        estimated_arrival_time: 224,
        estimated_duration: 112,
        last_scheduled_at: 144,
        last_status_transition_time: 160,
        last_modified_at: 144,
        last_active_at: 144,
        last_paused_at: 144,
        last_on_hold_at: 144,
        last_completed_at: 144,
        last_finalized_at: 144,
        last_cancelled_at: 144,
    }

    const pinningByColumn = {
        id: true,
        service_name: true,
        state_label: false,
        status_label: false,
        job_type: false,
        assigned_technicians: false,
        date_received: false,
        client_name: false,
        service_location_address: false,
        estimated_arrival_time: false,
        estimated_duration: false,
        last_scheduled_at: false,
        last_status_transition_time: false,
        last_modified_at: false,
        last_active_at: false,
        last_paused_at: false,
        last_on_hold_at: false,
        last_completed_at: false,
        last_finalized_at: false,
        last_cancelled_at: false,
    }

    const cellByColumn: Cells<Job> = {
        id: ({ row }) => <Table.Cells.IDCell id={row.original.custom_id || row.original.id} />,
        service_name: ({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />,
        state_label: ({ getValue }) => <Table.Cells.BadgesCell badges={[getValue()]} />,
        status_label: ({ row }) => (
            <Table.Cells.StatusCell
                status={getJobStatusOrSubStatusLabel(row.original)}
                dotStatusOverride={getJobStatusColorClass(row.original)}
            />
        ),
        job_type: ({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />,
        assigned_technicians: ({ getValue }) => <Table.Cells.UsersCell users={getValue()} />,
        date_received: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        client_name: ({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />,
        service_location_address: ({ getValue }) => (
            <Table.Cells.TruncatedTextCell content={getValue()} variant="secondary" />
        ),
        estimated_arrival_time: ({ getValue, row }) => (
            <Table.Cells.DateTimeCell
                value={getValue()}
                showTime={true}
                isDangerous={["Pending", "Scheduled"].includes(row.original.state_label) && row.original.is_past_due}
            />
        ),
        estimated_duration: ({ getValue }) => <Table.Cells.NumericalDataCell type="duration" value={getValue()} />,
        last_scheduled_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_status_transition_time: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_modified_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_active_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_paused_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_on_hold_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_completed_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_finalized_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_cancelled_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
    }

    const columnByName: Columns<Job> = {
        id: {
            header: headerByColumn["id"],
            accessorKey: "id",
            id: "id",
            size: widthByColumn["id"],
            cell: cellByColumn["id"],
            enableSorting: sortingEnabledByColumn["id"],
            enablePinning: pinningByColumn["id"],
        },
        service_name: {
            header: headerByColumn["service_name"],
            accessorKey: "service_name",
            id: "service_name",
            size: widthByColumn["service_name"],
            cell: cellByColumn["service_name"],
            enableSorting: sortingEnabledByColumn["service_name"],
            enablePinning: pinningByColumn["service_name"],
        },
        state_label: {
            header: headerByColumn["state_label"],
            accessorKey: "state_label",
            id: "state_label",
            size: widthByColumn["state_label"],
            cell: cellByColumn["state_label"],
            enableSorting: sortingEnabledByColumn["state_label"],
            enablePinning: pinningByColumn["state_label"],
        },
        status_label: {
            header: headerByColumn["status_label"],
            accessorKey: "status_label",
            id: "status_label",
            size: widthByColumn["status_label"],
            cell: cellByColumn["status_label"],
            enableSorting: sortingEnabledByColumn["status_label"],
            enablePinning: pinningByColumn["status_label"],
        },
        job_type: {
            header: headerByColumn["job_type"],
            accessorKey: "job_type",
            id: "job_type",
            size: widthByColumn["job_type"],
            cell: cellByColumn["job_type"],
            enableSorting: sortingEnabledByColumn["job_type"],
            enablePinning: pinningByColumn["job_type"],
        },
        assigned_technicians: {
            header: headerByColumn["assigned_technicians"],
            accessorKey: "assigned_technicians",
            id: "assigned_technicians",
            size: widthByColumn["assigned_technicians"],
            cell: cellByColumn["assigned_technicians"],
            enableSorting: sortingEnabledByColumn["assigned_technicians"],
            enablePinning: pinningByColumn["assigned_technicians"],
        },
        date_received: {
            header: headerByColumn["date_received"],
            accessorKey: "date_received",
            id: "date_received",
            size: widthByColumn["date_received"],
            cell: cellByColumn["date_received"],
            enableSorting: sortingEnabledByColumn["date_received"],
            enablePinning: pinningByColumn["date_received"],
        },
        client_name: {
            header: headerByColumn["client_name"],
            accessorKey: "client_name",
            id: "client_name",
            size: widthByColumn["client_name"],
            cell: cellByColumn["client_name"],
            enableSorting: sortingEnabledByColumn["client_name"],
            enablePinning: pinningByColumn["client_name"],
        },
        service_location_address: {
            header: headerByColumn["service_location_address"],
            accessorKey: "service_location_address",
            id: "service_location_address",
            size: widthByColumn["service_location_address"],
            cell: cellByColumn["service_location_address"],
            enableSorting: sortingEnabledByColumn["service_location_address"],
            enablePinning: pinningByColumn["service_location_address"],
        },
        estimated_arrival_time: {
            header: headerByColumn["estimated_arrival_time"],
            accessorKey: "estimated_arrival_time",
            id: "estimated_arrival_time",
            size: widthByColumn["estimated_arrival_time"],
            cell: cellByColumn["estimated_arrival_time"],
            enableSorting: sortingEnabledByColumn["estimated_arrival_time"],
            enablePinning: pinningByColumn["estimated_arrival_time"],
        },
        estimated_duration: {
            header: headerByColumn["estimated_duration"],
            accessorKey: "estimated_duration",
            id: "estimated_duration",
            size: widthByColumn["estimated_duration"],
            cell: cellByColumn["estimated_duration"],
            enableSorting: sortingEnabledByColumn["estimated_duration"],
            enablePinning: pinningByColumn["estimated_duration"],
        },
        last_scheduled_at: {
            header: headerByColumn["last_scheduled_at"],
            accessorKey: "last_scheduled_at",
            id: "last_scheduled_at",
            size: widthByColumn["last_scheduled_at"],
            cell: cellByColumn["last_scheduled_at"],
            enableSorting: sortingEnabledByColumn["last_scheduled_at"],
            enablePinning: pinningByColumn["last_scheduled_at"],
        },
        last_status_transition_time: {
            header: headerByColumn["last_status_transition_time"],
            accessorKey: "last_status_transition_time",
            id: "last_status_transition_time",
            size: widthByColumn["last_status_transition_time"],
            cell: cellByColumn["last_status_transition_time"],
            enableSorting: sortingEnabledByColumn["last_status_transition_time"],
            enablePinning: pinningByColumn["last_status_transition_time"],
        },
        last_modified_at: {
            header: headerByColumn["last_modified_at"],
            accessorKey: "last_modified_at",
            id: "last_modified_at",
            size: widthByColumn["last_modified_at"],
            cell: cellByColumn["last_modified_at"],
            enableSorting: sortingEnabledByColumn["last_modified_at"],
            enablePinning: pinningByColumn["last_modified_at"],
        },
        last_active_at: {
            header: headerByColumn["last_active_at"],
            accessorKey: "last_active_at",
            id: "last_active_at",
            size: widthByColumn["last_active_at"],
            cell: cellByColumn["last_active_at"],
            enableSorting: sortingEnabledByColumn["last_active_at"],
            enablePinning: pinningByColumn["last_active_at"],
        },
        last_paused_at: {
            header: headerByColumn["last_paused_at"],
            accessorKey: "last_paused_at",
            id: "last_paused_at",
            size: widthByColumn["last_paused_at"],
            cell: cellByColumn["last_paused_at"],
            enableSorting: sortingEnabledByColumn["last_paused_at"],
            enablePinning: pinningByColumn["last_paused_at"],
        },
        last_on_hold_at: {
            header: headerByColumn["last_on_hold_at"],
            accessorKey: "last_on_hold_at",
            id: "last_on_hold_at",
            size: widthByColumn["last_on_hold_at"],
            cell: cellByColumn["last_on_hold_at"],
            enableSorting: sortingEnabledByColumn["last_on_hold_at"],
            enablePinning: pinningByColumn["last_on_hold_at"],
        },
        last_completed_at: {
            header: headerByColumn["last_completed_at"],
            accessorKey: "last_completed_at",
            id: "last_completed_at",
            size: widthByColumn["last_completed_at"],
            cell: cellByColumn["last_completed_at"],
            enableSorting: sortingEnabledByColumn["last_completed_at"],
            enablePinning: pinningByColumn["last_completed_at"],
        },
        last_finalized_at: {
            header: headerByColumn["last_finalized_at"],
            accessorKey: "last_finalized_at",
            id: "last_finalized_at",
            size: widthByColumn["last_finalized_at"],
            cell: cellByColumn["last_finalized_at"],
            enableSorting: sortingEnabledByColumn["last_finalized_at"],
            enablePinning: pinningByColumn["last_finalized_at"],
        },
        last_cancelled_at: {
            header: headerByColumn["last_cancelled_at"],
            accessorKey: "last_cancelled_at",
            id: "last_cancelled_at",
            size: widthByColumn["last_cancelled_at"],
            cell: cellByColumn["last_cancelled_at"],
            enableSorting: sortingEnabledByColumn["last_cancelled_at"],
            enablePinning: pinningByColumn["last_cancelled_at"],
        },
    }

    const openJobsTabs: Tab<Job>[] = [
        {
            title: "All Open",
            tabObjectName: "Job",
            showTab: true,
            objectsPhase: "open",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", JobStatuses.pending],
                ["status", JobStatuses.scheduled],
                ["status", JobStatuses.active],
                ["status", JobStatuses.paused],
                ["status", JobStatuses.on_hold],
                ["status", JobStatuses.completed],
                ["ordering", "estimated_arrival_time"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["service_name"],
                columnByName["state_label"],
                columnByName["status_label"],
                columnByName["job_type"],
                columnByName["assigned_technicians"],
                columnByName["date_received"],
                columnByName["client_name"],
                columnByName["service_location_address"],
                columnByName["estimated_arrival_time"],
                columnByName["estimated_duration"],
                columnByName["last_status_transition_time"],
                columnByName["last_modified_at"],
            ],
        },
        {
            title: "Pending",
            tabObjectName: "Job",
            showTab: user?.user_type_label !== "Service Technician",
            objectsPhase: "open",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", JobStatuses.pending],
                ["ordering", "-id"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["service_name"],
                columnByName["status_label"],
                columnByName["job_type"],
                columnByName["assigned_technicians"],
                columnByName["date_received"],
                columnByName["client_name"],
                columnByName["service_location_address"],
                columnByName["estimated_arrival_time"],
                columnByName["estimated_duration"],
                columnByName["last_modified_at"],
            ],
        },
        {
            title: "Scheduled",
            tabObjectName: "Job",
            showTab: true,
            objectsPhase: "open",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", JobStatuses.scheduled],
                ["ordering", "estimated_arrival_time"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["service_name"],
                columnByName["status_label"],
                columnByName["job_type"],
                columnByName["assigned_technicians"],
                columnByName["date_received"],
                columnByName["client_name"],
                columnByName["service_location_address"],
                columnByName["estimated_arrival_time"],
                columnByName["estimated_duration"],
                columnByName["last_scheduled_at"],
                columnByName["last_modified_at"],
            ],
        },
        {
            title: "In Progress",
            tabObjectName: "Job",
            showTab: true,
            objectsPhase: "open",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", JobStatuses.active],
                ["status", JobStatuses.paused],
                ["ordering", "estimated_arrival_time"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["service_name"],
                columnByName["status_label"],
                columnByName["job_type"],
                columnByName["assigned_technicians"],
                columnByName["date_received"],
                columnByName["client_name"],
                columnByName["service_location_address"],
                columnByName["estimated_arrival_time"],
                columnByName["estimated_duration"],
                columnByName["last_status_transition_time"],
                columnByName["last_modified_at"],
            ],
        },
        {
            title: "On Hold",
            tabObjectName: "Job",
            showTab: true,
            objectsPhase: "open",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", JobStatuses.on_hold],
                ["ordering", "estimated_arrival_time"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["service_name"],
                columnByName["status_label"],
                columnByName["job_type"],
                columnByName["assigned_technicians"],
                columnByName["date_received"],
                columnByName["client_name"],
                columnByName["service_location_address"],
                columnByName["estimated_arrival_time"],
                columnByName["estimated_duration"],
                columnByName["last_on_hold_at"],
                columnByName["last_modified_at"],
            ],
        },
        {
            title: "Completed",
            tabObjectName: "Job",
            showTab: true,
            objectsPhase: "open",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", JobStatuses.completed],
                ["ordering", "-last_completed_at"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["service_name"],
                columnByName["status_label"],
                columnByName["job_type"],
                columnByName["assigned_technicians"],
                columnByName["date_received"],
                columnByName["client_name"],
                columnByName["service_location_address"],
                columnByName["estimated_arrival_time"],
                columnByName["estimated_duration"],
                columnByName["last_completed_at"],
                columnByName["last_modified_at"],
            ],
        },
    ]

    const closedJobsTabs: Tab<Job>[] = [
        {
            title: "All Closed",
            tabObjectName: "Job",
            showTab: true,
            objectsPhase: "closed",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", JobStatuses.finalized],
                ["status", JobStatuses.cancelled],
                ["ordering", "-id"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["service_name"],
                columnByName["state_label"],
                columnByName["job_type"],
                columnByName["assigned_technicians"],
                columnByName["date_received"],
                columnByName["client_name"],
                columnByName["service_location_address"],
                columnByName["estimated_arrival_time"],
                columnByName["estimated_duration"],
                columnByName["last_status_transition_time"],
                columnByName["last_modified_at"],
            ],
        },
        {
            title: "Finalized",
            tabObjectName: "Job",
            showTab: true,
            objectsPhase: "closed",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", JobStatuses.finalized],
                ["ordering", "-last_finalized_at"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["service_name"],
                columnByName["job_type"],
                columnByName["assigned_technicians"],
                columnByName["date_received"],
                columnByName["client_name"],
                columnByName["service_location_address"],
                columnByName["estimated_arrival_time"],
                columnByName["estimated_duration"],
                columnByName["last_finalized_at"],
                columnByName["last_modified_at"],
            ],
        },
        {
            title: "Cancelled",
            tabObjectName: "Job",
            showTab: true,
            objectsPhase: "closed",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", JobStatuses.cancelled],
                ["ordering", "-estimated_arrival_time"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["service_name"],
                columnByName["job_type"],
                columnByName["assigned_technicians"],
                columnByName["date_received"],
                columnByName["client_name"],
                columnByName["service_location_address"],
                columnByName["estimated_arrival_time"],
                columnByName["estimated_duration"],
                columnByName["last_cancelled_at"],
                columnByName["last_modified_at"],
            ],
        },
    ]

    return [openJobsTabs, closedJobsTabs]
}
