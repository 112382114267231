import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { Component } from 'react';

dayjs.extend(timezone)

class GeneratedDatetimeField extends Component {
    constructor(props) {
        super(props)

        // Populate the date and time selects
        let localTime = dayjs().tz(props.preferredTimezone).startOf("day").add(6, "hours")
        let timeOptions = []

        for (let index = 0; index < (24*4); index++) {
            let newLocalTime = dayjs(localTime).add(index * 15, "minutes")
            timeOptions.push(
                <option value={newLocalTime.format("HH:mm:00")} key={index}>{newLocalTime.format("h:mm A")}</option>
            )
        }

        this.state = {
            timeOptions: timeOptions,
            date: "",
            time: "",
            datetime: null,
        }

        if (props.fieldValue) {
            let defaultDayjs = dayjs(props.fieldValue).tz(props.preferredTimezone)
            this.state.date = defaultDayjs.format("YYYY-MM-DD")
            this.state.time = defaultDayjs.format("HH:mm:00")
            this.state.datetime = defaultDayjs.format("YYYY-MM-DDTHH:mm:00ZZ")
        }
    }

    updateValue = (fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[fieldName] = fieldValue

            // Recompute datetime
            if (state.date !== "" && state.time !== "") {
                updatedState.datetime = dayjs(state.date + "T" + state.time).tz(props.preferredTimezone).format("YYYY-MM-DDTHH:mm:00ZZ")
            }
            else {
                updatedState.datetime = null
            }

            props.fieldOnChange(updatedState.datetime)

            return updatedState
        })
    }

    render() {
        const {fieldName, fieldLabel, fieldValue, fieldOnChange, optional=false, optionalText="optional", errors} = this.props

        return (
            <div className="data-panel__form__field" id={`div_id_${fieldName}`} aria-label={fieldLabel}>
                <label htmlFor={`id_${fieldName}`} className="data-panel__form__field__label in-form">{fieldLabel}{optional && <span className="text-optional"> ({optionalText})</span>}</label>
                <div className="data-panel__form__field__input generated-datetime-field">
                    <input
                        defaultValue={this.state.date || ""}
                        onChange={event => this.updateValue(event.target.name, event.target.value)}
                        type="date"
                        id="id_date"
                        name="date"
                        placeholder="yyyy-mm-dd"
                        autoComplete="off"
                        aria-label="Date"
                    />
                    <select name="time" defaultValue={this.state.time || ""} aria-label="Time Select" autoComplete="off" onChange={event => this.updateValue(event.target.name, event.target.value)} id="id_time" style={{marginLeft: "1em"}}>
                        <option key="default" value="">AM / PM</option>
                        {this.state.timeOptions.map(option => option)}
                    </select>
                </div>
                {errors[fieldName] && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors[fieldName]}</div>}
            </div>
        )
    }
}

export default GeneratedDatetimeField;
