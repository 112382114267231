import { UserAvatarStack } from '@molecules/index';
import ObjectListCard from '../../core/components/ObjectListCard';
import AlertingRenderer from "../../core/renderers/AlertingRenderer";
import DateTimeRenderer from "../../core/renderers/DateTimeRenderer";
import StatusChipRenderer from '../../core/renderers/StatusChipRenderer';
import { getJobStatusChipLabel, getJobStatusColorClass } from '../utils/utils';


function JobListCard(props) {
    const { data, showState } = props
    const job = data

    const footerContext = {
        label: null,
        value: null
    }

    footerContext.label = "Scheduled For:"
    footerContext.value = footerContext.value = (
        <AlertingRenderer alertCondition={job.state_label === "Scheduled" && job.is_past_due}>
            <DateTimeRenderer type="datetime" value={job.estimated_arrival_time} options={{duration: job.estimated_duration, addYear: true}} />
        </AlertingRenderer>
    )

    return <ObjectListCard
        objectName="Job"
        objectID={job.custom_id || job.id}
        showState={showState}
        stateLabel={job.state_label}
        headerContext={job.job_type}
        statusChip={<StatusChipRenderer value={getJobStatusChipLabel(job)} extraClasses={getJobStatusColorClass(job).replaceAll("jobs", "status")} />}
        bodyTitle={job.service_name}
        bodySubtextPrimary={job.client_name}
        bodySubtextSecondary={job.service_location_address}
        footerContextLabel={footerContext.label}
        footerContextValue={footerContext.value}
        footerPrimary={<UserAvatarStack users={job.assigned_technicians} limit={2} avatarSize={28} />}
    />
}

export default JobListCard;
