import {
    DndContext,
    DragEndEvent,
    DragStartEvent,
    PointerSensor,
    UniqueIdentifier,
    closestCenter,
    useSensor,
    useSensors,
} from "@dnd-kit/core"
import { restrictToParentElement } from "@dnd-kit/modifiers"
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { Fragment, useEffect, useState } from "react"

import useCurrentObjectsView from "@hooks/useCurrentObjectsView"

import generateRandomKey from "@utils/generateRandomKey"

import { Divider } from "@atoms"

import { ColumnId } from "@organisms/Table/Table.types"
import TableViewMenuColumnOrderingItem from "@organisms/Table/TableViewMenu/TableViewMenuColumnOrderingItem/TableViewMenuColumnOrderingItem"

import styles from "./TableViewMenuColumnsOrder.module.scss"
import { TableViewMenuColumnsOrderProps } from "./TableViewMenuColumnsOrder.types"

export default function TableViewMenuColumnsOrder(props: TableViewMenuColumnsOrderProps) {
    const { isVisible } = props

    const { currentObjectsView, updateColumnOrder, pinColumn, unpinColumn, updateColumnPinningOrder, visibleColumns } =
        useCurrentObjectsView()

    const [allColumnsIds, setAllColumnsIds] = useState<UniqueIdentifier[]>([])
    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)

    useEffect(() => {
        if (visibleColumns.allColumns) {
            setAllColumnsIds(visibleColumns.allColumns)
        }
    }, [visibleColumns.allColumns])

    const getColumnIndex = (id: UniqueIdentifier) => allColumnsIds.indexOf(id)

    const activeIndex = activeId ? getColumnIndex(activeId) : -1

    const columnIsPinned = (columnId: ColumnId) => {
        return (currentObjectsView?.columnPinning.left ?? []).includes(columnId)
    }

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
    )

    const onDragStart = ({ active }: DragStartEvent) => {
        if (!active) {
            return
        }

        setActiveId(active.id)
    }

    const onDragEnd = ({ over }: DragEndEvent) => {
        setActiveId(null)

        if (over) {
            const overIndex = getColumnIndex(over.id)
            const isPinned = columnIsPinned(over.id as ColumnId)

            if (isPinned) {
                const newColumnPinningOrder = arrayMove(visibleColumns.pinnedColumns, activeIndex, overIndex)
                updateColumnPinningOrder(newColumnPinningOrder)
            } else if (activeIndex !== overIndex) {
                const newTableOrder = arrayMove(visibleColumns.allColumns, activeIndex, overIndex)
                updateColumnOrder(newTableOrder)
            }
        }
    }

    const onPinColumn = (columnId: ColumnId) => {
        pinColumn(columnId)
    }

    const onUnpinColumn = (columnId: ColumnId) => {
        unpinColumn(columnId)
    }

    const hasPinnedColumns = visibleColumns.pinnedColumns.length > 0

    return (
        <DndContext
            collisionDetection={closestCenter}
            onDragStart={onDragStart}
            sensors={sensors}
            onDragEnd={onDragEnd}
            modifiers={[restrictToParentElement]}
        >
            <div className={styles.base} data-no-drag={true}>
                {hasPinnedColumns && (
                    <>
                        <div className={styles.pinnedArea}>
                            <SortableContext
                                items={visibleColumns.pinnedColumns}
                                strategy={verticalListSortingStrategy}
                            >
                                {visibleColumns.pinnedColumns.map((columnId) => {
                                    return (
                                        <Fragment key={generateRandomKey()}>
                                            <TableViewMenuColumnOrderingItem
                                                isTabbable={isVisible}
                                                columnId={columnId}
                                                onPinColumn={onPinColumn}
                                                onUnpinColumn={onUnpinColumn}
                                                isPinned={true}
                                            />
                                        </Fragment>
                                    )
                                })}
                            </SortableContext>
                        </div>
                        <div className={styles.divider}>
                            <Divider contrast="low" />
                        </div>
                    </>
                )}
                <div className={styles.unpinnedArea}>
                    <SortableContext items={visibleColumns.unpinnedColumns} strategy={verticalListSortingStrategy}>
                        {visibleColumns.unpinnedColumns.map((columnId) => {
                            return (
                                <Fragment key={generateRandomKey()}>
                                    <TableViewMenuColumnOrderingItem
                                        isTabbable={isVisible}
                                        columnId={columnId}
                                        onPinColumn={onPinColumn}
                                        onUnpinColumn={onUnpinColumn}
                                        isPinned={false}
                                    />
                                </Fragment>
                            )
                        })}
                    </SortableContext>
                </div>
            </div>
        </DndContext>
    )
}
