// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuthHeader-base__F4hrQ{position:relative;display:flex;flex-direction:column;gap:var(--size-2);width:var(--size-full)}.AuthHeader-grid__A4JhC{display:grid;grid-template-columns:1fr 1fr 1fr;align-items:center;justify-content:center;width:var(--size-full);padding:var(--size-5) var(--size-0)}.AuthHeader-icon__b5FC_{width:var(--size-12);margin-right:auto;margin-left:auto;box-shadow:var(--box-shadow-xl-down)}@media only screen and (min-width: 64rem){.AuthHeader-icon__b5FC_{width:var(--size-10)}}.AuthHeader-backButton__lAC7R{margin-left:calc(var(--size-3)*-1);opacity:0}@media only screen and (min-width: 64rem){.AuthHeader-backButton__lAC7R{margin-left:0}}.AuthHeader-showBackButton__HgzmL{opacity:1}.AuthHeader-titleContainer__eHsQx{position:relative}.AuthHeader-title__WmrFq{align-self:stretch;width:var(--size-full);max-height:0;color:var(--gray-12);text-align:center;transform:translateY(-10px);opacity:0;transition:var(--duration-slow);transition-property:opacity,transform;-webkit-user-select:none;-moz-user-select:none;user-select:none;font:var(--font-mobile-heading-05);font-feature-settings:var(--feature-settings-mobile-heading-05);letter-spacing:var(--letter-spacing-mobile-heading-05)}@media only screen and (min-width: 64rem){.AuthHeader-title__WmrFq{font:var(--font-global-heading-05);font-feature-settings:var(--feature-settings-global-heading-05);letter-spacing:var(--letter-spacing-global-heading-05)}}.AuthHeader-titleActive__aGUfe{max-height:-moz-fit-content;max-height:fit-content;transform:translateY(0px);opacity:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "AuthHeader-base__F4hrQ",
	"grid": "AuthHeader-grid__A4JhC",
	"icon": "AuthHeader-icon__b5FC_",
	"backButton": "AuthHeader-backButton__lAC7R",
	"showBackButton": "AuthHeader-showBackButton__HgzmL",
	"titleContainer": "AuthHeader-titleContainer__eHsQx",
	"title": "AuthHeader-title__WmrFq",
	"titleActive": "AuthHeader-titleActive__aGUfe"
};
export default ___CSS_LOADER_EXPORT___;
