import { Indicator, Root } from "@radix-ui/react-checkbox"
import clsx from "clsx"
import { AnimatePresence, motion } from "framer-motion"
import { forwardRef, useEffect, useId } from "react"
import { useFormContext } from "react-hook-form"

import { FormControl, FormField, FormItem } from "@molecules/Form/Form"
import { InputName } from "@molecules/Form/inputs/Input/Input.types"

import { Label } from ".."
import styles from "./Checkbox.module.scss"
import { CheckboxProps } from "./Checkbox.types"

const MotionCheckIcon = ({ className }: { className?: string }) => {
    return (
        <AnimatePresence>
            <svg
                className={clsx(className)}
                width="10"
                height="7"
                viewBox="0 0 10 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <motion.path
                    d="M1 4L3.5 6L9 1"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    initial={{ pathLength: 0 }}
                    animate={{
                        pathLength: 1,
                    }}
                    transition={{ type: "spring", duration: 0.3 }}
                    exit={{ pathLength: 0 }}
                />
            </svg>
        </AnimatePresence>
    )
}

const IndeterminateIcon = ({ className }: { className?: string }) => {
    return (
        <AnimatePresence>
            <svg
                className={clsx(className)}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <motion.path
                    d="M5 8H11"
                    stroke="currentColor"
                    strokeWidth="1.75"
                    initial={{ pathLength: 0 }}
                    animate={{
                        pathLength: 1,
                    }}
                    transition={{ type: "spring", duration: 0.3 }}
                    exit={{ pathLength: 0 }}
                />
            </svg>
        </AnimatePresence>
    )
}

function Checkbox<T extends InputName = InputName>(props: CheckboxProps<T>, ref: React.Ref<HTMLButtonElement>) {
    const {
        name = "",
        id,
        colorScheme,
        size,
        defaultChecked,
        checked,
        isDisabled,
        isRequired,
        label = undefined,
        labelPosition = "right",
        ...rest
    } = props

    const defaultId = useId()

    return (
        <div className={styles.wrapper}>
            {label && labelPosition === "left" && (
                <Label
                    htmlFor={id || defaultId}
                    size={size}
                    variant={isDisabled ? "disabled" : "default"}
                    {...label}
                />
            )}
            <Root
                className={clsx(styles.base, styles[`${colorScheme}BaseColorScheme`], styles[`${size}BaseSize`])}
                defaultChecked={defaultChecked}
                checked={checked}
                disabled={isDisabled}
                required={isRequired}
                id={id || defaultId}
                {...rest}
                name={String(name)}
                ref={ref}
            >
                <Indicator
                    className={clsx(
                        styles.indicator,
                        styles[`${colorScheme}IndicatorColorScheme`],
                        styles[`${size}IndicatorSize`],
                    )}
                >
                    {(checked === "indeterminate" || defaultChecked === "indeterminate") && <IndeterminateIcon />}
                    {checked !== "indeterminate" && <MotionCheckIcon />}
                </Indicator>
            </Root>
            {label && labelPosition === "right" && (
                <Label
                    htmlFor={id || defaultId}
                    size={size}
                    variant={isDisabled ? "disabled" : "default"}
                    {...label}
                />
            )}
        </div>
    )
}

const withController = <T extends InputName>(CheckboxComponent: React.FC<Omit<CheckboxProps<T>, "isControlled">>) => {
    const Controller = forwardRef<HTMLButtonElement, CheckboxProps<T>>((props, ref) => {
        const { isControlled, displayAsUnchecked, ...rest } = props
        const form = useFormContext()

        if (isControlled) {
            return (
                <FormField
                    control={form.control}
                    name={props.name as string}
                    render={({ field }) => {
                        useEffect(() => {
                            if (props.value !== undefined) {
                                form.setValue(props.name as string, props.value)
                            }
                        }, [props.value])

                        return (
                            <FormItem>
                                <FormControl>
                                    <CheckboxComponent
                                        {...rest}
                                        {...field}
                                        checked={displayAsUnchecked ? false : (field.value as boolean)}
                                        onCheckedChange={field.onChange}
                                        onBlur={field.onBlur}
                                        ref={ref}
                                    />
                                </FormControl>
                            </FormItem>
                        )
                    }}
                />
            )
        } else {
            return <CheckboxComponent {...rest} ref={ref} />
        }
    })

    Controller.displayName = "CheckboxComponentController"

    return Controller as typeof Checkbox
}

export default withController(forwardRef(Checkbox))
