
import { Button } from '@atoms/index';
import { ResponsiveDialog } from '@molecules/index';
import { Fragment, useState } from 'react';
import { defaultStyles, FileIcon } from "react-file-icon";
import WrapperButton from "../buttons/WrapperButton";


const DISPLAYABLE_IMAGE_MIMETYPES = [
    "image/apng",
    "image/avif",
    "image/gif",
    "image/jpeg",
    "image/png",
    "image/svg+xml",
    "image/webp",
]


function AttachmentModal(props) {
    const { isOpen, setIsOpen, attachment } = props

    const handleFileDownload = async() => {
        const blob = await fetch(attachment.download_url)
        const bloburl = await blob.blob()
        const url = URL.createObjectURL(bloburl)

        const element = document.createElement("a")
        element.download = attachment.upload_filename
        element.href = url
        document.body.appendChild(element)
        element.click()
        element.remove()

        URL.revokeObjectURL(bloburl)
    }

        return (
            <ResponsiveDialog.Root
                headerTitle="Attachment details"
                onOpenChange={setIsOpen}
                isOpen={isOpen}
            >
                <ResponsiveDialog.Body>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "0.5rem" }}>
                        {
                            attachment.display_as_image
                            ?
                                <img src={attachment.download_url} alt={attachment.upload_filename} style={{"width": "100%"}} />
                            :
                            <div className="icon">
                                <FileIcon extension={attachment.upload_extension} {...defaultStyles[attachment.upload_extension]} />
                            </div>
                        }
                        <p style={{wordBreak: "break-all", padding: "0 1rem", margin: "0"}}>{attachment.upload_filename}</p>
                    </div>
                </ResponsiveDialog.Body>
                {attachment.download_url && (
                    <ResponsiveDialog.Footer>
                        <Button colorScheme="gray" variant="solid" size="md" isFullWidth={true} onClick={handleFileDownload}> Download File</Button>
                    </ResponsiveDialog.Footer>
                )}
            </ResponsiveDialog.Root>
        )
}

export default function AttachmentHorizontalDisplay(props) {
    const { attachments } = props

    const [isLightboxOpen, setIsLightboxOpen] = useState(false)
    const [selectedAttachmentIndex, setSelectedAttachmentIndex] = useState(0)

    const buildDownloadUrl = (fileHandle) => `https://cdn.filestackcontent.com/${fileHandle}?policy=${window.FILESTACK_POLICY}&signature=${window.FILESTACK_SIGNATURE}`

    const enrichedAttachments = attachments.map(attachment => (
        {
            ...attachment,
            display_as_image: DISPLAYABLE_IMAGE_MIMETYPES.includes(attachment.upload_mimetype),
            upload_extension: attachment.upload_filename.split(".").pop(),
            download_url: buildDownloadUrl(attachment.upload_handle)
        }
    ))

    function openLightbox(index) {
        setSelectedAttachmentIndex(index)
        setIsLightboxOpen(true)
    }

    return attachments.length > 0 && (
        <Fragment>
            <div className="gallery_container" style={{ display: "flex", overflow: "auto" }}>
                {enrichedAttachments.map((attachment, index) => (
                    <WrapperButton key={attachment.upload_handle} handler={() => openLightbox(index)} className="gallery_image_container">
                        {
                            attachment.display_as_image
                            ?
                            <img className="gallery_image" src={attachment.download_url} />
                            :
                            <div className="gallery_image">
                                <FileIcon extension={attachment.upload_extension} {...defaultStyles[attachment.upload_extension]} />
                            </div>
                        }
                    </WrapperButton>
                ))}
            </div>
            <AttachmentModal
                isOpen={isLightboxOpen}
                setIsOpen={setIsLightboxOpen}
                attachment={enrichedAttachments[selectedAttachmentIndex]}
            />
        </Fragment>
    )
}
