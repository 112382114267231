import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { useEffect, useRef } from "react"

import useUser from "@hooks/useUser"

import { ifSpaceOrEnter } from "@utils/keyboard"

import { NavigationItem } from "@molecules"

export default function WhatsNew() {
    const ref = useRef<HTMLDivElement>(null)

    const { user } = useUser()

    const openChangelog = () => {
        ref.current?.click()
    }

    useEffect(() => {
        window.addEventListener("openChangelog", openChangelog)

        return () => {
            window.removeEventListener("openChangelog", openChangelog)
        }
    }, [user])

    return (
        <div
            ref={ref}
            id="changelog_trigger"
            onKeyDown={(event) => ifSpaceOrEnter(event, openChangelog)}
            data-canny-changelog={true}
        >
            <NavigationItem
                variant="secondary"
                label="What's New"
                icon={icon({ name: "bullhorn", style: "regular", family: "sharp" })}
                iconActive={icon({ name: "bullhorn", style: "solid", family: "sharp" })}
            />
            <div id="changelog_anchor" aria-hidden="true" />
            <>
                {window.Canny("initChangelog", {
                    appID: window?.cannyAppID,
                    ssoToken: user?.canny_sso_token,
                    position: "right",
                    align: "bottom",
                    theme: "auto",
                })}
            </>
        </div>
    )
}
