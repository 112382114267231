// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Card-base__cxlqd{padding:var(--size-5);background:var(--surface-secondary);border-radius:var(--border-radius-2);outline:var(--size-0_25) solid var(--gray-a5);outline-offset:calc(var(--size-0_25)*-1);transition:var(--duration-normal);transition-property:background-color;-webkit-user-select:none;-moz-user-select:none;user-select:none}.Card-base__cxlqd:focus-visible:not([aria-disabled=true]){background-color:var(--gray-a3);outline-color:var(--gray-12)}.Card-base__cxlqd:hover:not([aria-disabled=true]){background:var(--gray-a3)}.Card-base__cxlqd:active:not([aria-disabled=true]){background:var(--gray-a4)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "Card-base__cxlqd"
};
export default ___CSS_LOADER_EXPORT___;
