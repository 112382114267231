export function ifSpaceOrEnter<T extends HTMLElement>(
    event: React.KeyboardEvent<T>,
    callback?: (event: React.KeyboardEvent<T>) => void,
) {
    const isSpaceOrEnter = ["Enter", "NumpadEnter", "Space"].includes(event.code)

    if (isSpaceOrEnter) {
        event.preventDefault()
        callback?.(event)
    }
}
