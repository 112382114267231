// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DateInput-base__OqTYW{display:grid;grid-gap:var(--size-2);gap:var(--size-2)}.DateInput-base__OqTYW .DateInput-iconButton__xA4O6{border-top-left-radius:0;border-bottom-left-radius:0}.DateInput-content__YbOxX{width:auto;padding:0}.DateInput-iconButton__xA4O6{position:absolute;top:0;right:0;pointer-events:none}.DateInput-input__C1dNP{cursor:pointer}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "DateInput-base__OqTYW",
	"iconButton": "DateInput-iconButton__xA4O6",
	"content": "DateInput-content__YbOxX",
	"input": "DateInput-input__C1dNP"
};
export default ___CSS_LOADER_EXPORT___;
