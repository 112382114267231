import "core-js/stable";
import Cookies from "js-cookie";
import MicroModal from "micromodal";
import ReactDOM from "react-dom/client";

import "../scss/base.scss";
import "@styles/global.scss";

import {
    Auth,
    Calendar,
    Clients,
    Dashboard,
    Estimates,
    Invoices,
    Jobs,
    Menu,
    Pricebook,
    PurchaseOrders,
    Reports,
    Search,
    Settings,
    Vendor
} from "@pages";

window.MicroModal = MicroModal
window.ReactDOM = ReactDOM
window.Cookies = Cookies

window.DashboardContainer = <Dashboard/>

window.CalendarContainer = <Calendar/>

window.EstimateObjectListContainer = <Estimates.List />
window.EstimateCreateContainer = <Estimates.Create />
window.EstimateUpdateContainer = <Estimates.Edit />
window.EstimateDetailsContainer = <Estimates.Details />
window.EstimatePublicDetailsContainer = <Estimates.PublicDetails />

window.JobObjectListContainer = <Jobs.List />
window.JobCreateContainer = <Jobs.Create />
window.JobUpdateContainer = <Jobs.Edit />
window.JobDetailsContainer = <Jobs.Details />
window.JobPublicDetailsContainer = <Jobs.PublicDetails />
window.JobDetailsUpdateContainer = <Jobs.DetailsEdit />
window.JobSummaryContainer = <Jobs.SummaryEdit />
window.JobEquipmentSummaryContainer = <Jobs.EquipmentSummaryEdit />
window.CompleteJobSummaryContainer = <Jobs.CompleteSummary />
window.JobCompleteConfirmContainer = <Jobs.CompleteSummaryConfirmation />

window.InvoiceObjectListContainer = <Invoices.List />
window.InvoiceCreateContainer = <Invoices.Create />
window.InvoiceUpdateContainer =  <Invoices.Edit />
window.InvoiceDetailsContainer = <Invoices.Details />
window.InvoicePublicDetailsContainer =  <Invoices.PublicDetails />

window.PurchaseOrderObjectListContainer = <PurchaseOrders.List />
window.PurchaseOrderCreateContainer = <PurchaseOrders.Create />
window.PurchaseOrderUpdateContainer = <PurchaseOrders.Edit />
window.PurchaseOrderDetailsContainer = <PurchaseOrders.Details />
window.PurchaseOrderPublicDetailsContainer = <PurchaseOrders.PublicDetails />

window.VendorObjectListContainer = <Vendor.List />
window.VendorCreateContainer = <Vendor.Create />
window.VendorUpdateContainer = <Vendor.Edit />
window.VendorDetailsContainer = <Vendor.Details />

window.ClientObjectListContainer = <Clients.List />
window.ClientCreateContainer = <Clients.Create />
window.ClientUpdateContainer =  <Clients.Edit />
window.ClientDetailsContainer =  <Clients.Details />
window.ClientEquipmentListContainer =  <Clients.Equipment />
window.ClientMergeContainer =  <Clients.Merge />
window.ServiceLocationMergeContainer = <Clients.ServiceLocationsMerge />

window.PriceBookItemObjectListContainer = <Pricebook.List />
window.PriceBookItemCreateContainer = <Pricebook.Create />
window.PriceBookItemUpdateContainer = <Pricebook.Edit />
window.PriceBookItemReviewContainer = <Pricebook.Review />

window.Menu = <Menu />

window.Search = <Search />

window.Reports = <Reports />

window.SettingsMain = <Settings.Main />
window.SettingsProfile = <Settings.Profile />
window.SettingsAccounting = <Settings.Accounting />
window.SettingsAvailabilitySchedules = <Settings.AvailabilitySchedules />
window.SettingsCompanyCam = <Settings.CompanyCam />
window.SettingsNotifications = <Settings.Notifications />
window.SettingsPayments = <Settings.Payments />
window.SettingsPreferences = <Settings.Preferences />
window.SettingsSecurityAndAccess = <Settings.SecurityAndAccess />
window.QuickBooksDesktopClientRecordProcessedListContainer = <Settings.QuickBooksClientRecordProcessedList />
window.QuickBooksDesktopClientRecordConfirmedListContainer = <Settings.QuickBooksClientRecordConfirmedList />
window.SettingsNotificationsJobs = <Settings.SettingsNotificationsJobs />
window.SettingsNotificationsEstimates = <Settings.SettingsNotificationsEstimates />
window.SettingsNotificationsInventory = <Settings.SettingsNotificationsInventory />

window.Auth = <Auth />

document.addEventListener("DOMContentLoaded", function() {
    // Old Modal setup
    window.MicroModal.init({awaitCloseAnimation: true})

    // Prevent scrolling from changing input numbers
    document.addEventListener("wheel", function(event) {
        if (document.activeElement.type === "number") {
            document.activeElement.blur();
        }
    });

    // Prevent hidden helpkit frame from being focusable.
    function temporary_tryToPreventHelpKitFromTabbing() {
        const iframe = document.getElementById("helpkit-popover-frame")
        if (iframe) {
            iframe.setAttribute("tabindex", "-1")
            iframe.addEventListener("keydown", function (event) {
                if (event.code === "Tab") {
                    event.preventDefault()
                }
            })
        } else {
            setTimeout(temporary_tryToPreventHelpKitFromTabbing, 500)
        }
    }
    temporary_tryToPreventHelpKitFromTabbing()

    console.log("Roopairs Javascript initialized.");
});
