import { CheckedState } from "@radix-ui/react-checkbox"
import { InvalidateQueryFilters, useQueryClient } from "@tanstack/react-query"
import { useEffect, useMemo, useState } from "react"

import useUser from "@hooks/useUser"

import { Checkbox } from "@atoms/index"

import GenericForm from "@molecules/Form/GenericForm/GenericForm"
import { Property } from "@molecules/index"

import { PropertyStack } from "@organisms/index"

import { Settings } from "@templates/index"

import { InventoryNotificationTopics } from "@pages/Settings/SettingsNotifications/SettingsNotification.types"
import {
    notificationPreferenceQueryKey,
    useNotificationPreferencesQuery,
} from "@pages/Settings/SettingsNotifications/SettingsNotifications.store"
import EmailNotificationTooltip from "@pages/Settings/SettingsNotifications/components/EmailNotificationTooltip/EmailNotificationTooltip"
import NotificationHeader from "@pages/Settings/SettingsNotifications/components/NotificationHeader/NotificationHeader"
import { useNotificationMethodsQuery } from "@pages/Settings/SettingsNotifications/components/NotificationMethodsSection/NotificationMethodsSection.store"
import SMSNotificationTooltip from "@pages/Settings/SettingsNotifications/components/SMSNotificationTooltip/SMSNotificationTooltip"

import { NOTIFICATION_ENDPOINTS } from "@endpoints/notification"

export default function InventoryNotificationsForm() {
    const { user } = useUser()

    const [shouldEnableAllNotifications, setShouldEnableAllNotifications] = useState<boolean>(false)
    const [shouldDisableAllNotifications, setShouldDisableAllNotifications] = useState<boolean>(false)
    const [shouldSetDefaultFormData, setShouldSetDefaultFormData] = useState<boolean>(false)

    const { topicPreference: inventoryNotificationPreference, isLoading: isLoadingData } =
        useNotificationPreferencesQuery<InventoryNotificationTopics>("inventory")

    const { data: defaultMethodsPreference } = useNotificationMethodsQuery()

    const queryClient = useQueryClient()

    useEffect(() => {
        if (!isLoadingData && !!inventoryNotificationPreference) {
            setShouldSetDefaultFormData(true)
        }
    }, [isLoadingData])

    const handleToggleAllNotifications = (shouldEnableAllNotifications: CheckedState) => {
        if (shouldEnableAllNotifications) {
            setShouldDisableAllNotifications(false)
            setShouldEnableAllNotifications(true)
        } else {
            setShouldEnableAllNotifications(false)
            setShouldDisableAllNotifications(true)
        }
    }

    const phoneNotificationsDisabled = !user?.phone || !user?.phone_verified || !defaultMethodsPreference?.contact_sms
    const emailNotificationsDisabled =
        !user?.email || !user?.email_verified || !defaultMethodsPreference?.contact_email

    const formValues = useMemo(() => {
        if (shouldEnableAllNotifications) {
            return {
                enable_inventory_notification_low_inventory: true,
            }
        } else if (shouldDisableAllNotifications) {
            return {
                enable_inventory_notification_low_inventory: false,
            }
        } else if (shouldSetDefaultFormData) {
            return inventoryNotificationPreference
        } else {
            return undefined
        }
    }, [shouldSetDefaultFormData, shouldEnableAllNotifications, shouldDisableAllNotifications])

    const mutationTriggerPayload = useMemo(() => {
        if (shouldEnableAllNotifications) {
            return {
                enable_inventory_notification_low_inventory: true,
            }
        } else if (shouldDisableAllNotifications) {
            return {
                enable_inventory_notification_low_inventory: false,
            }
        } else {
            return undefined
        }
    }, [shouldEnableAllNotifications, shouldDisableAllNotifications])

    const onMutationSuccess = () => {
        void queryClient.invalidateQueries([notificationPreferenceQueryKey] as InvalidateQueryFilters)
    }

    const onAfterFormValuesSet = () => {
        setShouldEnableAllNotifications(false)
        setShouldDisableAllNotifications(false)
        setShouldSetDefaultFormData(false)
    }

    return (
        <GenericForm<InventoryNotificationTopics, InventoryNotificationTopics, Error, InventoryNotificationTopics>
            formConfig={{
                defaultValues: undefined,
            }}
            mutationConfig={{
                genericErrorMessage: "Could not update notification preference",
                genericSuccessMessage: "Notification preference updated",
                method: "PATCH",
                endpoint: NOTIFICATION_ENDPOINTS.UPDATE_NOTIFICATION_PREFERENCES,
            }}
            onMutationSuccess={onMutationSuccess}
            mutationTriggerPayload={mutationTriggerPayload}
            formValues={formValues}
            onAfterFormValuesSet={onAfterFormValuesSet}
            isAutoSave={true}
        >
            {({ form }) => {
                const isAllNotificationsEnabled = Object.values(form.getValues()).every(Boolean)
                const isSomeNotificationsEnabled = Object.values(form.getValues()).some(Boolean)

                return (
                    <Settings.NotificationConfigPage.Wrapper>
                        <NotificationHeader.Root>
                            <NotificationHeader.EmailCheckbox
                                isIndeterminate={isSomeNotificationsEnabled && !isAllNotificationsEnabled}
                                isChecked={isAllNotificationsEnabled}
                                onChecked={handleToggleAllNotifications}
                            />
                            <NotificationHeader.SMSCheckbox
                                isIndeterminate={isSomeNotificationsEnabled && !isAllNotificationsEnabled}
                                isChecked={isAllNotificationsEnabled}
                                onChecked={handleToggleAllNotifications}
                            />
                        </NotificationHeader.Root>
                        <PropertyStack>
                            <Property.Root>
                                <Property.Content>
                                    <Property.Label>Low inventory digest</Property.Label>
                                    <Property.Subtitle>
                                        Get a daily summary of all parts that have low or depleted inventory.
                                    </Property.Subtitle>
                                </Property.Content>
                                <Property.Value>
                                    <Settings.NotificationConfigPage.Checkboxes>
                                        <EmailNotificationTooltip>
                                            <Checkbox<InventoryNotificationTopics>
                                                size="md"
                                                colorScheme="gray"
                                                displayAsUnchecked={emailNotificationsDisabled}
                                                isDisabled={isLoadingData || emailNotificationsDisabled}
                                                name="enable_inventory_notification_low_inventory"
                                                isControlled={true}
                                            />
                                        </EmailNotificationTooltip>
                                        <SMSNotificationTooltip>
                                            <Checkbox<InventoryNotificationTopics>
                                                size="md"
                                                colorScheme="gray"
                                                displayAsUnchecked={phoneNotificationsDisabled}
                                                isDisabled={isLoadingData || phoneNotificationsDisabled}
                                                name="enable_inventory_notification_low_inventory"
                                                isControlled={true}
                                            />
                                        </SMSNotificationTooltip>
                                    </Settings.NotificationConfigPage.Checkboxes>
                                </Property.Value>
                            </Property.Root>
                        </PropertyStack>
                    </Settings.NotificationConfigPage.Wrapper>
                )
            }}
        </GenericForm>
    )
}
