import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import InvoiceListCard from "@legacy/invoices/components/InvoiceListCard"
import { useEffect, useMemo } from "react"

import useIsDesktop from "@hooks/useIsDesktop"
import useLocalStorage from "@hooks/useLocalStorage"
import useSlugExtractor from "@hooks/useSlugExtractor"

import extractURLSearchParam from "@utils/extractURLSearchParam"

import { ObjectsView, TopBar } from "@organisms"
import { ObjectsViewType } from "@organisms/ObjectsView/ObjectsView.types"

import { AppFrame } from "@templates"

import { INVOICES_ROUTES } from "@routes/invoices"

import {
    LIST_SEARCH_PARAM_ACTIVE_TAB,
    LIST_SEARCH_PARAM_KEYWORD_SEARCH,
    LIST_SEARCH_PARAM_PHASE,
} from "@constants/searchParams"
import {
    INVOICES_LIST_CONTROLS_ACTIVE_TAB,
    INVOICES_LIST_CONTROLS_KEYWORD_SEARCH,
    INVOICES_LIST_CONTROLS_ORDERING,
    INVOICES_LIST_CONTROLS_PHASE,
    INVOICES_LIST_CONTROLS_VIEW,
} from "@constants/storage"

import useInvoiceTabs from "./useInvoiceTabs"

export default function InvoiceList() {
    const isDesktop = useIsDesktop()

    const [createRoute] = useSlugExtractor([INVOICES_ROUTES.CREATE])
    const [openTabs, closedTabs] = useInvoiceTabs()

    const phaseOverride = useMemo(() => extractURLSearchParam(LIST_SEARCH_PARAM_PHASE) as ObjectPhase, [])
    const activeTabOverride = useMemo(() => extractURLSearchParam(LIST_SEARCH_PARAM_ACTIVE_TAB), [])
    const searchKeywordsOverride = useMemo(() => extractURLSearchParam(LIST_SEARCH_PARAM_KEYWORD_SEARCH), [])

    const [viewType, setViewType] = useLocalStorage<ObjectsViewType>(INVOICES_LIST_CONTROLS_VIEW, "table")
    const [phase, setPhase] = useLocalStorage<ObjectPhase>(INVOICES_LIST_CONTROLS_PHASE, "open", phaseOverride)
    const [activeTab, setActiveTab] = useLocalStorage<string>(INVOICES_LIST_CONTROLS_ACTIVE_TAB, "", activeTabOverride)
    const [searchKeywords, setSearchKeywords] = useLocalStorage<string>(
        INVOICES_LIST_CONTROLS_KEYWORD_SEARCH,
        "",
        searchKeywordsOverride,
    )

    const onShowOpen = () => {
        setPhase("open")
        setActiveTab(openTabs[0].title)
    }

    const onShowClosed = () => {
        setPhase("closed")
        setActiveTab(closedTabs[0].title)
    }

    const tabs = phase === "open" ? openTabs : closedTabs

    useEffect(() => {
        const isAnyTabAvailable = tabs && tabs.length > 0

        if (isAnyTabAvailable && !activeTab) {
            setActiveTab(tabs[0].title)
        }
    }, [tabs, activeTab])

    return (
        <AppFrame.Root pageId="InvoiceList">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle
                            label="Invoices"
                            icon={icon({ name: "circle-dollar", style: "regular", family: "sharp" })}
                            iconActive={icon({ name: "circle-dollar", style: "solid", family: "sharp" })}
                        />
                    </TopBar.TopBarHeaderStart>
                    <TopBar.TopBarHeaderEnd>
                        <TopBar.PagePresence />
                        <TopBar.SearchButton scope="Invoices" />
                        <TopBar.ViewSelector
                            viewTypes={["table", "card"]}
                            selectedViewType={viewType}
                            setViewType={setViewType}
                        />
                        <TopBar.CTAButton destination={createRoute}>
                            {isDesktop ? "New Invoice" : null}
                        </TopBar.CTAButton>
                    </TopBar.TopBarHeaderEnd>
                </TopBar.Header>
            </TopBar.Root>
            <ObjectsView
                viewType={viewType}
                searchKeywords={searchKeywords}
                setActiveTab={setActiveTab}
                setSearchKeywords={setSearchKeywords}
                onShowOpen={onShowOpen}
                onShowClosed={onShowClosed}
                phase={phase}
                orderingKey={INVOICES_LIST_CONTROLS_ORDERING}
                tabs={tabs}
                activeTab={activeTab}
                CardComponent={InvoiceListCard}
                listTitle="Invoices"
                objectCreateRoute={createRoute}
                objectType="invoice"
            />
        </AppFrame.Root>
    )
}
