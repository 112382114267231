import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"

dayjs.extend(isSameOrBefore)
dayjs.extend(advancedFormat)

type OverdueBucket = "MORE_THAN_THIRTY" | "SIXTEEN_TO_THIRTY" | "ONE_TO_FIFTEEN"

export default function determineOverdueBucket(date: string | Date): OverdueBucket | undefined {
    const date_due = dayjs(date)
    const endOfThirtyDaysAgo = dayjs().startOf("day").subtract(31, "day")
    const endOfFifteenDaysAgo = dayjs().startOf("day").subtract(16, "day")
    const endOfToday = dayjs().startOf("day").subtract(1, "day")

    // More than 30 days late
    if (date_due.isSameOrBefore(endOfThirtyDaysAgo)) {
        return "MORE_THAN_THIRTY"
    }
    // 16-30 days late
    else if (date_due.isAfter(endOfThirtyDaysAgo) && date_due.isSameOrBefore(endOfFifteenDaysAgo)) {
        return "SIXTEEN_TO_THIRTY"
    }
    // 1-15 days late
    else if (date_due.isAfter(endOfFifteenDaysAgo) && date_due.isSameOrBefore(endOfToday)) {
        return "ONE_TO_FIFTEEN"
    }
}
