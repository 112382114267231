import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { flexRender } from "@tanstack/react-table"
import clsx from "clsx"
import { Fragment, forwardRef } from "react"

import { Icon } from "@atoms"

import SortingIcons from "@organisms/Table/SortingIcons/SortingIcons"

import styles from "./TableHeaderCell.module.scss"
import { TableCellProps } from "./TableHeaderCell.types"

function TableHeaderCell(props: TableCellProps, ref: React.Ref<HTMLTableCellElement>) {
    const {
        isSticky,
        leftStickyOffset,
        isScrolling,
        isLastSticky,
        canSort,
        isSorted,
        content,
        isPlaceholder,
        onClick,
        width,
        colSpan,
        context,
        columnId,
        isTabbable,
    } = props

    const onKeyDown = (event: React.KeyboardEvent<HTMLTableCellElement>) => {
        if (event.key === "Enter") {
            onClick?.(event)
        }
    }

    return (
        <th
            ref={ref}
            colSpan={colSpan}
            style={{
                minWidth: width,
                flexBasis: width,
                left: isSticky ? `${leftStickyOffset}px` : "auto",
                cursor: canSort ? "pointer" : "default",
            }}
            onClick={onClick}
            className={clsx(styles.base, {
                [styles.sticky]: isSticky,
                [styles.sortable]: canSort,
                [styles.scrolling]: isScrolling,
                [styles.lastSticky]: isLastSticky,
                [styles.sorting]: isSorted,
            })}
            onKeyDown={onKeyDown}
            tabIndex={isTabbable ? 0 : -1}
        >
            <div className={styles.inner}>
                <div className={styles.pin}>
                    {isPlaceholder ? null : flexRender(content, context)}
                    {isSticky && (
                        <Icon
                            className={styles.pinIcon}
                            icon={icon({ name: "thumbtack", style: "solid", family: "sharp" })}
                            size={10}
                        />
                    )}
                </div>

                {canSort && (
                    <span>
                        {isSorted && (
                            <Fragment>
                                {{
                                    asc: <SortingIcons isSorted={true} ascending={true} columnID={columnId} />,
                                    desc: <SortingIcons isSorted={true} ascending={false} columnID={columnId} />,
                                }[isSorted as string] ?? null}
                            </Fragment>
                        )}
                    </span>
                )}
            </div>
        </th>
    )
}

export default forwardRef(TableHeaderCell)
