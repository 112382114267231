import { VizzlyTheming } from "@vizzly/dashboard"

export const theme: VizzlyTheming.Base = {
    accordion: {
        iconColor: "var(--gray-12)",
        button: {
            color: "var(--gray-12)",
        },
    },
    alerts: {
        padding: "var(--size-3)",
        borderRadius: "var(--border-radius-2)",
        info: {
            color: "var(--blue-a11)",
            iconColor: "var(--blue-a11)",
            background: "var(--blue-a3)",
            border: "none",
            borderRadius: "var(--border-radius-2)",
        },
        warning: {
            color: "var(--amber-a11)",
            iconColor: "var(--amber-a11)",
            background: "var(--amber-a3)",
            border: "none",
            borderRadius: "var(--border-radius-2)",
        },
        critical: {
            color: "var(--tomato-a11)",
            iconColor: "var(--tomato-a11)",
            background: "var(--tomato-a3)",
            border: "none",
            borderRadius: "var(--border-radius-2)",
        },
    },
    baseFont: {
        fontFamily: "inherit",
    },
    buttons: {
        primary: {
            color: "var(--gray-1)",
            background: "var(--gray-a12)",
            borderRadius: "var(--border-radius-1_5)",
            padding: "var(--size-2) var(--size-2_5)",
            narrowPadding: "padding",
            "&:hover": {
                background: "var(--gray-a11)",
                borderRadius: "var(--border-radius-1_5)",
                color: "var(--gray-1)",
                opacity: 1,
            },
            // @ts-expect-error Bad typing
            "&:focus-visible": {
                background: "var(--gray-a11)",
                outline: "var(--size-0_5) solid var(--gray-12)",
                outlineOffset: "calc(var(--size-0_5) * -1)",
                borderRadius: "var(--border-radius-1_5)",
                color: "var(--gray-1)",
                opacity: 1,
            },
            "&:disabled": {
                background: "var(--gray-a3)",
                borderRadius: "var(--border-radius-1_5)",
                color: "var(--gray-a7)",
                opacity: 1,
            },
            "&:hover:disabled": {
                background: "var(--gray-a3)",
                borderRadius: "var(--border-radius-1_5)",
                color: "var(--gray-a7)",
                opacity: 1,
            },
        },
        secondary: {
            color: "var(--gray-12)",
            background: "var(--gray-a3)",
            borderRadius: "var(--border-radius-1_5)",
            padding: "var(--size-2) var(--size-2_5)",
            narrowPadding: "padding",
            "&:hover": {
                background: "var(--gray-a4)",
                borderRadius: "var(--border-radius-1_5)",
                color: "var(--gray-12)",
                opacity: 1,
            },
            // @ts-expect-error Bad typing
            "&:focus-visible": {
                background: "var(--gray-a4)",
                outline: "var(--size-0_5) solid var(--gray-12)",
                outlineOffset: "calc(var(--size-0_5) * -1)",
                borderRadius: "var(--border-radius-1_5)",
                color: "var(--gray-1)",
                opacity: 1,
            },
            "&:disabled": {
                background: "var(--gray-a3)",
                borderRadius: "var(--border-radius-1_5)",
                color: "var(--gray-a7)",
                opacity: 1,
            },
            "&:hover:disabled": {
                background: "var(--gray-a3)",
                borderRadius: "var(--border-radius-1_5)",
                color: "var(--gray-a7)",
                opacity: 1,
            },
        },
        add: {
            color: "var(--gray-12)",
            background: "transparent",

            border: "none",
            borderRadius: "var(--border-radius-1_5)",
            boxShadow: "unset",

            "&:hover": {
                background: "var(--gray-a3)",
                border: "none",
                color: "var(--gray-12)",
            },
            "&:focus-visible": {
                background: "var(--gray-a3)",
                outline: "var(--size-0_5) solid var(--gray-12)",
                outlineOffset: "calc(var(--size-0_5) * -1)",
                borderRadius: "var(--border-radius-1_5)",
                color: "var(--gray-12)",
                opacity: 1,
            },
            small: {
                padding: "var(--size-1_5) var(--size-2)",
                // @ts-expect-error Bad typing
                borderRadius: "var(--border-radius-1_5)",
            },
            medium: {
                padding: "var(--size-2) var(--size-2_5)",
                // @ts-expect-error Bad typing
                borderRadius: "var(--border-radius-1_5)",
            },
            large: {
                padding: "var(--size-2) var(--size-2_5)",
                // @ts-expect-error Bad typing
                borderRadius: "var(--border-radius-2)",
            },
        },
    },
    charts: {
        colors: [
            "var(--blue-8)",
            "var(--orange-8)",
            "var(--cyan-8)",
            "var(--tomato-8)",
            "var(--amber-8)",
            "var(--violet-8)",
            "var(--grass-8)",
        ],
        title: {
            color: "var(--gray-12)",
            fontFamily: "inherit",
        },
        subject: {
            color: "var(--gray-11)",
            fontFamily: "inherit",
        },
        popoverMenus: {
            background: "var(--surface-primary)",
            boxShadow: "var(--box-shadow-xl)",
            borderRadius: "var(--border-radius-2)",
            padding: "0.5rem 0.75rem",
            color: "var(--gray-12)",
        },
        iconColor: "var(--gray-12)",
        labels: {
            color: "var(--gray-12)",
        },
        axisTitles: {
            color: "var(--gray-12)",
        },
        grid: {
            stroke: "var(--gray-a3)",
        },
        axis: {
            stroke: "var(--gray-7)",
        },
        threshold: {
            stroke: "var(--gray-7)",
        },
    },
    dashboard: {
        // Empty Cells
        borderRadius: "0.5rem",
        borderWidth: "var(--size-px)",
        borderColor: "var(--gray-7)",
        borderStyle: "solid",
        icon: {
            // 'More' Buttons (3 dots)
            color: "var(--gray-12)",
            background: "transparent",
            borderRadius: 0,
            "&:disabled": {
                color: "var(--gray-a7)",
                background: "transparent",
            },
            "&:hover": {
                color: "var(--gray-12)",
                background: "var(--gray-3)",
            },
        },
    },
    dropdowns: {
        background: "var(--white)",
        color: "var(--gray-12)",
        separatorBorder: "var(--size-px) transparent solid",
        boxShadow: "var(--box-shadow-xl)",
        borderRadius: "var(--border-radius-2)",
        selected: {
            background: "var(--gray-a4)",
        },
        "&:hover": {
            background: "var(--gray-a3)",
        },
    },
    filters: {
        filterBy: {
            color: "var(--gray-12)",
            background: "transparent",
            borderRadius: "var(--border-radius-2)",
            padding: "0.5rem 1rem",
            "&:hover": {
                background: "var(--gray-a3)",
                borderRadius: "var(--border-radius-2)",
                opacity: 1,
            },
            "&:disabled": {
                color: "var(--gray-a7)",
                background: "var(--gray-a3)",
                borderRadius: "var(--border-radius-2)",
            },
            "&:hover:disabled": {
                color: "var(--gray-a7)",
                background: "var(--gray-a3)",
                borderRadius: "var(--border-radius-2)",
                opacity: 1,
            },
        },
    },
    forms: {
        label: {
            color: "var(--gray-12)",
        },
        input: {
            color: "var(--gray-12)",
            border: "var(--size-px) solid var(--gray-7)",
            background: "transparent",
            boxShadow: "none",
            borderRadius: "var(--border-radius-2)",
            padding: "var(--size-2) var(--size-1) var(--size-2) var(--size-3_5)",
            "&:hover": {
                border: "var(--size-px) solid var(--gray-a5)",
            },
            "&:focus": {
                border: "var(--size-px) solid var(--gray-12)",
            },
            // @ts-expect-error Bad typing
            "::placeholder": {
                color: "var(--gray-a9)",
            },
        },
        select: {
            color: "var(--gray-12)",
            border: "var(--size-px) solid var(--gray-7)",
            background: "transparent",
            boxShadow: "none",
            borderRadius: "var(--border-radius-2)",
            padding: "var(--size-2) var(--size-1) var(--size-2) var(--size-3_5)",
            "&:hover": {
                background: "transparent",
                border: "var(--size-px) solid var(--gray-a5)",
            },
            "&:focus": {
                background: "transparent",
                border: "var(--size-px) solid var(--gray-12)",
            },
            "&:focus:hover": {
                background: "transparent",
                border: "var(--size-px) solid var(--gray-12)",
            },
            // @ts-expect-error Bad typing
            "::placeholder": {
                color: "var(--gray-a9)",
            },
        },
        dateTime: {
            input: {
                color: "var(--gray-12)",
                background: "transparent",
                border: "var(--size-px) solid var(--gray-7)",
                borderRadius: "var(--border-radius-2)",
                boxShadow: "unset",
                padding: "var(--size-2) var(--size-1) var(--size-2) var(--size-3_5)",
                "&:hover": {
                    background: "transparent",
                    border: "var(--size-px) solid var(--gray-a5)",
                },
                "&:focus": {
                    background: "transparent",
                    border: "var(--size-px) solid var(--gray-12)",
                },
                "&:focus:hover": {
                    background: "transparent",
                    border: "var(--size-px) solid var(--gray-12)",
                },
                // @ts-expect-error Bad typing
                "::placeholder": {
                    color: "var(--gray-a9)",
                },
            },
            calendar: {
                title: {
                    color: "var(--gray-12)",
                },
                selected: {
                    background: "var(--gray-a4)",
                    border: "0",
                    color: "var(--gray-12)",
                },
                unselected: {
                    background: "transparent",
                    color: "inherit",
                    border: "var(--size-px) solid var(--gray-7)",
                    "&:hover": {
                        background: "var(--gray-a3)",
                        border: "var(--size-px) solid var(--gray-12)",
                        color: "inherit",
                    },
                },
                arrows: {
                    background: "transparent",
                    border: "var(--size-px) solid var(--gray-7)",
                    borderRadius: "var(--border-radius-2)",
                    fill: "var(--gray-12)",
                    "&:hover": {
                        background: "var(--gray-a3)",
                        border: "var(--size-px) solid var(--gray-12)",
                    },
                },
            },
            // Arrow color between start and end date/time
            indicatorColor: "var(--gray-12)",
        },
    },
    header: {
        background: "transparent",
        borderBottom: "var(--size-0_5) solid var(--gray-12)",
    },
    icons: {
        borderRadius: "var(--border-radius-2)",
        color: "var(--gray-12)",
        padding: "0.25rem",
        "&:hover": {
            color: "var(--gray-12)",
            background: "var(--gray-3)",
        },
    },
    library: {
        verified: {
            color: "var(--gray-12)",
        },
    },
    lists: {
        background: "var(--surface-secondary)",
        border: "var(--size-px) solid var(--gray-7)",
        borderRadius: "var(--border-radius-2)",
        icon: {
            // 'x' icons for removing list items
            color: "var(--gray-12)",
            background: "var(--gray-3)",
            border: "none",
        },
        select: {
            // List item select dropdown controls (e.g. 'Count'/'Unique', 'Asc'/'Desc' etc)
            padding: "0.25rem 0 0.25rem 1rem",
            color: "var(--gray-12)",
            "&:hover": {
                background: "var(--gray-a3)",
            },
        },
        row: {
            padding: "0 0.5rem",
        },
    },
    modals: {
        overlay: {
            background: "var(--pure-black-a9)",
            opacity: 1,
        },
        base: {
            border: "none",
            background: "var(--surface-secondary)",
            boxShadow: "var(--box-shadow-xl)",
            borderRadius: "var(--border-radius-2)",
            zIndex: "var(--z-index-modal)",
        },
        content: {
            background: "none",
            alternativeBackground: "none", // Shown in the View Library only
        },
        header: {
            background: "none",
        },
        footer: {
            background: "var(--gray-2)",
            // @ts-expect-error Bad typing
            borderBottomLeftRadius: "var(--border-radius-2)",
            borderBottomRightRadius: "var(--border-radius-2)",
            padding: "0.75rem 2rem",
        },
        options: {
            disableOutsideClick: false,
            closeButton: "inside",
        },
    },
    panels: {
        background: "var(--surface-secondary)",
        boxShadow: "var(--box-shadow-sm)",
        border: "var(--size-0_5) solid var(--gray-7)",
        borderRadius: "var(--border-radius-2)",
        // @ts-expect-error Bad typing
        "&:hover": {
            border: "var(--size-0_5) solid var(--gray-12)",
            outline: "none",
            boxShadow: "none",
        },
        "&:focus": {
            border: "var(--size-0_5) solid var(--gray-12)",
            outline: "none",
            boxShadow: "none",
        },
    },
    popoverMenu: {
        background: "var(--surface-tertiary)",
        boxShadow: "var(--box-shadow-xl)",
        borderRadius: "var(--border-radius-2)",
        padding: "0.5rem 0.75rem",
        separatorBorder: "none",
        "&:hover": {
            background: "var(--gray-a3)",
        },
    },
    radioGroup: {
        description: {
            color: "var(--gray-11)",
        },
    },
    search: {
        color: "var(--gray-12)",
        background: "transparent",
        border: "var(--size-px) solid var(--gray-7)",
        outline: "none",
        "&:hover": {
            // @ts-expect-error Bad typing
            border: "var(--size-px) solid var(--gray-a5)",
            background: "transparent",
            boxShadow: "none",
        },
        "&:focus": {
            // @ts-expect-error Bad typing
            border: "var(--size-px) solid var(--gray-12)",
            background: "transparent",
            color: "var(--gray-12)",
            boxShadow: "none",
        },
        icons: {
            color: "var(--gray-a10)",
        },
    },
    tables: {
        th: {
            borderBottom: "var(--size-0_5) solid var(--gray-8)",
            borderRight: "var(--size-px) solid transparent",

            "&:hover": {
                background: "transparent",
                color: "var(--gray-12)",
            },
            column: {
                // (Pivot Tables only) Applied to 'Group rows'
                background: "var(--gray-a4)",
                borderRight: "var(--size-px) solid var(--gray-7)",
                color: "inherit",
                padding: "0.5rem",
            },
            row: {
                // (Pivot Tables only) Applied to 'Group columns'
                borderBottom: "var(--size-px) solid var(--gray-7)",
                borderRight: "var(--size-px) solid transparent",
                padding: "0.5rem",
            },
            level: {
                2: {
                    background: "var(--gray-a4)",
                },
            },
        },
        body: {
            cells: {
                borderBottom: "var(--size-px) solid var(--gray-7)",
                padding: "0.5rem",
            },
            odd: {
                color: "inherit",
                "&:hover": {
                    color: "inherit",
                    background: "var(--gray-a3)",
                },
            },
            even: {
                color: "inherit",
                "&:hover": {
                    color: "inherit",
                    background: "var(--gray-a3)",
                },
            },
        },
    },
    tabs: {
        borderBottom: "none",
        selected: {
            color: "var(--gray-12)",
            borderBottom: "var(--size-px) solid var(--gray-12)",
            padding: "0.75rem 1rem",
            "&:hover": {
                borderRadius: "0",
                color: "var(--gray-12)",
            },
            "&:disabled": {
                borderRadius: "0",
                color: "var(--gray-a7)",
            },
            "&:hover:disabled": {
                borderRadius: "0",
                color: "var(--gray-a7)",
            },
        },
        unselected: {
            color: "var(--gray-9)",
            borderBottom: "var(--size-px) solid transparent",
            padding: "0.75rem 1rem",
            "&:hover": {
                borderRadius: "0",
                color: "var(--gray-12)",
            },
            "&:disabled": {
                borderRadius: "0",
                color: "var(--gray-a7)",
            },
            "&:hover:disabled": {
                borderRadius: "0",
                color: "var(--gray-a7)",
            },
        },
    },
    titles: {
        fontFamily: "inherit",
    },
    tooltips: {
        background: "var(--surface-primary)",
        color: "var(--gray-12)",
    },
    version: 2,
    cellLimit: 100,
    rowLimit: 100,
}
