// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RadioItem-itemWrapper__m3aBV{display:inline-flex;gap:var(--size-2);align-items:\"center\"}.RadioItem-item__kT2XQ{min-height:0;margin:0;padding:0;background-color:rgba(0,0,0,0);border:1px solid var(--gray-a7);border-radius:var(--border-radius-full);outline:none}.RadioItem-item__kT2XQ:hover,.RadioItem-item__kT2XQ:active{-webkit-text-decoration:none;text-decoration:none;outline:none}.RadioItem-item__kT2XQ[data-state=checked]{background-color:var(--gray-12);border-color:var(--gray-12)}.RadioItem-item__kT2XQ[data-disabled]{background-color:var(--gray-a4);border-color:rgba(0,0,0,0);cursor:not-allowed}.RadioItem-itemSm__acQG3{width:var(--size-4);height:var(--size-4)}.RadioItem-itemMd__ttvF3{width:var(--size-5);height:var(--size-5)}.RadioItem-item__kT2XQ:focus{box-shadow:0 0 0 1px var(--gray-1),0 0 0 3px var(--gray-12)}.RadioItem-indicator__klYcC{position:relative;display:flex;align-items:center;justify-content:center;width:var(--size-full);height:var(--size-full)}.RadioItem-indicatorSm__VfTKx::after{width:var(--size-1_5);height:var(--size-1_5)}.RadioItem-indicatorMd__O7czx::after{width:var(--size-2);height:var(--size-2)}.RadioItem-indicator__klYcC::after{display:block;background-color:var(--gray-1);border-radius:var(--border-radius-full);content:\"\"}.RadioItem-indicator__klYcC[data-disabled]::after{background-color:var(--gray-a8)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemWrapper": "RadioItem-itemWrapper__m3aBV",
	"item": "RadioItem-item__kT2XQ",
	"itemSm": "RadioItem-itemSm__acQG3",
	"itemMd": "RadioItem-itemMd__ttvF3",
	"indicator": "RadioItem-indicator__klYcC",
	"indicatorSm": "RadioItem-indicatorSm__VfTKx",
	"indicatorMd": "RadioItem-indicatorMd__O7czx"
};
export default ___CSS_LOADER_EXPORT___;
