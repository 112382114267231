import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import EstimateDetailsContainer from "@legacy/estimates/EstimateDetailsContainer"

import useIsDesktop from "@hooks/useIsDesktop"
import useSlugExtractor from "@hooks/useSlugExtractor"
import useToastQueue from "@hooks/useToastQueue"

import { TopBar } from "@organisms"

import { AppFrame } from "@templates"

import { ESTIMATES_ROUTES } from "@routes/estimates"

export default function EstimateDetails() {
    const isDesktop = useIsDesktop()

    const [listRoute] = useSlugExtractor([ESTIMATES_ROUTES.LIST])
    const { addToastToQueue } = useToastQueue()

    return (
        <AppFrame.Root pageId="EstimateDetails">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle
                            label="Estimates"
                            icon={icon({ name: "file-lines", style: "regular", family: "sharp" })}
                            iconActive={icon({ name: "file-lines", style: "solid", family: "sharp" })}
                            destination={listRoute}
                            iconOnly={!isDesktop}
                        />
                        <TopBar.VerticalDivider />
                        <TopBar.PageSubtitle label="Estimate Details" backDestination={listRoute} />
                    </TopBar.TopBarHeaderStart>
                </TopBar.Header>
            </TopBar.Root>
            <AppFrame.PageContent>
                <EstimateDetailsContainer addToastToQueue={addToastToQueue} />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
