import { Command } from "cmdk"
import { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"

import { Overlay } from "@particles"

import { AppSearch } from "@organisms"

import styles from "./SearchModal.module.scss"
import { SearchModalProps } from "./SearchModal.types"

export default function SearchModal(props: SearchModalProps) {
    const { isOpen, onOpenChange } = props
    const [searchScope, setSearchScope] = useState<ObjectsNames>()

    const closeModal = () => onOpenChange(false)

    useEffect(() => {
        if (!isOpen) {
            setSearchScope(undefined)
        }
    }, [isOpen])

    const ref = useRef<HTMLDivElement>(null)

    const openModal = (event: CustomEvent<{ scope: ObjectsNames }>) => {
        setSearchScope(event.detail.scope)

        onOpenChange(true)
    }

    useEffect(() => {
        window.addEventListener("openSearchModal", openModal as EventListener)

        return () => {
            window.removeEventListener("openSearchModal", openModal as EventListener)
        }
    }, [])

    return createPortal(
        <div ref={ref} className={styles.base} aria-hidden={isOpen ? "true" : "false"} tabIndex={-1}>
            <Overlay isActive={isOpen} onClick={closeModal} />
            <Command.Dialog
                open={isOpen}
                label="Global Command Menu"
                onOpenChange={onOpenChange}
                className={styles.dialog}
                container={ref.current ?? undefined}
                loop={true}
                shouldFilter={false}
            >
                <AppSearch closeSearchModal={closeModal} searchScope={searchScope} />
            </Command.Dialog>
        </div>,
        document.body,
    )
}
