import { ColumnDef, OnChangeFn, SortingState, Updater, getCoreRowModel, useReactTable } from "@tanstack/react-table"
import { useVirtualizer } from "@tanstack/react-virtual"
import { useEffect, useMemo, useRef } from "react"

import useCurrentObjectsView from "@hooks/useCurrentObjectsView"
import useIsAnyDialogOpen from "@hooks/useIsAnyDialogOpen"
import useIsDesktop from "@hooks/useIsDesktop"
import useIsScrolling from "@hooks/useIsScrolling"
import useRouter from "@hooks/useRouter"

import { ifSpaceOrEnter } from "@utils/keyboard"

import { ObjectsData } from "@organisms/ObjectsView/ObjectsView.types"
import { ColumnId, ColumnSort } from "@organisms/Table/Table.types"
import TableBodyCell from "@organisms/Table/TableBodyCell/TableBodyCell"
import TableEmptyState from "@organisms/Table/TableEdgeStates/TableEmptyState/TableEmptyState"
import TableErrorState from "@organisms/Table/TableEdgeStates/TableErrorState/TableErrorState"
import TableLoadingState from "@organisms/Table/TableEdgeStates/TableLoadingState/TableLoadingState"
import TableNoResultsState from "@organisms/Table/TableEdgeStates/TableNoResultsState/TableNoResultsState"
import TableHeaderCell from "@organisms/Table/TableHeaderCell/TableHeaderCell"
import TableLoaderRow from "@organisms/Table/TableLoaderRow/TableLoaderRow"

import withAlternativeStates from "@HOCs/withAlternativeStates"

import styles from "./TableContent.module.scss"
import { TableContentProps } from "./TableContent.types"

const TABLE_ROW_HEIGHT = 48

function TableContent(props: TableContentProps) {
    const {
        activeTabConfig,
        currentData,
        setSorting,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        objectType,
        objectTypeVariation,
    } = props

    const isDesktop = useIsDesktop()

    const { currentObjectsView, updateCurrentObjectsViewSorting, isLoading } = useCurrentObjectsView({
        objectType: objectType,
        objectTypeVariation,
        viewType: "table",
        phase: activeTabConfig.objectsPhase,
        tab: activeTabConfig.title,
    })

    const columns = useMemo(
        () => activeTabConfig.columnDefinition.filter((column) => column !== undefined),
        [activeTabConfig.columnDefinition],
    ) as ColumnDef<ObjectsData>[]

    const onSortingChange: OnChangeFn<SortingState> = (newSorting: Updater<SortingState>) => {
        if (typeof newSorting === "function") {
            newSorting = newSorting(currentObjectsView?.sorting ?? [])
        }
        setSorting(newSorting as ColumnSort[])
        updateCurrentObjectsViewSorting(newSorting as ColumnSort[])
    }

    const table = useReactTable({
        columns,
        data: currentData,
        getCoreRowModel: getCoreRowModel(),
        initialState: isLoading
            ? undefined
            : {
                  sorting: currentObjectsView?.sorting,
                  columnVisibility: currentObjectsView?.columnVisibility,
                  columnPinning: currentObjectsView?.columnPinning,
                  columnOrder: currentObjectsView?.columnOrder,
              },
        manualSorting: true,
        onSortingChange,
        debugTable: true,
        debugHeaders: true,
        debugColumns: true,
    })

    const router = useRouter()

    const { isScrolling, ref: scrollingElement } = useIsScrolling<HTMLTableElement>({ inHorizontal: true })

    const rowVirtualizer = useVirtualizer({
        count: hasNextPage ? currentData.length + 1 : currentData.length,
        getScrollElement: () => scrollingElement.current,
        estimateSize: () => TABLE_ROW_HEIGHT,
    })

    useEffect(() => {
        if (currentObjectsView) {
            const newColumnPinning = [...(currentObjectsView.columnPinning.left ?? [])]

            table.setState((state) => {
                return {
                    ...state,
                    ...currentObjectsView,
                    columnPinning: {
                        left: newColumnPinning,
                    },
                }
            })
        }
    }, [currentObjectsView])

    const virtualRows = rowVirtualizer.getVirtualItems()

    const headerGroups = table.getHeaderGroups()
    const rows = table.getRowModel().rows

    // Fetch more rows when we hit the bottom
    useEffect(() => {
        const [lastItem] = [...virtualRows].reverse()

        if (!lastItem) {
            return
        }

        if (lastItem.index === currentData?.length && hasNextPage && !isFetchingNextPage) {
            void fetchNextPage()
        }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage, currentData?.length, virtualRows])

    const headerCellRefs = useRef<(HTMLTableCellElement | null)[]>([])
    const bodyCellRefs = useRef<(HTMLTableCellElement | null)[]>([])
    const { isAnyDialogOpen } = useIsAnyDialogOpen()

    return (
        <table ref={scrollingElement} className={styles.scrollContainer} role="tabpanel" tabIndex={-1}>
            <thead
                className={styles.tableHeader}
                style={{
                    minWidth: table.getTotalSize(),
                }}
            >
                {headerGroups.map((headerGroup) => (
                    <tr key={headerGroup.id} className={styles.headerRow} tabIndex={-1}>
                        {headerGroup.headers.map((header, index) => {
                            const previousHeader = headerCellRefs.current[index - 1]

                            const isSticky = header.column.getIsPinned()

                            const leftColumnWidth = previousHeader ? previousHeader?.clientWidth : 0
                            const leftColumnLeftOffset = Number(previousHeader?.style?.left?.replace(/\D+/, ""))

                            const leftStickyOffset = leftColumnWidth + leftColumnLeftOffset

                            const nextColumn = headerGroup.headers[index + 1]?.column?.getIsPinned()
                            const isLastSticky = !nextColumn

                            while (headerCellRefs.current.length <= index) {
                                headerCellRefs.current.push(null)
                            }

                            const handleHeaderCellRef = (cellElement: HTMLTableCellElement | null) => {
                                headerCellRefs.current[index] = cellElement
                            }

                            return (
                                <TableHeaderCell
                                    ref={handleHeaderCellRef}
                                    canSort={header.column.getCanSort()}
                                    isSorted={header.column.getIsSorted()}
                                    content={header.column.columnDef.header}
                                    sortIndex={header.column.getSortIndex()}
                                    isPlaceholder={header.isPlaceholder}
                                    onClick={header.column.getToggleSortingHandler()}
                                    width={header.getSize()}
                                    colSpan={header.colSpan}
                                    context={header.getContext()}
                                    isSticky={isSticky && isDesktop}
                                    leftStickyOffset={leftStickyOffset || 0}
                                    key={header?.id}
                                    isScrolling={isScrolling}
                                    isLastSticky={isSticky && isLastSticky}
                                    columnId={header.column.columnDef.id as ColumnId}
                                    objectType={objectType}
                                    isTabbable={!isAnyDialogOpen}
                                />
                            )
                        })}
                    </tr>
                ))}
            </thead>
            <tbody
                className={styles.virtualizer}
                style={{
                    height: `${rowVirtualizer.getTotalSize()}px`,
                }}
            >
                {virtualRows?.map((virtualRow) => {
                    const isLoaderRow = virtualRow.index > currentData?.length - 1
                    const row = rows[virtualRow.index]

                    if (isLoaderRow && hasNextPage) {
                        return (
                            <TableLoaderRow
                                key={virtualRow.index}
                                height={virtualRow.size}
                                transform={virtualRow.start}
                            />
                        )
                    } else {
                        const rowData = row.original
                        const detailsURL = activeTabConfig.getDetailsRoute(rowData)
                        const visibleCells = row.getVisibleCells()

                        return (
                            <tr
                                key={row?.id}
                                style={{
                                    height: `${virtualRow.size}px`,
                                    transform: `translateY(${virtualRow.start}px)`,
                                    minWidth: table.getTotalSize(),
                                }}
                                className={styles.bodyRow}
                                onClick={() => router.push(detailsURL)}
                                onKeyDown={(event) => ifSpaceOrEnter(event, () => router.push(detailsURL))}
                                tabIndex={isAnyDialogOpen ? -1 : 0}
                                id={rowData?.id}
                                data-index={virtualRow.index}
                                ref={rowVirtualizer.measureElement}
                            >
                                {visibleCells.map((cell, index, allCells) => {
                                    const previousCell = bodyCellRefs.current[index - 1]

                                    const leftColumnWidth = previousCell ? previousCell?.clientWidth : 0
                                    const leftColumnLeftOffset = Number(previousCell?.style?.left?.replace(/\D+/, ""))

                                    const leftStickyOffset = leftColumnWidth + leftColumnLeftOffset

                                    const isSticky = cell.column.getIsPinned()

                                    const nextColumn = allCells[index + 1]?.column?.getIsPinned()

                                    const isLastSticky = !nextColumn

                                    while (bodyCellRefs.current.length <= index) {
                                        bodyCellRefs.current.push(null)
                                    }

                                    const handleBodyCellRef = (cellElement: HTMLTableCellElement | null) => {
                                        bodyCellRefs.current[index] = cellElement
                                    }

                                    return (
                                        <TableBodyCell
                                            key={cell?.id}
                                            cell={cell}
                                            ref={handleBodyCellRef}
                                            isSticky={isSticky && isDesktop}
                                            leftStickyOffset={leftStickyOffset || 0}
                                            virtualRow={virtualRow}
                                            isLastSticky={isSticky && isLastSticky}
                                            isScrolling={isScrolling}
                                            width={cell.column.getSize()}
                                            isTabbable={!isAnyDialogOpen}
                                        />
                                    )
                                })}
                            </tr>
                        )
                    }
                })}
            </tbody>
        </table>
    )
}

export default withAlternativeStates({
    LoadingState: TableLoadingState,
    ErrorState: TableErrorState,
    EmptyState: TableEmptyState,
    NoResultsState: TableNoResultsState,
    SuccessState: TableContent,
})
