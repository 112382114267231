import { Command } from "cmdk"

import styles from "./SelectListItemStack.module.scss"
import { SelectListItemStackProps } from "./SelectListItemStack.types"

export default function SelectListItemStack(props: SelectListItemStackProps) {
    const { children, emptyText = "No results found." } = props

    return (
        <Command.List className={styles.base}>
            <Command.Empty className={styles.empty}>{emptyText}</Command.Empty>
            {children}
        </Command.List>
    )
}
