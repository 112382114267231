import { BryntumDateField } from "@bryntum/schedulerpro-react"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useIsDesktop from "@hooks/useIsDesktop"
import useUser from "@hooks/useUser"

import formatDate from "@utils/formatDate"

import { Badge, Button, IconButton, Tooltip } from "@atoms"

import TimelineViewMenu from "@organisms/ObjectsView/JobTimelineView/TimelineViewMenu/TimelineViewMenu"
import UnscheduledJobsHeader from "@organisms/ObjectsView/JobTimelineView/UnscheduledJobsHeader/UnscheduledJobsHeader"
import useJobTimelineViewEventHandlers from "@organisms/ObjectsView/JobTimelineView/hooks/useJobTimelineViewEventHandlers"
import useJobTimelineViewStates from "@organisms/ObjectsView/JobTimelineView/hooks/useJobTimelineViewStates"

import styles from "./JobTimelineViewControls.module.scss"
import JobTimelineViewZoom from "./JobTimelineViewZoom/JobTimelineViewZoom"

export default function JobTimelineViewControls() {
    const isDesktop = useIsDesktop()
    const { user } = useUser()

    const { currentDate, unscheduledJobsColumnStatus, totalOfUnscheduledJobs, expandUnscheduledJobsColumn } =
        useJobTimelineViewStates()

    const nextDate = new Date(currentDate)
    const previousDate = new Date(currentDate)
    nextDate.setDate(currentDate.getDate() + 1)
    previousDate.setDate(currentDate.getDate() - 1)

    const { onTopBarDateFieldChange, onTopBarPreviousButtonClick, onTopBarNextButtonClick, onTopBarNowButtonClick } =
        useJobTimelineViewEventHandlers()

    const { localDateString: currentDateString } = formatDate({
        datetime: currentDate,
        preferredTimezone: undefined,
        short: true,
    })
    const { localDateString: previousDateString } = formatDate({
        datetime: previousDate,
        preferredTimezone: undefined,
        short: true,
    })
    const { localDateString: nextDateString } = formatDate({
        datetime: nextDate,
        preferredTimezone: undefined,
        short: true,
    })

    return (
        <div className={styles.base}>
            <div className={styles.controls}>
                <div className={styles.navControls}>
                    <Button
                        onClick={() => null}
                        colorScheme="gray"
                        size={isDesktop ? "sm" : "md"}
                        variant="outline"
                        leftIcon={icon({
                            name: "calendar",
                            family: "sharp",
                            style: "regular",
                        })}
                        className={styles.datePicker}
                    >
                        <span className={styles.stringDate}>{currentDateString}</span>
                        <BryntumDateField
                            type="datefield"
                            editable={false}
                            format="MMM D"
                            value={currentDate}
                            inputType="button"
                            cls={styles.bryntumDateField}
                            onChange={onTopBarDateFieldChange}
                            hidden={true}
                        />
                    </Button>
                    <div className={styles.previousAndNextDays}>
                        <Tooltip label={previousDateString}>
                            <IconButton
                                icon={icon({
                                    name: "angle-left",
                                    style: "regular",
                                    family: "sharp",
                                })}
                                onClick={onTopBarPreviousButtonClick}
                                colorScheme="gray"
                                size={isDesktop ? "sm" : "md"}
                                variant="ghost"
                            />
                        </Tooltip>
                        <Tooltip label={nextDateString}>
                            <IconButton
                                icon={icon({
                                    name: "angle-right",
                                    style: "regular",
                                    family: "sharp",
                                })}
                                onClick={onTopBarNextButtonClick}
                                colorScheme="gray"
                                size={isDesktop ? "sm" : "md"}
                                variant="ghost"
                            />
                        </Tooltip>
                    </div>

                    <Button
                        colorScheme="gray"
                        size={isDesktop ? "sm" : "md"}
                        variant="outline"
                        onClick={onTopBarNowButtonClick}
                    >
                        Now
                    </Button>
                </div>

                <div className={styles.viewControls}>
                    <JobTimelineViewZoom />
                    <TimelineViewMenu />
                    {unscheduledJobsColumnStatus === "expanded" || !isDesktop || !user?.isServiceDispatcher ? null : (
                        <Button
                            colorScheme="gray"
                            size={isDesktop ? "sm" : "md"}
                            variant="outline"
                            leftIcon={icon({
                                name: "arrow-left-to-line",
                                style: "regular",
                                family: "sharp",
                            })}
                            onClick={expandUnscheduledJobsColumn}
                            noDebounce={true}
                        >
                            <div className={styles.badgeContainer}>
                                Unscheduled Jobs
                                <Badge
                                    colorScheme={totalOfUnscheduledJobs === 0 ? "gray" : "tomato"}
                                    size="md"
                                    type="counter"
                                    variant="subtle"
                                >
                                    {totalOfUnscheduledJobs}
                                </Badge>
                            </div>
                        </Button>
                    )}
                </div>
            </div>
            <UnscheduledJobsHeader />
        </div>
    )
}
