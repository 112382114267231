import { Component, Fragment } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import UniversalLinkButton from "../buttons/UniversalLinkButton";
import { formatLocalTime } from "../utils/utils";

import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)


class QuickBooksDesktopSetupForm extends Component {

    getFormattedLastConnected = (lastConnected, preferred_timezone) => {
        if (lastConnected !== null) {
            const localTime = dayjs(lastConnected).tz(preferred_timezone)
            const { localDateTimeString } = formatLocalTime(lastConnected, preferred_timezone, false, true, false, true)

            const lateConnectionTime = dayjs().startOf("hour").subtract(2, "hour")

            if (localTime.isSameOrAfter(lateConnectionTime)) {
                return {
                    "status": "Connected",
                    "className": "text-success",
                    "dateTime": localDateTimeString,
                }
            }
            else {
                return {
                    "status": "Connection Broken",
                    "className": "text-warning",
                    "dateTime": localDateTimeString,
                }
            }
        }
        else {
            return {
                "status": "Not Connected",
                "className": "",
                "dateTime": "N/A",
            }
        }
    }

    showTranferredRecordSpinner = () => {
        const {configuration} = this.props
        return configuration.quickbooks_desktop_importable_customer_count > configuration.imported_customer_record_counts.total_record_count
    }

    showProcessedRecordSpinner = () => {
        const {configuration} = this.props
        return !this.showTranferredRecordSpinner() && configuration.imported_customer_record_counts.transferred_count !== 0
    }

    showImportedRecordSpinner = () => {
        const {configuration} = this.props
        return configuration.imported_customer_record_counts.ready_for_import_count !== 0
    }

    shouldShowAccountResyncButton = () => {
        const {configuration} = this.props

        return (
            configuration.quickbooks_desktop_accounts_accounts_receivable.length !== 0 ||
            configuration.quickbooks_desktop_accounts_income.length !== 0 ||
            configuration.quickbooks_desktop_accounts_other_current_asset.length !== 0
        )
    }

    showTemplateResyncButton = () => {
        const {configuration} = this.props

        return configuration.quickbooks_desktop_templates_invoice.length !== 0
    }

    renderLineItemConfig = () => {
        const {configuration, updateConfigData} = this.props

        if (configuration.quickbooks_line_item_scheme === 1) {  // Split Generic
            return (
                <Fragment>
                    <p className="data-panel__form__caption data-panel__form__caption--field-inline-after-input">Select the account you use to categorize service revenue (income). Roopairs will credit this account with each service line item's amount.</p>
                    <div className={"data-panel__form__field__input data-panel__form__field__input--inline"}>
                        <select key="service_revenue_select" className={configuration.quickbooks_desktop_accounts_income.length === 0 ? "disabled" : ""} name="quickbooks_desktop_invoice_service_revenue_account" id="id_quickbooks_desktop_invoice_service_revenue_account" defaultValue={configuration.quickbooks_desktop_invoice_service_revenue_account} onChange={event => updateConfigData(event.target.name, event.target.value)} autoComplete="off" disabled={configuration.quickbooks_desktop_accounts_income.length === 0}>
                            {
                                configuration.quickbooks_desktop_accounts_income.length === 0
                                ?
                                <option key="default" value="">No Accounts Available</option>
                                :
                                <Fragment>
                                    <option key="default" value="">Choose an Account</option>
                                    {configuration.quickbooks_desktop_accounts_income.map((account, index) => {
                                        return <option key={`service_revenue_${index}`} value={account.value}>{account.label}</option>
                                    })}
                                </Fragment>
                            }
                        </select>
                    </div>
                    <p className="data-panel__form__caption data-panel__form__caption--field-inline-after-input">Select the account you use to categorize part revenue (income). Roopairs will credit this account with each part line item's amount.</p>
                    <div className={"data-panel__form__field__input data-panel__form__field__input--inline"}>
                        <select key="part_revenue_select" className={configuration.quickbooks_desktop_accounts_income.length === 0 ? "disabled" : ""} name="quickbooks_desktop_invoice_part_revenue_account" id="id_quickbooks_desktop_invoice_part_revenue_account" defaultValue={configuration.quickbooks_desktop_invoice_part_revenue_account} onChange={event => updateConfigData(event.target.name, event.target.value)} autoComplete="off" disabled={configuration.quickbooks_desktop_accounts_income.length === 0}>
                            {
                                configuration.quickbooks_desktop_accounts_income.length === 0
                                ?
                                <option key="default" value="">No Accounts Available</option>
                                :
                                <Fragment>
                                    <option key="default" value="">Choose an Account</option>
                                    {configuration.quickbooks_desktop_accounts_income.map((account, index) => {
                                        return <option key={`part_revenue_${index}`} value={account.value}>{account.label}</option>
                                    })}
                                </Fragment>
                            }
                        </select>
                    </div>
                    <p className="data-panel__form__caption data-panel__form__caption--field-inline-after-input">Select the account you use to categorize miscellaneous charge revenue (income). Roopairs will credit this account with each miscellaneous line item's amount.</p>
                    <div className={"data-panel__form__field__input data-panel__form__field__input--inline"}>
                        <select key="other_revenue_select" className={configuration.quickbooks_desktop_accounts_income.length === 0 ? "disabled" : ""} name="quickbooks_desktop_invoice_other_revenue_account" id="id_quickbooks_desktop_invoice_other_revenue_account" defaultValue={configuration.quickbooks_desktop_invoice_other_revenue_account} onChange={event => updateConfigData(event.target.name, event.target.value)} autoComplete="off" disabled={configuration.quickbooks_desktop_accounts_income.length === 0}>
                            {
                                configuration.quickbooks_desktop_accounts_income.length === 0
                                ?
                                <option key="default" value="">No Accounts Available</option>
                                :
                                <Fragment>
                                    <option key="default" value="">Choose an Account</option>
                                    {configuration.quickbooks_desktop_accounts_income.map((account, index) => {
                                        return <option key={`other_revenue_${index}`} value={account.value}>{account.label}</option>
                                    })}
                                </Fragment>
                            }
                        </select>
                    </div>
                    <p className="data-panel__form__caption data-panel__form__caption--field-inline-after-input">Select the account you use to categorize discounts (income). Roopairs will debit this account with each discount line item's amount.</p>
                    <div className={"data-panel__form__field__input data-panel__form__field__input--inline"}>
                        <select key="discount_revenue_select" className={configuration.quickbooks_desktop_accounts_income.length === 0 ? "disabled" : ""} name="quickbooks_desktop_invoice_discount_revenue_account" id="id_quickbooks_desktop_invoice_discount_revenue_account" defaultValue={configuration.quickbooks_desktop_invoice_discount_revenue_account} onChange={event => updateConfigData(event.target.name, event.target.value)} autoComplete="off" disabled={configuration.quickbooks_desktop_accounts_income.length === 0}>
                            {
                                configuration.quickbooks_desktop_accounts_income.length === 0
                                ?
                                <option key="default" value="">No Accounts Available</option>
                                :
                                <Fragment>
                                    <option key="default" value="">Choose an Account</option>
                                    {configuration.quickbooks_desktop_accounts_income.map((account, index) => {
                                        return <option key={`discount_revenue_${index}`} value={account.value}>{account.label}</option>
                                    })}
                                </Fragment>
                            }
                        </select>
                    </div>
                </Fragment>
            )

        }
        else { // Split Detailed
            return null  // QuickBooks items are chosen at the invoice line item level
        }
    }

    render() {
        const {configuration, updateConfigData} = this.props
        const {status, className, dateTime} = this.getFormattedLastConnected(configuration.quickbooks_desktop_last_connected, configuration.preferred_timezone)

        return (
            <Fragment>
                <p className="data-panel__form__caption">Follow the set up instructions below to connect QuickBooks Desktop to Roopairs:</p>
                <div className="data-panel__form__field" aria-label="Step 1: Download your configuration file">
                    <span className="data-panel__form__field__label" aria-hidden="true">Step 1: Download your configuration file</span>
                    <p className="data-panel__form__caption data-panel__form__caption--field-inline">Click the button below to download your Roopairs account's configuration file. You'll use this file to hook up the QuickBooks Web Connector to Roopairs.</p>
                    <ButtonGroup className="button-group--inline">
                        <ButtonGroupRow>
                            <UniversalLinkButton type="secondary" text="Download QWC File" destination={configuration.qwc_url} download="roopairs.qwc" />
                        </ButtonGroupRow>
                    </ButtonGroup>
                </div>
                <div className="data-panel__form__field" aria-label="Step 2: Open the Web Connector">
                    <span className="data-panel__form__field__label" aria-hidden="true">Step 2: Open the Web Connector</span>
                    <p className="data-panel__form__caption data-panel__form__caption--field-inline">
                        In QuickBooks Desktop, open the QuickBooks Web Connector by going to <strong>File</strong> menu, selecting <strong>App Management</strong>, and then clicking <strong>Update Web Services</strong>.
                        </p>
                </div>
                <div className="data-panel__form__field" aria-label="Step 3: Connect QuickBooks to Roopairs">
                    <span className="data-panel__form__field__label" aria-hidden="true">Step 3: Connect QuickBooks to Roopairs</span>
                    <p className="data-panel__form__caption data-panel__form__caption--field-inline">
                        Click <strong>Add an Application</strong> and then select the configuration file you downloaded in Step 1.<br /><br />
                        In the "Authorize New Web Service" window, click OK to contine. In the "Application Certificate" window, select the "Yes, always" option and leave the "access personal information" box unchecked. Click OK on the confirmation and then click Continue and then Done.<br /><br />
                        Once the application is added, click the checkbox next to the Roopairs application and then click <strong>Update Selected</strong>. You'll be prompted to enter your Roopairs password. Once you've entered your password, confirm you want to save it and then click <strong>Update Selected</strong> again.
                        </p>
                </div>
                <div className="data-panel__form__field" aria-label="Step 4: Check your connection">
                    <span className="data-panel__form__field__label" aria-hidden="true">Step 4: Check your connection</span>
                    <p className="data-panel__form__caption data-panel__form__caption--field-inline">The status indicator below shows if your QuickBooks Web Connector is properly installed, configured, and running.<br /><br /><span>Status: <strong className={className}>{status}</strong></span><br /><span>Last Connection: <strong>{dateTime}</strong></span></p>
                    <ButtonGroup className="button-group--inline">
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Refresh Connection Status" handler={event => location.reload()} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                </div>
                <div className="data-panel__form__field" aria-label="Step 5: Configure Service Location Generation">
                    <span className="data-panel__form__field__label" aria-hidden="true">Step 5: Configure Service Location Generation (optional)</span>
                    <p className="data-panel__form__caption data-panel__form__caption--field-inline-after-input">Roopairs can generate service locations for top-level customer records with no children. Select the source from which an address can be pulled. <br></br>None means no locations are generated; Billing Address pulls from the billing address field; Shipping Address pulls from the shipping address field; and Shipping Addresses List creates a location for each address in the ship to list.</p>
                    <div className={"data-panel__form__field__input data-panel__form__field__input--inline"}>
                        <select name="quickbooks_service_location_generation_source" id="quickbooks_service_location_generation_source" defaultValue={configuration.quickbooks_service_location_generation_source} onChange={event => updateConfigData(event.target.name, parseInt(event.target.value))} autoComplete="off">
                            <option key="default" value="">Choose a Source</option>
                            {configuration.quickbooks_service_location_generation_sources.map((scheme, index) => {
                                return <option key={index} value={scheme.value}>{scheme.label}</option>
                            })}
                        </select>
                    </div>

                </div>
                <div className="data-panel__form__field" aria-label="Step 6: Import Existing Customers">
                    <span className="data-panel__form__field__label" aria-hidden="true">Step 6: Import Existing Clients (optional)</span>
                    {
                        configuration.quickbooks_desktop_importable_customer_count
                        ?
                        <Fragment>
                            <p className="data-panel__form__caption data-panel__form__caption--field-inline">
                                The counts below represent the current import status of your QuickBooks Clients. When ready, please review, confirm, and import any Clients you want to bring into Roopairs.
                                <br /><br />
                                <strong>Transferred</strong> {configuration.imported_customer_record_counts.total_record_count} ({configuration.quickbooks_desktop_importable_customer_count} reported){status === "Connected" && this.showTranferredRecordSpinner() && <span aria-label="Transfer in progress...">&nbsp;&nbsp;<i className="fa-sharp fa-regular fa-rotate fa-spin" aria-hidden="true"></i></span>}<br />
                                <strong>Processed</strong> {configuration.imported_customer_record_counts.total_importable_record_count - configuration.imported_customer_record_counts.transferred_count} {configuration.imported_customer_record_counts.transferred_count !== 0 ? `(${configuration.imported_customer_record_counts.transferred_count} queued, ` : "("}{configuration.imported_customer_record_counts.total_record_count - configuration.imported_customer_record_counts.total_importable_record_count} ignored){this.showProcessedRecordSpinner() && <span aria-label="Processing in progress...">&nbsp;&nbsp;<i className="fa-sharp fa-regular fa-rotate fa-spin" aria-hidden="true"></i></span>}<br />
                                <strong>Ready for Review</strong> {configuration.imported_customer_record_counts.processed_count}<br />
                                <strong>Confirmed</strong> {configuration.imported_customer_record_counts.confirmed_count}<br />
                                <strong>Imported</strong> {configuration.imported_customer_record_counts.imported_count} {configuration.imported_customer_record_counts.ready_for_import_count !== 0 ? `(${configuration.imported_customer_record_counts.ready_for_import_count} queued)` : ""}{this.showImportedRecordSpinner() && <span aria-label="Import in progress...">&nbsp;&nbsp;<i className="fa-sharp fa-regular fa-rotate fa-spin" aria-hidden="true"></i></span>}<br />
                            </p>
                            <ButtonGroup className="button-group--inline">
                                <ButtonGroupRow>
                                    <UniversalLinkButton type="secondary" text="Review Processed Records" destination={DjangoUrls["quickbooks_desktop:quickbooks-desktop-records-processed-list"](window.MARKETPLACE_ENTITY_SLUG)} />
                                </ButtonGroupRow>
                                <ButtonGroupRow>
                                    <UniversalLinkButton type="secondary" text="View Confirmed Records" destination={DjangoUrls["quickbooks_desktop:quickbooks-desktop-records-confirmed-list"](window.MARKETPLACE_ENTITY_SLUG)} />
                                </ButtonGroupRow>
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Import Confirmed Records" handler={event => {document.querySelector("#message_modal_confirm_mark_for_import_quickbooks_imported_customers").style.display = ""; window.MicroModal.show("message_modal_confirm_mark_for_import_quickbooks_imported_customers")}} />
                                </ButtonGroupRow>
                            </ButtonGroup>
                            <ButtonGroup className="button-group--inline">
                                <ButtonGroupRow>
                                    <UniversalButton type="danger" text="Confirm All Non-Error Records" handler={event => {document.querySelector("#message_modal_confirm_confirm_new_matched_quickbooks_imported_customers").style.display = ""; window.MicroModal.show("message_modal_confirm_confirm_new_matched_quickbooks_imported_customers")}} />
                                </ButtonGroupRow>
                                <ButtonGroupRow>
                                    <UniversalButton type="danger" text="Re-process Records" handler={event => {document.querySelector("#message_modal_confirm_reprocess_quickbooks_imported_customers").style.display = ""; window.MicroModal.show("message_modal_confirm_reprocess_quickbooks_imported_customers")}} />
                                </ButtonGroupRow>
                                <ButtonGroupRow>
                                    <UniversalButton type="danger" text="Re-sync Records" handler={event => {document.querySelector("#message_modal_confirm_resync_quickbooks_imported_customers").style.display = ""; window.MicroModal.show("message_modal_confirm_resync_quickbooks_imported_customers")}} />
                                </ButtonGroupRow>
                            </ButtonGroup>
                        </Fragment>
                        :
                        <p className="data-panel__form__caption data-panel__form__caption--field-inline">Your QuickBooks Desktop instance hasn't reported any existing customers.</p>
                    }
                </div>
                <div className="data-panel__form__field" aria-label="Step 7: Configure the Integration">
                    <span className="data-panel__form__field__label" aria-hidden="true">Step 7: Configure the Integration</span>
                    <p className="data-panel__form__caption data-panel__form__caption--field-inline">The Roopairs QuickBooks Desktop integration requires a few configuration options to be set in order to sync invoices and invoice payments.<br />Account and template options should appear after the first successful sync with your QuickBooks account. If you need to refresh the options, click the appropriate "re-sync" button below, then click <strong>Update Selected</strong> again in the web connector, and then click "Refresh Selection Options" below.<br />Note that if you change these settings after an invoice or invoice payment has been synced, Roopairs will not retroactively go back and update that object in QuickBooks; you'll have to reconcile it manually.</p>
                    <ButtonGroup className="button-group--inline">
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Refresh Selection Options" handler={event => location.reload()} />
                        </ButtonGroupRow>
                    </ButtonGroup>

                    <p className="data-panel__form__caption data-panel__form__caption--field-inline-after-input">Select the line item scheme you'd like to use. <br></br>Generic Split assigns an account to each line item type (service, part, other, discount) and Detailed Split lets you assign an account to each line item. Detailed Split is only recommended if you have an <em>extremely</em> well established pricebook -- this scheme requires configuring some QuickBooks settings each time you want to add a pricebook item in Roopairs.</p>
                    <div className={"data-panel__form__field__input data-panel__form__field__input--inline"}>
                        <select name="quickbooks_line_item_scheme" id="id_quickbooks_line_item_scheme" defaultValue={configuration.quickbooks_line_item_scheme} onChange={event => updateConfigData(event.target.name, parseInt(event.target.value))} autoComplete="off">
                            <option key="default" value="">Choose a Scheme</option>
                            {configuration.quickbooks_line_item_schemes.map((scheme, index) => {
                                return <option key={index} value={scheme.value}>{scheme.label}</option>
                            })}
                        </select>
                    </div>

                    {this.renderLineItemConfig()}

                    <p className="data-panel__form__caption data-panel__form__caption--field-inline-after-input">Select the account you use to categorize accounts receivable (accounts receivable). QuickBooks will credit this account with invoice totals when Roopairs syncs invoices, and will debit this account when Roopairs syncs invoice payments.</p>
                    <div className={"data-panel__form__field__input data-panel__form__field__input--inline"}>
                        <select className={configuration.quickbooks_desktop_accounts_accounts_receivable.length === 0 ? "disabled" : ""} name="quickbooks_desktop_accounts_receivable_account" id="id_quickbooks_desktop_accounts_receivable_account" defaultValue={configuration.quickbooks_desktop_accounts_receivable_account} onChange={event => updateConfigData(event.target.name, event.target.value)} autoComplete="off" disabled={configuration.quickbooks_desktop_accounts_accounts_receivable.length === 0}>
                            {
                                configuration.quickbooks_desktop_accounts_accounts_receivable.length === 0
                                ?
                                <option key="default" value="">No Accounts Available</option>
                                :
                                <Fragment>
                                    <option key="default" value="">Choose an Account</option>
                                    {configuration.quickbooks_desktop_accounts_accounts_receivable.map((account, index) => {
                                        return <option key={index} value={account.value}>{account.label}</option>
                                    })}
                                </Fragment>
                            }
                        </select>
                    </div>
                    <p className="data-panel__form__caption data-panel__form__caption--field-inline-after-input">Select the account you use to categorize bad debt (expense). QuickBooks will debit this account when Roopairs syncs uncollectible payments / bad debt from forgiven invoices.</p>
                    <div className={"data-panel__form__field__input data-panel__form__field__input--inline"}>
                        <select className={configuration.quickbooks_desktop_accounts_expense.length === 0 ? "disabled" : ""} name="quickbooks_desktop_bad_debt_account" id="id_quickbooks_desktop_bad_debt_account" defaultValue={configuration.quickbooks_desktop_bad_debt_account} onChange={event => updateConfigData(event.target.name, event.target.value)} autoComplete="off" disabled={configuration.quickbooks_desktop_accounts_expense.length === 0}>
                            {
                                configuration.quickbooks_desktop_accounts_expense.length === 0
                                ?
                                <option key="default" value="">No Accounts Available</option>
                                :
                                <Fragment>
                                    <option key="default" value="">Choose an Account</option>
                                    {configuration.quickbooks_desktop_accounts_expense.map((account, index) => {
                                        return <option key={index} value={account.value}>{account.label}</option>
                                    })}
                                </Fragment>
                            }
                        </select>
                    </div>
                    <p className="data-panel__form__caption data-panel__form__caption--field-inline-after-input">Select the account you use to categorize inventory purchases (cost of goods sold). Quickbooks uses this account when creating parts orders for inventory parts.</p>
                    <div className={"data-panel__form__field__input data-panel__form__field__input--inline"}>
                        <select className={configuration.quickbooks_desktop_accounts_cogs.length === 0 ? "disabled" : ""} name="quickbooks_desktop_cogs_account" id="id_quickbooks_desktop_cogs_account" defaultValue={configuration.quickbooks_desktop_cogs_account} onChange={event => updateConfigData(event.target.name, event.target.value)} autoComplete="off" disabled={configuration.quickbooks_desktop_accounts_cogs.length === 0}>
                            {
                                configuration.quickbooks_desktop_accounts_cogs.length === 0
                                ?
                                <option key="default" value="">No Accounts Available</option>
                                :
                                <Fragment>
                                    <option key="default" value="">Choose an Account</option>
                                    {configuration.quickbooks_desktop_accounts_cogs.map((account, index) => {
                                        return <option key={index} value={account.value}>{account.label}</option>
                                    })}
                                </Fragment>
                            }
                        </select>
                    </div>
                    <p className="data-panel__form__caption data-panel__form__caption--field-inline-after-input">Select the account you use to categorize inventory assets (other current assets). Quickbooks will credit this acccount as you account for purchased inventory in QuickBooks.</p>
                    <div className={"data-panel__form__field__input data-panel__form__field__input--inline"}>
                        <select className={configuration.quickbooks_desktop_accounts_other_current_asset.length === 0 ? "disabled" : ""} name="quickbooks_desktop_inventory_asset_account" id="id_quickbooks_desktop_inventory_asset_account" defaultValue={configuration.quickbooks_desktop_inventory_asset_account} onChange={event => updateConfigData(event.target.name, event.target.value)} autoComplete="off" disabled={configuration.quickbooks_desktop_accounts_other_current_asset.length === 0}>
                            {
                                configuration.quickbooks_desktop_accounts_other_current_asset.length === 0
                                ?
                                <option key="default" value="">No Accounts Available</option>
                                :
                                <Fragment>
                                    <option key="default" value="">Choose an Account</option>
                                    {configuration.quickbooks_desktop_accounts_other_current_asset.map((account, index) => {
                                        return <option key={index} value={account.value}>{account.label}</option>
                                    })}
                                </Fragment>
                            }
                        </select>
                    </div>
                    <p className="data-panel__form__caption data-panel__form__caption--field-inline-after-input">Select the account you use to categorize received invoice payments (other current assets). QuickBooks will add payments synced by Roopairs into this account. Payments in this account are reconciled against deposited funds in QuickBooks.</p>
                    <div className={"data-panel__form__field__input data-panel__form__field__input--inline"}>
                        <select className={configuration.quickbooks_desktop_accounts_other_current_asset.length === 0 ? "disabled" : ""} name="quickbooks_desktop_invoice_payments_account" id="id_quickbooks_desktop_invoice_payments_account" defaultValue={configuration.quickbooks_desktop_invoice_payments_account} onChange={event => updateConfigData(event.target.name, event.target.value)} autoComplete="off" disabled={configuration.quickbooks_desktop_accounts_other_current_asset.length === 0}>
                            {
                                configuration.quickbooks_desktop_accounts_other_current_asset.length === 0
                                ?
                                <option key="default" value="">No Accounts Available</option>
                                :
                                <Fragment>
                                    <option key="default" value="">Choose an Account</option>
                                    {configuration.quickbooks_desktop_accounts_other_current_asset.map((account, index) => {
                                        return <option key={index} value={account.value}>{account.label}</option>
                                    })}
                                </Fragment>
                            }
                        </select>
                    </div>
                    <p className="data-panel__form__caption data-panel__form__caption--field-inline-after-input">Select the template you use for QuickBooks invoices (invoice). QuickBooks will use this template for invoices synced by Roopairs.</p>
                    <div className={"data-panel__form__field__input data-panel__form__field__input--inline"}>
                        <select className={configuration.quickbooks_desktop_templates_invoice.length === 0 ? "disabled" : ""} name="quickbooks_desktop_invoice_template" id="id_quickbooks_desktop_invoice_template" defaultValue={configuration.quickbooks_desktop_invoice_template} onChange={event => updateConfigData(event.target.name, event.target.value)} autoComplete="off" disabled={configuration.quickbooks_desktop_templates_invoice.length === 0}>
                            {
                                configuration.quickbooks_desktop_templates_invoice.length === 0
                                ?
                                <option key="default" value="">No Templates Available</option>
                                :
                                <Fragment>
                                    <option key="default" value="">Choose a Template</option>
                                    {configuration.quickbooks_desktop_templates_invoice.map((account, index) => {
                                        return <option key={index} value={account.value}>{account.label}</option>
                                    })}
                                </Fragment>
                            }
                        </select>
                    </div>
                    <ButtonGroup className="button-group--inline">
                        {
                            this.shouldShowAccountResyncButton() && (
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Re-sync Accounts" handler={event => {document.querySelector("#message_modal_confirm_resync_quickbooks_accounts").style.display = ""; window.MicroModal.show("message_modal_confirm_resync_quickbooks_accounts")}} />
                                </ButtonGroupRow>
                            )
                        }
                        {
                            this.showTemplateResyncButton() && (
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Re-sync Templates" handler={event => {document.querySelector("#message_modal_confirm_resync_quickbooks_templates").style.display = ""; window.MicroModal.show("message_modal_confirm_resync_quickbooks_templates")}} />
                                </ButtonGroupRow>
                            )
                        }
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Re-sync Items" handler={event => {document.querySelector("#message_modal_confirm_resync_quickbooks_items").style.display = ""; window.MicroModal.show("message_modal_confirm_resync_quickbooks_items")}} />
                        </ButtonGroupRow>
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Re-sync Vendors" handler={event => {document.querySelector("#message_modal_confirm_resync_quickbooks_vendors").style.display = ""; window.MicroModal.show("message_modal_confirm_resync_quickbooks_vendors")}} />
                        </ButtonGroupRow>
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Re-sync Payment Methods" handler={event => {document.querySelector("#message_modal_confirm_resync_quickbooks_payment_methods").style.display = ""; window.MicroModal.show("message_modal_confirm_resync_quickbooks_payment_methods")}} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                </div>
                <div className="data-panel__form__field" aria-label="Step 8: Enable the Integration">
                    <span className="data-panel__form__field__label" aria-hidden="true">Step 8: Enable the Integration</span>
                    <p className="data-panel__form__caption data-panel__form__caption--field-inline">Once you're all set, enable the configuration and let the syncing begin! Note that invoices and payments will only sync once all step 7 options are selected.</p>
                    <div className={"data-panel__form__field__input data-panel__form__field__input--inline"}>
                        <select name="quickbooks_desktop_sync_enabled" id="id_quickbooks_desktop_sync_enabled" defaultValue={configuration.quickbooks_desktop_sync_enabled} onChange={event => updateConfigData(event.target.name, event.target.value)} autoComplete="off">
                            <option key="0" value="true">Enabled</option>
                            <option key="1" value="false">Disabled</option>
                        </select>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default QuickBooksDesktopSetupForm;
