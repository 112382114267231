import { useState } from "react"
import { isValidPhoneNumber } from "react-phone-number-input"
import * as Yup from "yup"

import useIsDesktop from "@hooks/useIsDesktop"
import useUser from "@hooks/useUser"

import { PhoneNumberInput } from "@molecules/Form/Form"
import GenericForm from "@molecules/Form/GenericForm/GenericForm"
import { MutationSuccessFunctionParams } from "@molecules/Form/GenericForm/GenericForm.types"
import toast from "@molecules/Toast/Toast"

import { SecurityCheckDialog } from "@organisms/index"

import { USER_ENDPOINT } from "@endpoints/user"

import { PhoneChangeErrorResponse, PhoneFormProps, UserPhone } from "./PhoneForm.types"

const formSchema: Yup.ObjectSchema<UserPhone> = Yup.object().shape({
    phone: Yup.string()
        .required("Please enter a phone number")
        .test("is-valid-phone", "Please enter a valid phone number", (value) => isValidPhoneNumber(value)),
})

export default function PhoneForm(props: PhoneFormProps) {
    const { showLabel = true } = props

    const { user, updateUser } = useUser()
    const isDesktop = useIsDesktop()

    const [isVerifyingPhone, setIsVerifyingPhone] = useState<boolean>(false)

    const startPhoneVerification = () => {
        setIsVerifyingPhone(true)
    }

    const endPhoneVerification = () => {
        setIsVerifyingPhone(false)

        updateUser({ phone_verified: true })

        toast({
            size: "md",
            title: "Phone number updated",
            type: "success",
        })
    }

    const onSuccessfulPhoneFormSubmission = (args: MutationSuccessFunctionParams<UserPhone, User>) => {
        const phoneWasChanged = user?.phone !== args.formValues.phone

        if (phoneWasChanged) {
            startPhoneVerification()

            updateUser({
                phone: args.formValues.phone,
                phone_verified: false,
            })
        } else {
            endPhoneVerification()
        }
    }

    return (
        <>
            <SecurityCheckDialog
                checkFor="phone"
                onSuccess={endPhoneVerification}
                onOpenChange={setIsVerifyingPhone}
                isOpen={isVerifyingPhone}
            />

            <GenericForm<UserPhone, User, PhoneChangeErrorResponse, UserPhone>
                formConfig={{
                    defaultValues: {
                        phone: "",
                    },
                }}
                formValidationSchema={formSchema}
                formValues={{
                    phone: user?.phone ?? "",
                }}
                mutationConfig={{
                    genericErrorMessage: "Could not update phone number",
                    method: "PATCH",
                    endpoint: USER_ENDPOINT,
                }}
                onMutationSuccess={onSuccessfulPhoneFormSubmission}
            >
                {({ isPending: isChangingPhone, form }) => {
                    const isTyping = user?.phone !== form.getValues()?.phone

                    const isPhoneVerificationRequired =
                        !user?.phone_verified && user?.phone && form.getValues().phone === user?.phone && !isTyping

                    const buttonCopy = !user?.phone
                        ? "Set"
                        : isTyping || !isPhoneVerificationRequired
                          ? "Update"
                          : "Verify"

                    return (
                        <PhoneNumberInput<UserPhone>
                            label={showLabel ? "Phone number" : undefined}
                            size={isDesktop ? "md" : "lg"}
                            placeholder="(000) 000-0000"
                            aria-label="Phone number"
                            defaultCountryCode={user?.service_company?.phone_number_country}
                            button={{
                                colorScheme: "gray",
                                variant: "solid",
                                type: isPhoneVerificationRequired ? "button" : "submit",
                                children: buttonCopy,
                                isLoading: !user || isChangingPhone,
                                loadingText: "Updating",
                                onClick: isPhoneVerificationRequired ? startPhoneVerification : () => undefined,
                            }}
                            inputHelpText={
                                isPhoneVerificationRequired
                                    ? {
                                          children: "Verification required",
                                          type: "warning",
                                      }
                                    : undefined
                            }
                            name="phone"
                        />
                    )
                }}
            </GenericForm>
        </>
    )
}
