import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { forwardRef, useState } from "react"

import Input from "@molecules/Form/inputs/Input/Input"
import { InputProps } from "@molecules/Form/inputs/Input/Input.types"
import PasswordStrengthIndicator from "@molecules/Form/inputs/PasswordStrengthIndicator/PasswordStrengthIndicator"

import { PasswordInputProps } from "./PasswordInput.types"

function PasswordInput<T>(props: PasswordInputProps & InputProps<T>, ref: React.Ref<HTMLInputElement>) {
    const { isDisabled, size, displayStrengthIndicator = false, ...rest } = props
    const [value, setValue] = useState<string>("")

    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)

    const strengthIndicatorSize = size === "sm" || size === "md" ? "sm" : "md"

    return (
        <Input
            ref={ref}
            type={isPasswordVisible ? "text" : "password"}
            isDisabled={isDisabled}
            size={size}
            placeholder="Password"
            renderLabelRightItem={
                displayStrengthIndicator
                    ? () => <PasswordStrengthIndicator password={value} size={strengthIndicatorSize} />
                    : undefined
            }
            iconButton={{
                icon: icon({ name: "eye", style: "regular", family: "sharp" }),
                iconOnActive: icon({ name: "eye-slash", style: "regular", family: "sharp" }),
                onClick: () => setIsPasswordVisible((prev) => !prev),
                iconIsActive: isPasswordVisible,
                noDebounce: true,
            }}
            onChangeCapture={(e) => setValue(e.target.value)}
            {...rest}
        />
    )
}

export default forwardRef(PasswordInput) as unknown as typeof PasswordInput
