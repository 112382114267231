import clsx from "clsx"
import { formatPhoneNumber } from "react-phone-number-input"

import useIsDesktop from "@hooks/useIsDesktop"
import useRouter from "@hooks/useRouter"

import { NavigationLink } from "@atoms/index"

import OTPInput from "@molecules/Form/inputs/OTPInput/OTPInput"

import { OTPForm } from "@organisms/index"

import authGlobalStyles from "@pages/Auth/Auth.module.scss"
import useAuth from "@pages/Auth/Auth.store"

import { AUTH_ENDPOINTS } from "@endpoints/auth"

import styles from "./AuthOTP.module.scss"
import { AuthOTPProps } from "./AuthOTP.types"

export default function AuthOTP(props: AuthOTPProps) {
    const { isActive } = props

    const router = useRouter()

    const { setAuthMethod, authMethod, setIsOnOTPSlide, emailData, phoneData, postAuthRedirectRoute } = useAuth()

    const isDesktop = useIsDesktop()

    const onCodeVerificationSuccessful = () => {
        router.replace(postAuthRedirectRoute)
    }

    const onRequestPasswordInsteadClick = () => {
        setIsOnOTPSlide(false)
        const newMethod = authMethod === "email_and_code" ? "email_and_password" : "phone_and_password"
        void setAuthMethod(newMethod)
    }

    return (
        <OTPForm.Root
            checkFor={authMethod === "email_and_code" ? "email" : "phone"}
            codeRequestEndpoint={
                authMethod === "email_and_code" ? AUTH_ENDPOINTS.OTP.EMAIL_REQUEST : AUTH_ENDPOINTS.OTP.SMS_REQUEST
            }
            codeVerificationEndpoint={
                authMethod === "email_and_code"
                    ? AUTH_ENDPOINTS.OTP.EMAIL_AUTHENTICATE
                    : AUTH_ENDPOINTS.OTP.SMS_AUTHENTICATE
            }
            onCodeVerificationSuccessful={onCodeVerificationSuccessful}
            email={emailData?.email ?? undefined}
            emailId={emailData?.emailId ?? undefined}
            phone={phoneData?.phone ?? undefined}
            phoneId={phoneData?.phoneId ?? undefined}
            triggerOTPReset={!isActive}
            hasRequestedCode={true}
        >
            <div className={styles.contentAndForm}>
                <div className={styles.copyWithForm}>
                    <div className={styles.copy}>
                        <div className={styles.subtitle}>
                            {authMethod === "email_and_code" ? (
                                <>
                                    We&apos;ve emailed a code{" "}
                                    <span className={styles.nowrap}>
                                        to <span className={styles.method}>{emailData?.email}</span>
                                    </span>
                                </>
                            ) : (
                                <>
                                    We&apos;ve texted a code{" "}
                                    <span className={styles.nowrap}>
                                        to{" "}
                                        <span className={styles.method}>
                                            {phoneData.phone !== null && formatPhoneNumber(phoneData.phone)}
                                        </span>
                                    </span>
                                </>
                            )}
                        </div>
                        {authMethod === "email_and_code" && (
                            <div className={styles.caption}>
                                Can&apos;t find our email? Check your spam folder or resend a code.
                            </div>
                        )}
                    </div>
                    <OTPForm.SendCodeButton size={isDesktop ? "md" : "lg"} variant="ghost" isTabbable={isActive} />

                    <div className={styles.otpAndSignInButton}>
                        <OTPForm.AlertBanner fullWidth={false} className={styles.alertBanner} />

                        <OTPInput
                            size={isDesktop ? "sm" : "md"}
                            onComplete={() => {}}
                            isFocused={isActive}
                            isTabbable={isActive}
                        />

                        <OTPForm.VerifyCodeButton isTabbable={isActive}>Sign in</OTPForm.VerifyCodeButton>
                    </div>
                </div>
                <NavigationLink
                    onClick={onRequestPasswordInsteadClick}
                    isUnderLine={true}
                    className={clsx(authGlobalStyles.link, authGlobalStyles.centeredLink)}
                    isTabbable={isActive}
                    title="Email and password form"
                    data-prevent-input-validation={true}
                >
                    {() => "Use password instead"}
                </NavigationLink>
            </div>
        </OTPForm.Root>
    )
}
