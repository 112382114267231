// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalContent-base__ZJRVW{position:fixed;top:0;right:0;bottom:0;left:0;z-index:var(--z-index-modal);display:flex;align-items:center;justify-content:center;width:var(--size-full);height:var(--size-full);pointer-events:none !important}.ModalContent-base__ZJRVW[data-state=open] .ModalContent-dialog__HVxuS{animation-name:ModalContent-enter__ir59N}.ModalContent-base__ZJRVW[data-state=closed] .ModalContent-dialog__HVxuS{animation-name:ModalContent-exit__iTCyZ}.ModalContent-dialog__HVxuS{width:-moz-fit-content;width:fit-content;background-color:var(--surface-tertiary);border-radius:var(--border-radius-2);box-shadow:var(--box-shadow-3xl-down);animation-duration:var(--duration-normal);animation-fill-mode:forwards;pointer-events:all}@keyframes ModalContent-enter__ir59N{from{transform:translateY(var(--size-5)) scale(0.9);opacity:0}to{transform:translateY(0px) scale(1);opacity:1}}@keyframes ModalContent-exit__iTCyZ{from{transform:translateY(0px) scale(1);opacity:1}to{transform:translateY(var(--size-5)) scale(0.9);opacity:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "ModalContent-base__ZJRVW",
	"dialog": "ModalContent-dialog__HVxuS",
	"enter": "ModalContent-enter__ir59N",
	"exit": "ModalContent-exit__iTCyZ"
};
export default ___CSS_LOADER_EXPORT___;
