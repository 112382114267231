import { MutationFunction } from "@tanstack/react-query"
import Cookies from "js-cookie"

import { ImageDimensions } from "@molecules/Form/inputs/HiddenImageInput/HiddenImageInput.types"

import { USER_PREFERENCES } from "@endpoints/userPreferences"

import {
    IMAGE_FORMATS_ACCEPTED,
    IMAGE_MAX_HEIGHT,
    IMAGE_MAX_SIZE_IN_KiB,
    IMAGE_MAX_SIZE_IN_kB,
    IMAGE_MAX_WIDTH,
} from "@constants/avatarImageLimits"

import { ImageValidationResult, UserAvatar } from "./AvatarManagement.types"

const updateUserAvatar: MutationFunction<UserAvatar, UserAvatar> = async (
    userAvatar: UserAvatar,
): Promise<UserAvatar> => {
    const formData = new FormData()
    formData.append("avatar", userAvatar.avatar)

    const response = await fetch(USER_PREFERENCES.UPLOAD_USER_AVATAR, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
        },
        body: formData,
    })

    if (!response.ok) {
        throw new Error("Could not upload image")
    }

    return (await response.json()) as UserAvatar
}

const deleteUserAvatar: MutationFunction<void> = async (): Promise<void> => {
    const response = await fetch(USER_PREFERENCES.DELETE_USER_AVATAR, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
        },
    })

    if (!response.ok) {
        throw new Error("Could not remove image")
    }
}

const validateImageProperties = (image: File, imageDimensions: ImageDimensions): ImageValidationResult => {
    const fileLength = image.size / 1024

    const ERROR_UNSUPPORTED_FORMAT = "Unsupported format"
    const ERROR_FILE_TOO_LARGE = `File size must be under ${IMAGE_MAX_SIZE_IN_kB}kB`
    const ERROR_DIMENSIONS_TOO_LARGE = `Image dimensions must be less than ${IMAGE_MAX_WIDTH}x${IMAGE_MAX_HEIGHT}`

    if (!IMAGE_FORMATS_ACCEPTED.includes(image.type.toLowerCase())) {
        return {
            valid: false,
            message: ERROR_UNSUPPORTED_FORMAT,
        }
    }

    if (fileLength > IMAGE_MAX_SIZE_IN_KiB) {
        return {
            valid: false,
            message: ERROR_FILE_TOO_LARGE,
        }
    }

    if (imageDimensions.height > IMAGE_MAX_HEIGHT || imageDimensions.width > IMAGE_MAX_WIDTH) {
        return {
            valid: false,
            message: ERROR_DIMENSIONS_TOO_LARGE,
        }
    }

    return { valid: true, message: "" }
}

export { deleteUserAvatar, updateUserAvatar, validateImageProperties }
