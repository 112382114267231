import { useQuery } from "@tanstack/react-query"

import fetchObjectListData from "@utils/fetchObjectListData"

import { Tab } from "@atoms"

import { TableViewTabProps } from "./TableViewTab.types"

export default function TableViewTab(props: TableViewTabProps) {
    const { tab, isPressed, searchKeywords, onClick } = props

    const { countEndpoint, endpointKwargs, title } = tab

    const { data } = useQuery({
        queryKey: [countEndpoint, endpointKwargs, searchKeywords, title],
        queryFn: () =>
            fetchObjectListData({
                objectName: `${title} Count`,
                endpoint: countEndpoint,
                endpointKwargs: endpointKwargs,
                searchKeywords: searchKeywords,
            }),
        staleTime: 60000,
    })

    return (
        <Tab count={data?.count} isPressed={isPressed} onClick={() => onClick?.(tab.title)} noDebounce={true}>
            {title}
        </Tab>
    )
}
