/* nextjs-TODO:
    - replace with nextjs useRouter
    - replace with nextjs Link
*/
import clsx from "clsx"
import React, { useEffect, useMemo } from "react"

import useRouter from "@hooks/useRouter"
import useSlugExtractor from "@hooks/useSlugExtractor"

import { ifSpaceOrEnter } from "@utils/keyboard"

import { HOME_ROUTE } from "@routes/home"

import styles from "./NavigationLink.module.scss"
import NavigationLinkProps from "./NavigationLink.types"

const checkIfShouldActiveLink = (path: string, pathname: string, rootPath: string): boolean => {
    const hasQuery = (path ?? "").includes("?")
    let queriesMatch = true

    if (hasQuery) {
        // If the path has a querystring, check to make sure the current path also has the querystring components
        const pathWithoutQuery = path.split("?")[0]
        const queryItemsInPath = path.split("?")[1].split("&")
        const currentQueryItems = window.location.search.split(/[&?]/).filter((kwarg) => kwarg !== "")

        path = pathWithoutQuery
        queriesMatch =
            queryItemsInPath.length > 0 ? queryItemsInPath.every((key) => currentQueryItems.includes(key)) : true
    }

    return queriesMatch && (path === rootPath ? pathname === rootPath : pathname.startsWith(path))
}

export default function NavigationLink(props: NavigationLinkProps) {
    const {
        title,
        path,
        activeClassName,
        children,
        className,
        openIn,
        isTabbable = true,
        isDisabled,
        isActive,
        isUnderLine = false,
        onClick,
        onActive,
        ...rest
    } = props

    const router = useRouter()

    const [rootPath] = useSlugExtractor([HOME_ROUTE])

    const shouldActiveLink = useMemo(() => {
        return isActive ?? (path !== undefined && checkIfShouldActiveLink(path, router.pathname, rootPath))
    }, [router.pathname, path, isActive])

    useEffect(() => {
        if (shouldActiveLink) {
            onActive?.(title ?? "")
        }
    }, [shouldActiveLink])

    const handleSpaceOrEnterKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
        if (path) {
            router.push(path)
        }

        onClick?.(event as unknown as React.MouseEvent<HTMLAnchorElement>)
    }

    return (
        <a
            href={path}
            className={clsx(styles.base, className, shouldActiveLink && activeClassName, {
                [styles.underline]: isUnderLine,
            })}
            title={`Go to ${title}`}
            aria-current={shouldActiveLink ? "page" : undefined}
            tabIndex={isTabbable && !isDisabled ? 0 : -1}
            rel="noreferrer noopener"
            aria-disabled={isDisabled}
            onClick={onClick}
            onKeyDown={(event) => ifSpaceOrEnter(event, handleSpaceOrEnterKeyDown)}
            target={openIn === "newTab" ? "_blank" : undefined}
            {...rest}
        >
            {/* Dynamically renders the content of the link passing down a boolean that indicates if the link should be active. */}
            {children(shouldActiveLink)}
        </a>
    )
}
