import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import generateRandomKey from "@utils/generateRandomKey"

import { NavigationItem as NavigationItemType } from "@molecules/NavigationItem/NavigationItem.types"
import { NavigationItem, NavigationSection } from "@molecules/index"

import { SETTINGS_ROUTES } from "@routes/settings"

function SettingsNavigation() {
    const myAccountLinks: NavigationItemType[] = [
        {
            label: "Profile",
            icon: icon({ name: "user", style: "light", family: "sharp" }),
            iconActive: icon({ name: "user", style: "regular", family: "sharp" }),
            showUserAvatar: true,
            path: SETTINGS_ROUTES.profile,
        },
        {
            label: "Preferences",
            icon: icon({ name: "gear", style: "light", family: "sharp" }),
            iconActive: icon({ name: "gear", style: "regular", family: "sharp" }),
            showUserAvatar: false,
            path: SETTINGS_ROUTES.preferences,
        },
        {
            label: "Security & access",
            icon: icon({ name: "key", style: "light", family: "sharp" }),
            iconActive: icon({ name: "key", style: "regular", family: "sharp" }),
            showUserAvatar: false,
            path: SETTINGS_ROUTES.securityAndAccess,
        },
        {
            label: "Notifications",
            icon: icon({ name: "bell", style: "light", family: "sharp" }),
            iconActive: icon({ name: "bell", style: "regular", family: "sharp" }),
            showUserAvatar: false,
            path: SETTINGS_ROUTES.notifications,
        },
    ]

    const workspaceLinks: NavigationItemType[] = [
        {
            label: "Payments",
            icon: icon({ name: "dollar-circle", style: "light", family: "sharp" }),
            iconActive: icon({ name: "dollar-circle", style: "regular", family: "sharp" }),
            showUserAvatar: false,
            path: SETTINGS_ROUTES.payments,
        },
        {
            label: "CompanyCam",
            icon: icon({ name: "camera-retro", style: "light", family: "sharp" }),
            iconActive: icon({ name: "camera-retro", style: "regular", family: "sharp" }),
            showUserAvatar: false,
            path: SETTINGS_ROUTES.companyCam,
        },
        {
            label: "Accounting",
            icon: icon({ name: "receipt", style: "light", family: "sharp" }),
            iconActive: icon({ name: "receipt", style: "regular", family: "sharp" }),
            showUserAvatar: false,
            path: SETTINGS_ROUTES.accounting,
        },
        {
            label: "Availability schedules",
            icon: icon({ name: "calendar", style: "light", family: "sharp" }),
            iconActive: icon({ name: "calendar", style: "regular", family: "sharp" }),
            showUserAvatar: false,
            path: SETTINGS_ROUTES.availabilitySchedules,
        },
    ]

    return (
        <>
            <NavigationSection title="My account">
                {myAccountLinks.map((item) => (
                    <NavigationItem
                        key={generateRandomKey()}
                        label={item.label}
                        icon={item.icon}
                        iconActive={item.iconActive}
                        path={item.path}
                        variant="primary"
                        isActive={item.isActive}
                        onActive={() => null}
                        showUserAvatar={item.showUserAvatar}
                    />
                ))}
            </NavigationSection>
            <NavigationSection title="Workspace">
                {workspaceLinks.map((item) => (
                    <NavigationItem
                        key={generateRandomKey()}
                        label={item.label}
                        path={item.path}
                        icon={item.icon}
                        iconActive={item.iconActive}
                        variant="primary"
                        isActive={item.isActive}
                        onActive={() => null}
                        showUserAvatar={item.showUserAvatar}
                    />
                ))}
            </NavigationSection>
        </>
    )
}

export default SettingsNavigation
