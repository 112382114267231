import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import PurchaseOrderCreateContainer from "@legacy/purchase_orders/PurchaseOrderCreateContainer"

import useIsDesktop from "@hooks/useIsDesktop"
import useSlugExtractor from "@hooks/useSlugExtractor"
import useToastQueue from "@hooks/useToastQueue"

import { TopBar } from "@organisms"

import { AppFrame } from "@templates"

import { PURCHASE_ORDER_ROUTES } from "@routes/purchase-orders"

export default function PurchaseOrderEdit() {
    const isDesktop = useIsDesktop()

    const purchaseOrderId = window.PURCHASE_ORDER_ID
    const isDraft = window.PURCHASE_ORDER_IS_DRAFT

    const [listRoute, detailsRoute] = useSlugExtractor(
        [PURCHASE_ORDER_ROUTES.LIST, PURCHASE_ORDER_ROUTES.DETAILS],
        purchaseOrderId,
    )
    const { addToastToQueue } = useToastQueue()

    return (
        <AppFrame.Root pageId="PurchaseOrderEdit">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle
                            label="Purchase Orders"
                            icon={icon({ name: "basket-shopping", style: "regular", family: "sharp" })}
                            iconActive={icon({ name: "basket-shopping", style: "solid", family: "sharp" })}
                            destination={listRoute}
                            iconOnly={!isDesktop}
                        />
                        <TopBar.VerticalDivider />
                        <TopBar.PageSubtitle
                            label={isDraft ? "New Purchase Order" : "Edit Purchase Order"}
                            backDestination={detailsRoute}
                        />
                    </TopBar.TopBarHeaderStart>
                </TopBar.Header>
            </TopBar.Root>
            <AppFrame.PageContent>
                <PurchaseOrderCreateContainer formMode="EDIT_PURCHASE_ORDER" addToastToQueue={addToastToQueue} />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
