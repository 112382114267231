import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"
import { useMemo } from "react"

import { Icon, Tooltip } from "@atoms"

import { DEFAULT_ROOT_FONT_SIZE } from "@constants/fonts"

import styles from "./Avatar.module.scss"
import { AvatarProps, AvatarPropsWithMask } from "./Avatar.types"
import AvatarStatus from "./AvatarStatus/AvatarStatus"

export default function Avatar(props: AvatarProps) {
    const {
        fallbackName = "",
        src,
        size = 28,
        shape = "square",
        count = null,
        countShowPlus = true,
        type = "user",
        isUnknown = false,
        showTooltip = false,
    } = props

    let status: AvatarPropsWithMask["status"] = undefined
    let isOverlapping: AvatarPropsWithMask["isOverlapping"] = undefined

    const isMaskProps = "status" in props || "isOverlapping" in props

    if (isMaskProps) {
        status = props.status
        isOverlapping = props.isOverlapping
    }

    const isCountVariation = Number.isSafeInteger(count)

    const initials = useMemo(() => {
        if (isCountVariation) {
            return
        }

        const fullNameSplit = fallbackName.split(" ")

        const firstInitial = fullNameSplit.shift()?.charAt(0) || ""
        const lastInitial = fullNameSplit.pop()?.charAt(0) || ""

        return type === "company" ? firstInitial : `${firstInitial}${lastInitial}`
    }, [fallbackName, type, isCountVariation])

    const avatarBaseFontSize = useMemo(() => `${size / DEFAULT_ROOT_FONT_SIZE}rem`, [size]) // Convert the pixel-based size to rem

    return (
        <Tooltip label={showTooltip ? fallbackName : ""} side="bottom">
            <div
                className={clsx(styles.base, styles.numerical, styles[shape], styles[type], styles[`size${size}`], {
                    [styles.overlapping]: isOverlapping,
                    [styles.showingStatus]: !!status,
                    [styles.unknown]: isUnknown,
                    [styles.usingMask]: isOverlapping || !!status,
                })}
                style={
                    {
                        fontSize: avatarBaseFontSize,
                    } as React.CSSProperties
                }
            >
                <div className={styles.container}>
                    {isUnknown ? (
                        <Icon
                            icon={icon({
                                name: "user",
                                style: "light",
                                family: "sharp",
                            })}
                        />
                    ) : (
                        <div
                            className={clsx(
                                isCountVariation ? styles.count : styles.initials,
                                styles.avatarBackground,
                            )}
                        >
                            {isCountVariation && countShowPlus ? "+" : null}
                            {isCountVariation ? Math.min(count ?? 0, 99) : initials}

                            <div
                                className={clsx(styles.backgroundImage)}
                                style={{
                                    backgroundImage: `url(${src})`,
                                }}
                                aria-label={`${fallbackName}'s Avatar`}
                                role="img"
                            />
                        </div>
                    )}
                </div>

                {status && (
                    <div className={styles.status}>
                        <AvatarStatus status={status} />
                    </div>
                )}
            </div>
        </Tooltip>
    )
}
