// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuthContent-base__VtVym{position:relative;display:flex;flex-direction:column;align-items:flex-start;height:var(--size-full);padding:var(--size-5);overflow-x:hidden;overflow-y:auto;overflow:hidden auto}@media only screen and (min-width: 64rem){.AuthContent-base__VtVym{flex:none;width:calc((var(--size-52) - var(--size-1))*2);height:-moz-fit-content;height:fit-content;margin-top:auto;margin-bottom:auto;overflow:hidden;background:var(--surface-tertiary);border-radius:var(--border-radius-2);box-shadow:var(--box-shadow-3xl-down)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "AuthContent-base__VtVym"
};
export default ___CSS_LOADER_EXPORT___;
