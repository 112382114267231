import { useMemo } from "react"

import useCurrentObjectsView from "@hooks/useCurrentObjectsView"

import generateRandomKey from "@utils/generateRandomKey"
import getSortingEnabledColumnsByIds from "@utils/getSortingEnabledColumnsByIds"
import getTableSortingIconsPairByColumnId from "@utils/getTableSortingIconsPairByColumnId"
import getTableSortingLabelsPairByColumnId from "@utils/getTableSortingLabelsPairByColumnId"

import { Tab } from "@atoms"

import { TabStack } from "@molecules"

import BottomSheet from "@organisms/BottomSheet/BottomSheet"
import { TableViewMenuColumn } from "@organisms/Table/TableViewMenu/TableViewMenu.types"

import styles from "./BottomSheetSortingColumns.module.scss"
import { BottomSheetSortingColumnsProps } from "./BottomSheetSortingColumns.types"

export default function BottomSheetSortingColumns(props: BottomSheetSortingColumnsProps) {
    const { isVisible } = props

    const { currentObjectsView, currentObjectType, updateCurrentObjectsViewSorting } = useCurrentObjectsView()

    const columns = useMemo(() => {
        return getSortingEnabledColumnsByIds(currentObjectsView?.columnOrder ?? [], currentObjectType)
    }, [currentObjectsView?.columnOrder, currentObjectType])

    const selectedColumn = useMemo(() => {
        return currentObjectsView?.sorting?.[0]
    }, [currentObjectsView])

    const selectedColumnSortingIcons = useMemo(() => {
        return selectedColumn && getTableSortingIconsPairByColumnId(selectedColumn?.id)
    }, [selectedColumn])

    const selectedColumnSortingLabels = useMemo(() => {
        return selectedColumn && getTableSortingLabelsPairByColumnId(selectedColumn?.id)
    }, [selectedColumn])

    const setCurrentSortToAscending = () => {
        selectedColumn && updateCurrentObjectsViewSorting([{ id: selectedColumn?.id, desc: false }])
    }

    const setCurrentSortToDescending = () => {
        selectedColumn && updateCurrentObjectsViewSorting([{ id: selectedColumn?.id, desc: true }])
    }

    const onSortingItemSelection = (column: TableViewMenuColumn) => {
        const notSelected = selectedColumn?.id !== column.value

        if (notSelected) {
            updateCurrentObjectsViewSorting([
                {
                    id: column.value,
                    desc: false,
                },
            ])
        }
    }

    return (
        <>
            <div className={styles.sortingColumns}>
                {columns.map((column) => (
                    <BottomSheet.Item
                        key={generateRandomKey()}
                        label={column.label}
                        onClick={() => onSortingItemSelection(column)}
                        isSelected={selectedColumn?.id === column.value}
                        isTabbable={isVisible}
                    />
                ))}
            </div>
            <BottomSheet.Divider />
            <div className={styles.sortingOrderSelector}>
                <TabStack attached={true} size="xl">
                    <Tab
                        icon={selectedColumnSortingIcons?.asc}
                        isPressed={!selectedColumn?.desc}
                        isTabbable={isVisible}
                        onClick={setCurrentSortToAscending}
                    >
                        {selectedColumnSortingLabels?.asc}
                    </Tab>
                    <Tab
                        icon={selectedColumnSortingIcons?.desc}
                        isPressed={selectedColumn?.desc}
                        isTabbable={isVisible}
                        onClick={setCurrentSortToDescending}
                    >
                        {selectedColumnSortingLabels?.desc}
                    </Tab>
                </TabStack>
            </div>
        </>
    )
}
