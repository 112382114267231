import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { ReactNode } from "react"

import useUser from "@hooks/useUser"

import { Avatar, Icon } from "@atoms/index"

import AvatarCropDialog from "@pages/Settings/SettingsProfile/components/AvatarCropDialog/AvatarCropDialog"
import { useAvatarManagement } from "@pages/Settings/SettingsProfile/components/AvatarManagement/AvatarManagement.store"

import styles from "./AvatarManagementDialogTrigger.module.scss"

export default function AvatarManagementDialogTrigger() {
    const { user } = useUser()

    const { displayedAvatar, userHasUploadedAvatar, setImageToCrop, selectImageFromOS } = useAvatarManagement()

    const renderTrigger = (children: ReactNode) => {
        if (displayedAvatar && userHasUploadedAvatar) {
            return <AvatarCropDialog>{children}</AvatarCropDialog>
        } else {
            return children
        }
    }

    const onButtonClick = () => {
        if (displayedAvatar && userHasUploadedAvatar) {
            setImageToCrop(displayedAvatar)
        } else {
            selectImageFromOS()
        }
    }

    return renderTrigger(
        <button className={styles.base} onClickCapture={onButtonClick}>
            <div className={styles.hoverIndicator}>
                {userHasUploadedAvatar ? (
                    <Icon
                        icon={icon({
                            name: "crop",
                            family: "sharp",
                            style: "solid",
                        })}
                        size={24}
                    />
                ) : (
                    <Icon icon={icon({ name: "upload", family: "sharp", style: "solid" })} size={24} />
                )}
            </div>
            <Avatar src={displayedAvatar} fallbackName={user?.full_name ?? ""} size={80} shape="circle" />
        </button>,
    )
}
