import { OTPInput as OTP } from "input-otp"
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react"

import useOTPForm from "@organisms/OTPForm/OTPForm.store"

import styles from "./OTPInput.module.scss"
import { OTPInputProps } from "./OTPInput.types"
import OTPInputSlot from "./OTPInputSlot/OTPInputSlot"

function OTPInput(props: OTPInputProps, ref: React.Ref<HTMLInputElement>) {
    const { size, isInvalid = false, isDisabled = false, onComplete, isTabbable, isFocused, ...rest } = props

    const { handleCodeVerification, onOTPInputChange, otpInputValue, isCodeInvalid } = useOTPForm()

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleCodeVerification()
        }
    }

    const inputRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, [])

    useEffect(() => {
        if (isFocused && isTabbable) {
            inputRef.current?.focus()
        }
    }, [isFocused, isTabbable])

    return (
        <OTP
            ref={inputRef}
            name="otp"
            maxLength={6}
            containerClassName={styles.base}
            className={styles.input}
            disabled={isDisabled}
            value={otpInputValue}
            onChange={onOTPInputChange}
            onComplete={onComplete || handleCodeVerification}
            data-1p-ignore={true}
            data-lpignore={true}
            onKeyDown={onKeyDown}
            tabIndex={isTabbable ? 0 : -1}
            render={({ slots }) => {
                return (
                    <>
                        {slots.slice(0, 6).map((slot, idx) => (
                            <OTPInputSlot
                                isInvalid={isInvalid || isCodeInvalid}
                                size={size}
                                key={idx}
                                isDisabled={isDisabled}
                                {...slot}
                            />
                        ))}
                    </>
                )
            }}
            {...rest}
        />
    )
}

export default forwardRef(OTPInput)
