import { RotateProp } from "@fortawesome/fontawesome-svg-core"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { useMemo } from "react"

import useIsDesktop from "@hooks/useIsDesktop"

import generateRandomKey from "@utils/generateRandomKey"

import { Icon, Tab } from "@atoms"

import { KeywordSearch } from "@molecules"
import TabStack from "@molecules/TabStack/TabStack"

import { Table } from "@organisms"
import TopBar from "@organisms/TopBar/TopBar"

import styles from "./TableViewControls.module.scss"
import { TableViewControlsProps } from "./TableViewControls.types"
import TableViewTab from "./TableViewTab/TableViewTab"
import TableViewTabs from "./TableViewTabs/TableViewTabs"

export default function TableViewControls(props: TableViewControlsProps) {
    const {
        phase,
        tabs,
        activeTab,
        setActiveTab,
        searchKeywords,
        setSearchKeywords,
        objectType,
        onShowOpen,
        onShowClosed,
        showTableViewMenu = true,
    } = props

    const isDesktop = useIsDesktop()

    const phasesTabs = useMemo(() => {
        if (objectType === "client" || objectType === "vendor" || objectType === "pricebook_item") {
            return {
                activeTab: {
                    phase: "active",
                    title: "Active",
                    icons: {
                        icon: icon({ name: "circle-minus", style: "light", family: "sharp" }),
                        iconOnActive: icon({ name: "circle-minus", style: "regular", family: "sharp" }),
                        rotation: 90 as RotateProp,
                    },
                },
                inactiveTab: {
                    phase: "inactive",
                    title: "Inactive",
                    icons: {
                        icon: icon({ name: "circle", style: "light", family: "sharp" }),
                        iconOnActive: icon({ name: "circle", style: "regular", family: "sharp" }),
                        rotation: undefined,
                    },
                },
            }
        } else {
            return {
                activeTab: {
                    phase: "open",
                    title: "Open",
                    icons: {
                        icon: icon({ name: "circle-dashed", style: "light", family: "sharp" }),
                        iconOnActive: icon({ name: "circle-dashed", style: "regular", family: "sharp" }),
                        rotation: undefined,
                    },
                },
                inactiveTab: {
                    phase: "closed",
                    title: "Closed",
                    icons: {
                        icon: icon({ name: "archive", style: "light", family: "sharp" }),
                        iconOnActive: icon({ name: "archive", style: "regular", family: "sharp" }),
                        rotation: undefined,
                    },
                },
            }
        }
    }, [objectType])

    return (
        <div className={styles.base}>
            <div className={styles.container}>
                <div className={styles.navControls}>
                    <TabStack attached={true} size={isDesktop ? "sm" : "md"}>
                        <Tab
                            iconInReactNode={
                                <Icon
                                    icon={phasesTabs.activeTab.icons.icon}
                                    iconOnActive={phasesTabs.activeTab.icons.iconOnActive}
                                    rotation={phasesTabs.activeTab.icons.rotation}
                                    isActive={true}
                                />
                            }
                            isPressed={phase === phasesTabs.activeTab.phase}
                            onClick={onShowOpen}
                            noDebounce={true}
                            title={phasesTabs.activeTab.title}
                        />
                        <Tab
                            iconInReactNode={
                                <Icon
                                    icon={phasesTabs.inactiveTab.icons.icon}
                                    iconOnActive={phasesTabs.inactiveTab.icons.iconOnActive}
                                    rotation={phasesTabs.activeTab.icons.rotation}
                                    isActive={true}
                                />
                            }
                            isPressed={phase === phasesTabs.inactiveTab.phase}
                            onClick={onShowClosed}
                            noDebounce={true}
                            title={phasesTabs.inactiveTab.title}
                        ></Tab>
                    </TabStack>
                    <div className={styles.divider}>
                        <TopBar.VerticalDivider />
                    </div>
                    <TableViewTabs>
                        {tabs?.map((tab) => (
                            <TableViewTab
                                key={generateRandomKey()}
                                isPressed={activeTab === tab.title}
                                tab={tab}
                                onClick={setActiveTab}
                                searchKeywords={searchKeywords}
                            >
                                {tab.title}
                            </TableViewTab>
                        ))}
                    </TableViewTabs>
                </div>
                <div className={styles.viewControls}>
                    <KeywordSearch defaultValue={searchKeywords} onValueChange={setSearchKeywords} />
                    {showTableViewMenu && <Table.ViewMenu />}
                </div>
            </div>
        </div>
    )
}
