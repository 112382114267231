// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TopBarPageSubtitle-base__nuU_v{display:flex;align-items:center;min-width:0;color:var(--gray-12)}.TopBarPageSubtitle-subtitle__vFfok{max-width:100%;padding-left:var(--size-1_5);overflow:hidden;white-space:nowrap;text-overflow:ellipsis;-webkit-user-select:none;-moz-user-select:none;user-select:none;font:var(--font-global-heading-01);font-feature-settings:var(--feature-settings-global-heading-01);letter-spacing:var(--letter-spacing-global-heading-01)}.TopBarPageSubtitle-noBackButton__EQblL .TopBarPageSubtitle-subtitle__vFfok{padding-left:var(--size-2)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "TopBarPageSubtitle-base__nuU_v",
	"subtitle": "TopBarPageSubtitle-subtitle__vFfok",
	"noBackButton": "TopBarPageSubtitle-noBackButton__EQblL"
};
export default ___CSS_LOADER_EXPORT___;
