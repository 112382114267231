// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JobTimelineViewControls-base__wljtB{display:flex;width:var(--size-full);height:var(--size-13);border-bottom:var(--size-0_25) solid var(--gray-a3)}@media only screen and (min-width: 64rem){.JobTimelineViewControls-base__wljtB{height:var(--size-14)}}.JobTimelineViewControls-controls__YHOuu{display:flex;align-items:flex-start;justify-content:space-between;width:var(--size-full);height:var(--size-13);padding:var(--size-2) var(--size-3);border-bottom:var(--size-0_25) solid var(--gray-a3)}@media only screen and (min-width: 64rem){.JobTimelineViewControls-controls__YHOuu{height:var(--size-14);padding:var(--size-3) var(--size-4)}}.JobTimelineViewControls-navControls__a4uOj,.JobTimelineViewControls-viewControls__K5bjc{display:flex;gap:var(--size-2);align-items:flex-start;padding:var(--size-0)}.JobTimelineViewControls-previousAndNextDays__I7Yce{display:flex;flex-wrap:nowrap;gap:var(--size-0_5)}.JobTimelineViewControls-datePicker__s39S4{position:relative;gap:var(--size-2_5);justify-content:center;min-width:var(--size-24);padding:var(--size-0) var(--size-4);font:var(--font-global-buttons-and-links-medium-375);font-feature-settings:var(--feature-settings-global-buttons-and-links-medium-375);letter-spacing:var(--letter-spacing-global-buttons-and-links-medium-375)}@media only screen and (min-width: 64rem){.JobTimelineViewControls-datePicker__s39S4{padding:var(--size-0) var(--size-3_5)}}.JobTimelineViewControls-bryntumDateField__mNHYJ{position:absolute;top:0;right:0;bottom:0;left:0;width:var(--size-full);margin-right:var(--size-1);padding:0;overflow:hidden;opacity:0}.JobTimelineViewControls-badgeContainer__Ibiow{display:flex;gap:var(--size-1_5)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "JobTimelineViewControls-base__wljtB",
	"controls": "JobTimelineViewControls-controls__YHOuu",
	"navControls": "JobTimelineViewControls-navControls__a4uOj",
	"viewControls": "JobTimelineViewControls-viewControls__K5bjc",
	"previousAndNextDays": "JobTimelineViewControls-previousAndNextDays__I7Yce",
	"datePicker": "JobTimelineViewControls-datePicker__s39S4",
	"bryntumDateField": "JobTimelineViewControls-bryntumDateField__mNHYJ",
	"badgeContainer": "JobTimelineViewControls-badgeContainer__Ibiow"
};
export default ___CSS_LOADER_EXPORT___;
