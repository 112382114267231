import { BryntumGridProps } from "@bryntum/schedulerpro-react"

import useJobTimelineViewEventHandlers from "./useJobTimelineViewEventHandlers"
import useJobTimelineViewRenderers from "./useJobTimelineViewRenderers"
import useTimelineViewUnscheduledJobsUtils from "./useTimelineViewUnscheduledJobsUtils"

export default function useJobTimelineViewGridProps(): {
    desktopProps: BryntumGridProps
    mobileProps: BryntumGridProps
} {
    const { onGridCellClick } = useJobTimelineViewEventHandlers()

    const {
        onUnscheduledJobsGridPaint,
        onUnscheduledJobsGridScroll,
        onMobileUnscheduledJobsGridPaint,
        onMobileUnscheduledJobsGridScroll,
    } = useTimelineViewUnscheduledJobsUtils()

    const { renderUnscheduledJobsCellsTooltip, renderUnscheduledJobsCells, renderUnscheduledJobsGridEmptyMessage } =
        useJobTimelineViewRenderers()

    const sharedProps: BryntumGridProps = {
        cls: "unscheduled-jobs-grid__bryntum-grid",
        // @ts-expect-error bad typing
        onCellClick: onGridCellClick,
        emptyText: renderUnscheduledJobsGridEmptyMessage(),
        columns: [
            {
                type: "template",
                headerRenderer: () => undefined,
                filterable: false,
                enableHeaderContextMenu: false,
                sortable: false,
                resizable: false,
                enableCellContextMenu: false,
                readOnly: true,
                template: renderUnscheduledJobsCells,
            },
        ],
    }

    const desktopProps = {
        listeners: {
            paint: onUnscheduledJobsGridPaint,
            scroll: onUnscheduledJobsGridScroll,
        },
        cellTooltipFeature: {
            cls: "job-timeline-view__job-preview-tooltip",
            tooltipRenderer: renderUnscheduledJobsCellsTooltip,
            hideDelay: true,
            allowOver: false,
            showOnHover: true,
            hoverDelay: 0,
            textContent: true,
            scrollAction: "hide",
            align: {
                align: "r-l",
                overlap: false,
                anchor: true,
                offset: 4,
            },
        },
        ...sharedProps,
    }

    const mobileProps = {
        listeners: {
            paint: onMobileUnscheduledJobsGridPaint,
            scroll: onMobileUnscheduledJobsGridScroll,
        },
        ...sharedProps,
    }

    return {
        desktopProps,
        mobileProps,
    }
}
