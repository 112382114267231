import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import DashboardContainer from "@legacy/dashboard/DashboardContainer"

import { TopBar } from "@organisms"

import { AppFrame } from "@templates"

export default function Dashboard() {
    return (
        <AppFrame.Root pageId="Dashboard">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle
                            label="Home"
                            icon={icon({ name: "house", style: "regular", family: "sharp" })}
                            iconActive={icon({ name: "house", style: "solid", family: "sharp" })}
                        />
                    </TopBar.TopBarHeaderStart>
                </TopBar.Header>
            </TopBar.Root>
            <AppFrame.PageContent>
                <DashboardContainer />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
