import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import PriceBookListCard from "@legacy/pricebook/components/PriceBookItemListCard"
import { useEffect, useMemo, useState } from "react"

import useIsDesktop from "@hooks/useIsDesktop"
import useLocalStorage from "@hooks/useLocalStorage"
import useNavigationLinks, { NavigationLink } from "@hooks/useNavigationLinks"
import useRouter from "@hooks/useRouter"
import useSlugExtractor from "@hooks/useSlugExtractor"

import extractURLSearchParam from "@utils/extractURLSearchParam"
import replaceSlugs from "@utils/replaceSlugs"

import { ObjectsView, TopBar } from "@organisms"
import { ObjectsViewType } from "@organisms/ObjectsView/ObjectsView.types"

import { AppFrame } from "@templates"

import { PRICEBOOK_ITEMS_ROUTES } from "@routes/pricebook-items"

import {
    LIST_SEARCH_PARAM_ACTIVE_TAB,
    LIST_SEARCH_PARAM_KEYWORD_SEARCH,
    LIST_SEARCH_PARAM_PHASE,
    LIST_SEARCH_PARAM_TYPE,
} from "@constants/searchParams"
import {
    PRICEBOOK_LIST_CONTROLS_ACTIVE_TAB,
    PRICEBOOK_LIST_CONTROLS_KEYWORD_SEARCH,
    PRICEBOOK_LIST_CONTROLS_ORDERING,
    PRICEBOOK_LIST_CONTROLS_PHASE,
    PRICEBOOK_LIST_CONTROLS_VIEW,
} from "@constants/storage"

import usePricebookTabs, { PricebookTypeName } from "./usePricebookTabs"

const CREATE_ROUTE_MAP: { [key: string]: string } = {
    Services: PRICEBOOK_ITEMS_ROUTES.SERVICE.CREATE,
    "Parts & Materials": PRICEBOOK_ITEMS_ROUTES.PART_OR_MATERIAL.CREATE,
    Miscellaneous: PRICEBOOK_ITEMS_ROUTES.MISCELLANEOUS.CREATE,
    Discounts: PRICEBOOK_ITEMS_ROUTES.DISCOUNT.CREATE,
    "Tax Rates": PRICEBOOK_ITEMS_ROUTES.TAX_RATES.CREATE,
}

export default function PricebookList() {
    const isDesktop = useIsDesktop()
    const { push } = useRouter()
    const { pricebook: pricebookLink } = useNavigationLinks()

    let pricebookTypeFromURL = new URLSearchParams(window.location.search).get(LIST_SEARCH_PARAM_TYPE)
    if (!pricebookTypeFromURL) {
        pricebookTypeFromURL = "Services"
        const url = new URL(window.location.href)
        url.searchParams.set(LIST_SEARCH_PARAM_TYPE, "Services")
        window.history.replaceState({}, "", url)
    }

    const [activePricebookType, setActivePricebookType] = useState<NavigationLink>()
    const [rawCreateRoute, setRawCreateRoute] = useState<string>(PRICEBOOK_ITEMS_ROUTES.CREATE)

    useEffect(() => {
        let activeType: NavigationLink | undefined

        if (pricebookTypeFromURL && !activePricebookType) {
            activeType = pricebookLink.subLinks?.find((link) => {
                const search = new URLSearchParams(link.path)
                for (const [, value] of search.entries()) {
                    return value === pricebookTypeFromURL
                }
            })
            setActivePricebookType(activeType)
        }

        if (activeType) {
            setRawCreateRoute(CREATE_ROUTE_MAP[activeType.label])
        }
    }, [pricebookTypeFromURL, pricebookLink.subLinks])

    const [createRoute] = useSlugExtractor([rawCreateRoute])
    const [activeTabs, inactiveTabs] = usePricebookTabs(activePricebookType?.label as PricebookTypeName | undefined)

    const phaseOverride = useMemo(() => extractURLSearchParam(LIST_SEARCH_PARAM_PHASE) as ObjectPhase, [])
    const activeTabOverride = useMemo(() => extractURLSearchParam(LIST_SEARCH_PARAM_ACTIVE_TAB), [])
    const searchKeywordsOverride = useMemo(() => extractURLSearchParam(LIST_SEARCH_PARAM_KEYWORD_SEARCH), [])

    const [viewType, setViewType] = useLocalStorage<ObjectsViewType>(
        replaceSlugs(PRICEBOOK_LIST_CONTROLS_VIEW, { type: activePricebookType?.label ?? "undefined" }),
        "table",
    )
    const [phase, setPhase] = useLocalStorage<ObjectPhase>(
        replaceSlugs(PRICEBOOK_LIST_CONTROLS_PHASE, { type: activePricebookType?.label ?? "undefined" }),
        "active",
        phaseOverride,
    )
    const [activeTab, setActiveTab] = useLocalStorage<string | null>(
        replaceSlugs(PRICEBOOK_LIST_CONTROLS_ACTIVE_TAB, { type: activePricebookType?.label ?? "undefined" }),
        null,
        activeTabOverride,
        activePricebookType !== undefined,
    )
    const [searchKeywords, setSearchKeywords] = useLocalStorage<string>(
        replaceSlugs(PRICEBOOK_LIST_CONTROLS_KEYWORD_SEARCH, { type: activePricebookType?.label ?? "undefined" }),
        "",
        searchKeywordsOverride,
    )

    const onShowActive = () => {
        setPhase("active")
        setActiveTab(activeTabs[0].title)
    }

    const onShowInactive = () => {
        setPhase("inactive")
        setActiveTab(inactiveTabs[0].title)
    }

    const tabs = phase === "active" ? activeTabs : inactiveTabs

    useEffect(() => {
        const isAnyTabAvailable = tabs && tabs.length > 0

        if (isAnyTabAvailable && activeTab === null) {
            setActiveTab(tabs[0].title)
        }
    }, [tabs, activeTab])

    return (
        <AppFrame.Root pageId={`PricebookList-${activePricebookType?.label}`}>
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle
                            label={activePricebookType?.label ?? ""}
                            icon={icon({ name: "tag", style: "regular", family: "sharp" })}
                            iconActive={icon({ name: "tag", style: "solid", family: "sharp" })}
                            onNavigationItemSelect={(item) => {
                                push(item.value)
                            }}
                            navigationItems={pricebookLink.subLinks?.map((item) => ({
                                label: item.label,
                                value: item.path,
                                isActive: activePricebookType?.label === item.label,
                            }))}
                        />
                    </TopBar.TopBarHeaderStart>
                    <TopBar.TopBarHeaderEnd>
                        <TopBar.PagePresence />
                        <TopBar.SearchButton scope="Pricebook" />
                        <TopBar.ViewSelector
                            viewTypes={["table", "card"]}
                            selectedViewType={viewType}
                            setViewType={setViewType}
                        />
                        <TopBar.CTAButton destination={createRoute}>{isDesktop ? "New Item" : null}</TopBar.CTAButton>
                    </TopBar.TopBarHeaderEnd>
                </TopBar.Header>
            </TopBar.Root>
            <ObjectsView
                viewType={viewType}
                searchKeywords={searchKeywords}
                setActiveTab={setActiveTab}
                setSearchKeywords={setSearchKeywords}
                onShowOpen={onShowActive}
                onShowClosed={onShowInactive}
                phase={phase}
                orderingKey={replaceSlugs(PRICEBOOK_LIST_CONTROLS_ORDERING, {
                    type: activePricebookType?.label ?? "undefined",
                })}
                tabs={tabs}
                activeTab={activeTab ?? ""}
                CardComponent={PriceBookListCard}
                listTitle={activePricebookType?.label ?? ""}
                objectCreateRoute={createRoute}
                objectType="pricebook_item"
                objectTypeVariation={activePricebookType?.label}
            />
        </AppFrame.Root>
    )
}
