import { CheckedState } from "@radix-ui/react-checkbox"
import { InvalidateQueryFilters, useQueryClient } from "@tanstack/react-query"
import { useEffect, useMemo, useState } from "react"

import useUser from "@hooks/useUser"

import { Checkbox } from "@atoms/index"

import GenericForm from "@molecules/Form/GenericForm/GenericForm"
import { Property } from "@molecules/index"

import { PropertyStack } from "@organisms/index"

import { Settings } from "@templates/index"

import {
    JobsAssignmentNotificationTopics,
    JobsNotificationTopics,
} from "@pages/Settings/SettingsNotifications/SettingsNotification.types"
import {
    notificationPreferenceQueryKey,
    useNotificationPreferencesQuery,
} from "@pages/Settings/SettingsNotifications/SettingsNotifications.store"
import EmailNotificationTooltip from "@pages/Settings/SettingsNotifications/components/EmailNotificationTooltip/EmailNotificationTooltip"
import NotificationHeader from "@pages/Settings/SettingsNotifications/components/NotificationHeader/NotificationHeader"
import { useNotificationMethodsQuery } from "@pages/Settings/SettingsNotifications/components/NotificationMethodsSection/NotificationMethodsSection.store"
import SMSNotificationTooltip from "@pages/Settings/SettingsNotifications/components/SMSNotificationTooltip/SMSNotificationTooltip"

import { NOTIFICATION_ENDPOINTS } from "@endpoints/notification"

export default function JobsAssignmentNotificationsForm() {
    const { user } = useUser()

    const [shouldEnableAllNotifications, setShouldEnableAllNotifications] = useState<boolean>(false)
    const [shouldDisableAllNotifications, setShouldDisableAllNotifications] = useState<boolean>(false)
    const [shouldSetDefaultFormData, setShouldSetDefaultFormData] = useState<boolean>(false)

    const { topicPreference: jobsNotificationPreference, isLoading: isLoadingData } =
        useNotificationPreferencesQuery<JobsNotificationTopics>("jobs")

    const { data: defaultMethodsPreference } = useNotificationMethodsQuery()

    const queryClient = useQueryClient()

    useEffect(() => {
        if (!isLoadingData && !!jobsNotificationPreference) {
            setShouldSetDefaultFormData(true)
        }
    }, [isLoadingData])

    const handleToggleAllNotifications = (shouldEnableAllNotifications: CheckedState) => {
        setShouldDisableAllNotifications(!shouldEnableAllNotifications)
        setShouldEnableAllNotifications(!!shouldEnableAllNotifications)
    }

    const phoneNotificationsDisabled = !user?.phone || !user?.phone_verified || !defaultMethodsPreference?.contact_sms
    const emailNotificationsDisabled =
        !user?.email || !user?.email_verified || !defaultMethodsPreference?.contact_email

    const formValues = useMemo(() => {
        if (shouldEnableAllNotifications) {
            return {
                enable_job_notification_job_assigned: true,
                enable_job_notification_job_unassigned: true,
                enable_job_notification_job_updated: true,
                enable_job_notification_job_rescheduled: true,
                enable_job_notification_job_unscheduled: true,
                enable_job_notification_job_cancelled: true,
            }
        } else if (shouldDisableAllNotifications) {
            return {
                enable_job_notification_job_assigned: false,
                enable_job_notification_job_unassigned: false,
                enable_job_notification_job_updated: false,
                enable_job_notification_job_rescheduled: false,
                enable_job_notification_job_unscheduled: false,
                enable_job_notification_job_cancelled: false,
            }
        } else if (shouldSetDefaultFormData) {
            return jobsNotificationPreference.assignment
        } else {
            return undefined
        }
    }, [shouldSetDefaultFormData, shouldEnableAllNotifications, shouldDisableAllNotifications])

    const mutationTriggerPayload = useMemo(() => {
        if (shouldEnableAllNotifications) {
            return {
                enable_job_notification_job_assigned: true,
                enable_job_notification_job_unassigned: true,
                enable_job_notification_job_updated: true,
                enable_job_notification_job_rescheduled: true,
                enable_job_notification_job_unscheduled: true,
                enable_job_notification_job_cancelled: true,
            }
        } else if (shouldDisableAllNotifications) {
            return {
                enable_job_notification_job_assigned: false,
                enable_job_notification_job_unassigned: false,
                enable_job_notification_job_updated: false,
                enable_job_notification_job_rescheduled: false,
                enable_job_notification_job_unscheduled: false,
                enable_job_notification_job_cancelled: false,
            }
        } else {
            return undefined
        }
    }, [shouldEnableAllNotifications, shouldDisableAllNotifications])

    const onMutationSuccess = () => {
        void queryClient.invalidateQueries([notificationPreferenceQueryKey] as InvalidateQueryFilters)
    }

    const onAfterFormValuesSet = () => {
        setShouldEnableAllNotifications(false)
        setShouldDisableAllNotifications(false)
        setShouldSetDefaultFormData(false)
    }

    return (
        <GenericForm<
            JobsAssignmentNotificationTopics,
            JobsAssignmentNotificationTopics,
            Error,
            JobsAssignmentNotificationTopics
        >
            formConfig={{
                defaultValues: undefined,
            }}
            mutationConfig={{
                genericErrorMessage: "Could not update notification preference",
                genericSuccessMessage: "Notification preference updated",
                method: "PATCH",
                endpoint: NOTIFICATION_ENDPOINTS.UPDATE_NOTIFICATION_PREFERENCES,
            }}
            onMutationSuccess={onMutationSuccess}
            mutationTriggerPayload={mutationTriggerPayload}
            formValues={formValues}
            onAfterFormValuesSet={onAfterFormValuesSet}
            isAutoSave={true}
        >
            {({ form }) => {
                const isAllNotificationsEnabled = Object.values(form.getValues()).every(Boolean)
                const isSomeNotificationsEnabled = Object.values(form.getValues()).some(Boolean)

                return (
                    <Settings.NotificationConfigPage.Wrapper>
                        <NotificationHeader.Root>
                            <NotificationHeader.EmailCheckbox
                                isIndeterminate={isSomeNotificationsEnabled && !isAllNotificationsEnabled}
                                isChecked={isAllNotificationsEnabled}
                                onChecked={handleToggleAllNotifications}
                            />
                            <NotificationHeader.SMSCheckbox
                                isIndeterminate={isSomeNotificationsEnabled && !isAllNotificationsEnabled}
                                isChecked={isAllNotificationsEnabled}
                                onChecked={handleToggleAllNotifications}
                            />
                        </NotificationHeader.Root>
                        <PropertyStack>
                            <Property.Root>
                                <Property.Content>
                                    <Property.Label>Job assigned</Property.Label>
                                    <Property.Subtitle>Notify me when I am assigned a job.</Property.Subtitle>
                                </Property.Content>
                                <Property.Value>
                                    <Settings.NotificationConfigPage.Checkboxes>
                                        <EmailNotificationTooltip>
                                            <Checkbox<JobsAssignmentNotificationTopics>
                                                size="md"
                                                colorScheme="gray"
                                                displayAsUnchecked={emailNotificationsDisabled}
                                                isDisabled={isLoadingData || emailNotificationsDisabled}
                                                name="enable_job_notification_job_assigned"
                                                isControlled={true}
                                            />
                                        </EmailNotificationTooltip>
                                        <SMSNotificationTooltip>
                                            <Checkbox<JobsAssignmentNotificationTopics>
                                                size="md"
                                                colorScheme="gray"
                                                displayAsUnchecked={phoneNotificationsDisabled}
                                                isDisabled={isLoadingData || phoneNotificationsDisabled}
                                                name="enable_job_notification_job_assigned"
                                                isControlled={true}
                                            />
                                        </SMSNotificationTooltip>
                                    </Settings.NotificationConfigPage.Checkboxes>
                                </Property.Value>
                            </Property.Root>
                            <Property.Root>
                                <Property.Content>
                                    <Property.Label>Job unassigned</Property.Label>
                                    <Property.Subtitle>Notify me when I am unassigned from a job.</Property.Subtitle>
                                </Property.Content>
                                <Property.Value>
                                    <Settings.NotificationConfigPage.Checkboxes>
                                        <EmailNotificationTooltip>
                                            <Checkbox<JobsAssignmentNotificationTopics>
                                                size="md"
                                                colorScheme="gray"
                                                displayAsUnchecked={emailNotificationsDisabled}
                                                isDisabled={isLoadingData || emailNotificationsDisabled}
                                                name="enable_job_notification_job_unassigned"
                                                isControlled={true}
                                            />
                                        </EmailNotificationTooltip>
                                        <SMSNotificationTooltip>
                                            <Checkbox<JobsAssignmentNotificationTopics>
                                                size="md"
                                                colorScheme="gray"
                                                displayAsUnchecked={phoneNotificationsDisabled}
                                                isDisabled={isLoadingData || phoneNotificationsDisabled}
                                                name="enable_job_notification_job_unassigned"
                                                isControlled={true}
                                            />
                                        </SMSNotificationTooltip>
                                    </Settings.NotificationConfigPage.Checkboxes>
                                </Property.Value>
                            </Property.Root>
                            <Property.Root>
                                <Property.Content>
                                    <Property.Label>Job updated</Property.Label>
                                    <Property.Subtitle>
                                        Notify me when someone updates a job I was assigned.
                                    </Property.Subtitle>
                                </Property.Content>
                                <Property.Value>
                                    <Settings.NotificationConfigPage.Checkboxes>
                                        <EmailNotificationTooltip>
                                            <Checkbox<JobsAssignmentNotificationTopics>
                                                size="md"
                                                colorScheme="gray"
                                                displayAsUnchecked={emailNotificationsDisabled}
                                                isDisabled={isLoadingData || emailNotificationsDisabled}
                                                name="enable_job_notification_job_updated"
                                                isControlled={true}
                                            />
                                        </EmailNotificationTooltip>
                                        <SMSNotificationTooltip>
                                            <Checkbox<JobsAssignmentNotificationTopics>
                                                size="md"
                                                colorScheme="gray"
                                                displayAsUnchecked={phoneNotificationsDisabled}
                                                isDisabled={isLoadingData || phoneNotificationsDisabled}
                                                name="enable_job_notification_job_updated"
                                                isControlled={true}
                                            />
                                        </SMSNotificationTooltip>
                                    </Settings.NotificationConfigPage.Checkboxes>
                                </Property.Value>
                            </Property.Root>
                            <Property.Root>
                                <Property.Content>
                                    <Property.Label>Job rescheduled</Property.Label>
                                    <Property.Subtitle>
                                        Notify me when someone reschedules a job I was assigned.
                                    </Property.Subtitle>
                                </Property.Content>
                                <Property.Value>
                                    <Settings.NotificationConfigPage.Checkboxes>
                                        <EmailNotificationTooltip>
                                            <Checkbox<JobsAssignmentNotificationTopics>
                                                size="md"
                                                colorScheme="gray"
                                                displayAsUnchecked={emailNotificationsDisabled}
                                                isDisabled={isLoadingData || emailNotificationsDisabled}
                                                name="enable_job_notification_job_rescheduled"
                                                isControlled={true}
                                            />
                                        </EmailNotificationTooltip>
                                        <SMSNotificationTooltip>
                                            <Checkbox<JobsAssignmentNotificationTopics>
                                                size="md"
                                                colorScheme="gray"
                                                displayAsUnchecked={phoneNotificationsDisabled}
                                                isDisabled={isLoadingData || phoneNotificationsDisabled}
                                                name="enable_job_notification_job_rescheduled"
                                                isControlled={true}
                                            />
                                        </SMSNotificationTooltip>
                                    </Settings.NotificationConfigPage.Checkboxes>
                                </Property.Value>
                            </Property.Root>
                            <Property.Root>
                                <Property.Content>
                                    <Property.Label>Job unscheduled</Property.Label>
                                    <Property.Subtitle>
                                        Notify me when someone unschedules a job I was assigned.
                                    </Property.Subtitle>
                                </Property.Content>
                                <Property.Value>
                                    <Settings.NotificationConfigPage.Checkboxes>
                                        <EmailNotificationTooltip>
                                            <Checkbox<JobsAssignmentNotificationTopics>
                                                size="md"
                                                colorScheme="gray"
                                                displayAsUnchecked={emailNotificationsDisabled}
                                                isDisabled={isLoadingData || emailNotificationsDisabled}
                                                name="enable_job_notification_job_unscheduled"
                                                isControlled={true}
                                            />
                                        </EmailNotificationTooltip>
                                        <SMSNotificationTooltip>
                                            <Checkbox<JobsAssignmentNotificationTopics>
                                                size="md"
                                                colorScheme="gray"
                                                displayAsUnchecked={phoneNotificationsDisabled}
                                                isDisabled={isLoadingData || phoneNotificationsDisabled}
                                                name="enable_job_notification_job_unscheduled"
                                                isControlled={true}
                                            />
                                        </SMSNotificationTooltip>
                                    </Settings.NotificationConfigPage.Checkboxes>
                                </Property.Value>
                            </Property.Root>
                            <Property.Root>
                                <Property.Content>
                                    <Property.Label>Job cancelled</Property.Label>
                                    <Property.Subtitle>
                                        Notify me when someone cancels a job I was assigned.
                                    </Property.Subtitle>
                                </Property.Content>
                                <Property.Value>
                                    <Settings.NotificationConfigPage.Checkboxes>
                                        <EmailNotificationTooltip>
                                            <Checkbox<JobsAssignmentNotificationTopics>
                                                size="md"
                                                colorScheme="gray"
                                                displayAsUnchecked={emailNotificationsDisabled}
                                                isDisabled={isLoadingData || emailNotificationsDisabled}
                                                name="enable_job_notification_job_cancelled"
                                                isControlled={true}
                                            />
                                        </EmailNotificationTooltip>
                                        <SMSNotificationTooltip>
                                            <Checkbox<JobsAssignmentNotificationTopics>
                                                size="md"
                                                colorScheme="gray"
                                                displayAsUnchecked={phoneNotificationsDisabled}
                                                isDisabled={isLoadingData || phoneNotificationsDisabled}
                                                name="enable_job_notification_job_cancelled"
                                                isControlled={true}
                                            />
                                        </SMSNotificationTooltip>
                                    </Settings.NotificationConfigPage.Checkboxes>
                                </Property.Value>
                            </Property.Root>
                        </PropertyStack>
                    </Settings.NotificationConfigPage.Wrapper>
                )
            }}
        </GenericForm>
    )
}
