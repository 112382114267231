// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Label-wrapper__T4Xih{display:inline-flex;gap:var(--size-1);align-items:center}.Label-base__biqF7{color:var(--gray-12);-webkit-user-select:none;-moz-user-select:none;user-select:none}.Label-baseSm__iGWTw{font:var(--font-global-caption-medium-475);font-feature-settings:var(--feature-settings-global-caption-medium-475);letter-spacing:var(--letter-spacing-global-caption-medium-475)}.Label-baseMd__inSrQ{font:var(--font-global-body-475);font-feature-settings:var(--feature-settings-global-body-475);letter-spacing:var(--letter-spacing-global-body-475)}.Label-baseLg__V59Hj{font:var(--font-mobile-body-475);font-feature-settings:var(--feature-settings-mobile-body-475);letter-spacing:var(--letter-spacing-mobile-body-475)}.Label-focused__xOWH7{color:var(--gray-12)}.Label-error__oABHb{color:var(--tomato-a11)}.Label-disabled__ovZnI{color:var(--gray-a7);cursor:not-allowed}.Label-clickable__n6wbc{cursor:pointer}.Label-tooltipTrigger__xZnxv{color:var(--gray-a11)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Label-wrapper__T4Xih",
	"base": "Label-base__biqF7",
	"baseSm": "Label-baseSm__iGWTw",
	"baseMd": "Label-baseMd__inSrQ",
	"baseLg": "Label-baseLg__V59Hj",
	"focused": "Label-focused__xOWH7",
	"error": "Label-error__oABHb",
	"disabled": "Label-disabled__ovZnI",
	"clickable": "Label-clickable__n6wbc",
	"tooltipTrigger": "Label-tooltipTrigger__xZnxv"
};
export default ___CSS_LOADER_EXPORT___;
