import { ResourceTimeRangeModel, TimeZoneHelper } from "@bryntum/schedulerpro"
import clsx from "clsx"

import useUser from "@hooks/useUser"

import { SchedulerTimeRange } from "@organisms/ObjectsView/JobTimelineView/JobTimelineView.types"

import useJobTimelineViewBryntumInstances from "./useJobTimelineViewBryntumInstances"

export default function useJobTimelineViewTimeRanges() {
    const { user } = useUser()

    const preferredTimeZone = user?.service_company?.preferred_timezone ?? "UTC"

    const { schedulerPro } = useJobTimelineViewBryntumInstances()

    const getTimeRanges = (date: Date): SchedulerTimeRange => {
        const midnight = new Date(date)
        midnight.setHours(0, 0, 0, 0)

        const startOfDayStr = user?.service_company?.start_of_day ?? "00:00"

        const [hours, minutes] = startOfDayStr.split(":")
        const startOfDay = new Date(date)
        startOfDay.setHours(Number(hours))
        startOfDay.setMinutes(Number(minutes))
        startOfDay.setSeconds(0)
        startOfDay.setMilliseconds(0)

        const nextDayAtMidnight = new Date(date)
        nextDayAtMidnight.setDate(nextDayAtMidnight.getDate() + 1)
        nextDayAtMidnight.setHours(0, 0, 0, 0)

        const endOfDayStr = user?.service_company?.end_of_day ?? "23:59"

        const [endHours, endMinutes] = endOfDayStr.split(":")
        const endOfDay = new Date(date)
        endOfDay.setHours(Number(endHours))
        endOfDay.setMinutes(Number(endMinutes))
        endOfDay.setSeconds(0)
        endOfDay.setMilliseconds(0)

        return {
            midnight,
            startOfDay,
            endOfDay,
            nextDayAtMidnight,
        }
    }

    const createNonWorkingInterval = (
        timeRange: SchedulerTimeRange,
        resourceId: CalendarTechnician["id"],
        idPrefix: string,
        type: "full-day" | "start-of-day" | "end-of-day",
    ) => {
        const startDate = type === "full-day" || type === "start-of-day" ? timeRange.midnight : timeRange.endOfDay
        const endDate =
            type === "full-day" || type === "end-of-day" ? timeRange.nextDayAtMidnight : timeRange.startOfDay

        return {
            startDate: TimeZoneHelper.fromTimeZone(startDate, preferredTimeZone),
            endDate: TimeZoneHelper.fromTimeZone(endDate, preferredTimeZone),
            cls: clsx("job-timeline-view__non-working-time", {
                "job-timeline-view__non-working-time--unassigned": resourceId === "unassigned",
            }),
            resourceId: resourceId,
            id: `${idPrefix}-${timeRange.midnight.toString()}-${resourceId}`,
        }
    }

    const createWorkingInterval = (
        timeRange: SchedulerTimeRange,
        resourceId: CalendarTechnician["id"],
        idPrefix: string,
    ) => {
        return {
            startDate: TimeZoneHelper.fromTimeZone(timeRange.startOfDay, preferredTimeZone),
            endDate: TimeZoneHelper.fromTimeZone(timeRange.endOfDay, preferredTimeZone),
            cls: clsx("job-timeline-view__working-time", {
                "job-timeline-view__working-time--unassigned": resourceId === "unassigned",
            }),
            resourceId: resourceId,
            id: `${idPrefix}-${timeRange.midnight.toString()}-${resourceId}`,
        }
    }

    const createAllResourcesTimeRanges = (
        timeRange: SchedulerTimeRange,
        resources: CalendarTechnician[],
    ): ResourceTimeRangeModel[] => {
        return resources.flatMap((resource) => {
            if (!resource.available) {
                return [createNonWorkingInterval(timeRange, resource.id, "", "full-day")]
            } else {
                return [
                    createNonWorkingInterval(timeRange, resource.id, "first", "start-of-day"),
                    createWorkingInterval(timeRange, resource.id, "middle"),
                    createNonWorkingInterval(timeRange, resource.id, "last", "end-of-day"),
                ]
            }
        }) as ResourceTimeRangeModel[]
    }

    const createUnassignedTimeRange = (timeRange: SchedulerTimeRange): ResourceTimeRangeModel[] => {
        return [
            createNonWorkingInterval(timeRange, "unassigned", "unassigned-start", "start-of-day"),
            createWorkingInterval(timeRange, "unassigned", "working"),
            createNonWorkingInterval(timeRange, "unassigned", "unassigned-end", "end-of-day"),
        ] as ResourceTimeRangeModel[]
    }

    const generateSchedulerMouseIndicatorTimeRanges = (): ResourceTimeRangeModel[] => {
        return [
            {
                id: "hovered-time-range",
                startDate: new Date(),
                endDate: new Date(),
                cls: "job-timeline-view__create-job-indicator jsJobTimelineViewCreateJobIndicator",
            },
            {
                id: "job-move-feedback",
                startDate: new Date(),
                endDate: new Date(),
                cls: "job-timeline-view__job-moving-indicator jsBryntumTimelineViewMoveFeedback",
            },
        ] as ResourceTimeRangeModel[]
    }

    const generateAllSchedulerTimeRanges = (
        timeRange: SchedulerTimeRange,
        resources: CalendarTechnician[],
    ): ResourceTimeRangeModel[] => {
        const resourceTimeRanges = createAllResourcesTimeRanges(timeRange, resources)

        const unassignedTimeRange = createUnassignedTimeRange(timeRange)

        const schedulerBaseTimeRanges = [...unassignedTimeRange, ...resourceTimeRanges]

        const utilTimeRanges = generateSchedulerMouseIndicatorTimeRanges()

        return [...schedulerBaseTimeRanges, ...utilTimeRanges]
    }

    const configureSchedulerTimeRange = (timeRange: SchedulerTimeRange) => {
        if (schedulerPro.current) {
            schedulerPro.current.instance.setStartDate(timeRange.midnight)
            schedulerPro.current.instance.setEndDate(timeRange.nextDayAtMidnight)
        }
    }

    return {
        getTimeRanges,
        generateAllSchedulerTimeRanges,
        configureSchedulerTimeRange,
    }
}
