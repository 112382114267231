import { yupResolver } from "@hookform/resolvers/yup"
import { useMutation } from "@tanstack/react-query"
import Cookies from "js-cookie"
import { useCallback, useEffect } from "react"
import { DeepPartial, EventType, FieldValues, Path, Resolver, useForm } from "react-hook-form"

import debounce, { DEFAULT_DEBOUNCE_TIME } from "@utils/debounce"

import Form from "@molecules/Form/Form"
import toast from "@molecules/Toast/Toast"

import { HTTP_204_NO_CONTENT } from "@constants/http"

import { GenericError, GenericFormProps, MutationErrorBase } from "./GenericForm.types"

export default function GenericForm<
    FormFields extends FieldValues,
    MutationReturn,
    MutationError extends MutationErrorBase,
    MutationPayload,
>(props: GenericFormProps<FormFields, MutationReturn, MutationError, MutationPayload>) {
    const {
        children,

        formConfig,
        formValues = undefined,
        formValidationSchema,
        onAfterFormValuesSet,
        triggerFormReset = false,

        mutationConfig,
        onMutationError,
        onMutationSuccess,
        mutationTriggerPayload = undefined,

        isAutoSave = false,
        autoSaveDebounceTimeMs = DEFAULT_DEBOUNCE_TIME,

        noFormTag = false,

        className = "",
    } = props

    const form = useForm({
        mode: "onTouched",
        reValidateMode: "onChange",
        ...(formValidationSchema
            ? { resolver: yupResolver(formValidationSchema) as unknown as Resolver<FormFields> }
            : {}),
        ...formConfig,
        defaultValues: formConfig?.defaultValues || undefined,
    })

    useEffect(() => {
        if (formValues) {
            form.reset(formValues)
            onAfterFormValuesSet?.()
        }
    }, [formValues])

    const {
        mutate,
        isPending,
        isError,
        error,
        isSuccess,
        data,
        reset: resetMutationStates,
    } = useMutation<MutationReturn, GenericError<MutationError>, MutationPayload>({
        mutationFn: async (mutationPayload: MutationPayload): Promise<MutationReturn> => {
            const response = await fetch(mutationConfig.endpoint, {
                method: mutationConfig.method,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-CSRFToken": Cookies.get("csrftoken") ?? "",
                },
                body: JSON.stringify(mutationPayload),
            })

            if (!response.ok) {
                await response.json().then((error: MutationError) => {
                    throw new GenericError(mutationConfig.genericErrorMessage, error)
                })
            }

            const responseIsEmpty =
                response.status === HTTP_204_NO_CONTENT || response.headers.get("Content-Length") === "0"

            if (responseIsEmpty) {
                return {} as MutationReturn
            } else {
                return (await response?.json()) as MutationReturn
            }
        },
    })

    useEffect(() => {
        if (triggerFormReset) {
            form.reset()
            resetMutationStates()
        }
    }, [triggerFormReset])

    useEffect(() => {
        if (mutationTriggerPayload) {
            mutate(mutationTriggerPayload)
        }
    }, [mutationTriggerPayload])

    useEffect(() => {
        if (error && isError) {
            const errorKeys = error?.error ? Object.keys(error.error) : []
            const errorValues = error?.error ? (Object.values(error.error) as string[]) : []
            const hasFormError = errorKeys.length > 0

            if (hasFormError) {
                const fieldErrors = errorValues?.[0]
                const fieldErrorsFirstMessage = fieldErrors?.[0]
                const fieldWithError = errorKeys?.[0] as Path<FormFields>

                form.setError(fieldWithError, {
                    type: "manual",
                    message: fieldErrorsFirstMessage,
                })

                form.setFocus(fieldWithError)
            } else {
                toast({
                    type: "error",
                    size: "md",
                    title: error.message,
                })

                console.error(error.message)

                onMutationError?.(error)
            }
        }
    }, [error, isError])

    useEffect(() => {
        if (isSuccess) {
            onMutationSuccess?.({ data: data, formValues: form.getValues() })

            if (mutationConfig.genericSuccessMessage) {
                toast({
                    type: "success",
                    size: "md",
                    title: mutationConfig.genericSuccessMessage,
                })
            }
        }
    }, [isSuccess])

    const onSubmit = (data: FormFields) => {
        if (!mutationConfig.disabled) {
            const payloadFormatter = mutationConfig.payloadFormatter || ((data: FormFields) => data)
            mutate(payloadFormatter(data) as MutationPayload)
        }
    }

    const debouncedSubmit = useCallback(
        debounce(
            ((_: DeepPartial<FormFields>, info: { name?: Path<FormFields>; type?: EventType }) => {
                if (info.type === "change") {
                    void form.handleSubmit(onSubmit)()
                }
            }) as (...args: unknown[]) => unknown,
            autoSaveDebounceTimeMs,
        ),
        [form, onSubmit],
    )

    useEffect(() => {
        const subscription = isAutoSave ? form.watch(debouncedSubmit) : null

        return () => subscription?.unsubscribe()
    }, [isAutoSave])

    // @ts-expect-error types are hard
    const nonFieldError = error?.error?.["__all__"] as string

    return (
        <Form
            form={form}
            onSubmit={isAutoSave ? undefined : (form.handleSubmit(onSubmit) as () => void)}
            noFormTag={noFormTag}
            className={className}
        >
            {children({ isPending, nonFieldError, form })}
        </Form>
    )
}
