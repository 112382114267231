import clsx from "clsx"
import { createContext, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { useHotkeys } from "react-hotkeys-hook"

import { Overlay } from "@particles"

import styles from "./Popover.module.scss"
import { PopoverContextValues, PopoverProps } from "./Popover.types"
import PopoverButton from "./PopoverButton/PopoverButton"
import PopoverContent from "./PopoverContent/PopoverContent"
import PopoverHeader from "./PopoverHeader/PopoverHeader"
import PopoverSubHeader from "./PopoverSubHeader/PopoverSubHeader"

const body = document.querySelector("body")

export const PopoverContext = createContext<PopoverContextValues | undefined>(undefined)
PopoverContext.displayName = "Popover"

const DISTANCE_FROM_PARENT_ELEMENT = 4

function Popover(props: PopoverProps) {
    const { isOpen, onClose, anchorRef, children } = props

    const popoverRef = useRef<HTMLDivElement>(null)

    const [contentHeight, setContentHeight] = useState<number>()
    const [contentActive, setContentActive] = useState<string>()

    const handleClose = () => {
        setContentActive(undefined)
        onClose()
    }

    useHotkeys("esc", handleClose, { enabled: isOpen })

    useEffect(() => {
        const handleAnchor = () => {
            if (!popoverRef.current || !anchorRef.current) {
                return
            }

            const currentElement = popoverRef.current.getBoundingClientRect()
            const parentElement = anchorRef.current.getBoundingClientRect()
            const top = parentElement.top + parentElement.height + DISTANCE_FROM_PARENT_ELEMENT
            const left = parentElement.left - currentElement.width + parentElement.width

            popoverRef.current.style.top = `${top}px`
            popoverRef.current.style.left = `${left}px`
        }

        window.addEventListener("resize", handleAnchor)
        handleAnchor()

        return () => {
            window.removeEventListener("resize", handleAnchor)
        }
    }, [popoverRef, anchorRef])

    useEffect(() => {
        if (isOpen) {
            const allFocusableElements = Array.from(popoverRef.current?.querySelectorAll('[tabindex="0"]') ?? [])
            const firstFocusableElement = allFocusableElements[0] as HTMLElement
            firstFocusableElement?.focus()
        }
    }, [isOpen, contentActive])

    return (
        body &&
        createPortal(
            <PopoverContext.Provider
                value={{
                    contentHeight,
                    setContentHeight,
                    isOpen,
                    contentActive,
                    setContentActive,
                }}
            >
                <Overlay isActive={isOpen} onClick={handleClose} isTransparent={true} />
                <div
                    className={clsx(styles.base, {
                        [styles.open]: isOpen,
                    })}
                    ref={popoverRef}
                    style={{
                        maxHeight: contentHeight,
                        minHeight: contentHeight,
                    }}
                >
                    {children}
                </div>
            </PopoverContext.Provider>,
            body,
        )
    )
}

const Root = Popover
const Header = PopoverHeader
const SubHeader = PopoverSubHeader
const Content = PopoverContent
const Button = PopoverButton

export default { Root, Header, Content, Button, SubHeader }
