import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import Vizzly from "@vizzly/dashboard"
import Cookies from "js-cookie"
import { useState } from "react"

import useIsDesktop from "@hooks/useIsDesktop"
import useSlugExtractor from "@hooks/useSlugExtractor"
import useUser from "@hooks/useUser"

import { Button } from "@atoms"

import { TopBar } from "@organisms"

import { AppFrame } from "@templates"

import { REPORTS_ENDPOINTS } from "@endpoints/reports"

import styles from "./Reports.module.scss"
import { theme } from "./Reports.themes"
import type { VizzlyAuth } from "./Reports.types"

export default function Reports() {
    const { user } = useUser()
    const isDesktop = useIsDesktop()

    const [adminMode, setAdminMode] = useState<boolean>(false)

    const [authEndpoint] = useSlugExtractor([REPORTS_ENDPOINTS.AUTH])

    return (
        user && (
            <AppFrame.Root pageId="Reports">
                <TopBar.Root>
                    <TopBar.Header>
                        <TopBar.TopBarHeaderStart>
                            <TopBar.PageTitle
                                label="Reports"
                                icon={icon({ name: "chart-mixed", style: "regular", family: "sharp" })}
                                iconActive={icon({ name: "chart-mixed", style: "solid", family: "sharp" })}
                            />
                        </TopBar.TopBarHeaderStart>
                        <TopBar.TopBarHeaderEnd>
                            {user?.is_reports_admin && (
                                <Button
                                    colorScheme="gray"
                                    size={isDesktop ? "sm" : "md"}
                                    variant="outline"
                                    leftIcon={
                                        adminMode
                                            ? icon({
                                                  name: "lock",
                                                  style: "solid",
                                                  family: "sharp",
                                              })
                                            : icon({
                                                  name: "unlock",
                                                  style: "solid",
                                                  family: "sharp",
                                              })
                                    }
                                    onClick={() => setAdminMode((prev) => !prev)}
                                >
                                    {adminMode ? "Exit Admin Mode" : "Enter Admin Mode"}
                                </Button>
                            )}
                        </TopBar.TopBarHeaderEnd>
                    </TopBar.Header>
                </TopBar.Root>
                <AppFrame.PageContent>
                    <div className={styles.base} key={adminMode ? "admin" : "standard"}>
                        <Vizzly.Dashboard
                            id="dashboard"
                            theme={theme}
                            queryEngineEndpoint={window.QUERY_ENGINE_ENDPOINT}
                            parentDashboardId={user.service_company.vizzly_dashboard_id}
                            featureToggles={{
                                canEditComponentLibrary: false,
                                showSavingIndicator: true,
                            }}
                            textOverrides={{}}
                            developerTools={{ theme: false, componentOverride: false }}
                            nullValueAlias={() => "\u2014"}
                            identity={async () => {
                                const response = await fetch(authEndpoint, {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        Accept: "application/json",
                                        "X-CSRFToken": Cookies.get("csrftoken") ?? "",
                                    },
                                    body: JSON.stringify({
                                        admin_mode: adminMode,
                                    }),
                                })

                                if (response.ok) {
                                    return (await response.json()) as VizzlyAuth
                                }
                                return null
                            }}
                        />
                    </div>
                </AppFrame.PageContent>
            </AppFrame.Root>
        )
    )
}
