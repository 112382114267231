// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavigationLink-base__pxA3w{color:inherit;-webkit-text-decoration:none;text-decoration:none}.NavigationLink-base__pxA3w:hover{color:inherit}.NavigationLink-base__pxA3w.NavigationLink-underline__os_Ty{-webkit-text-decoration:underline;text-decoration:underline}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "NavigationLink-base__pxA3w",
	"underline": "NavigationLink-underline__os_Ty"
};
export default ___CSS_LOADER_EXPORT___;
