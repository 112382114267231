export const PRICEBOOK_ITEMS_ROUTES = {
    SERVICE: {
        CREATE: "/{service_company_slug}/pricebook/create/?type=Services",
        LIST: "/{service_company_slug}/pricebook/?type=Services",
    },
    PART_OR_MATERIAL: {
        CREATE: "/{service_company_slug}/pricebook/create/?type=Parts+%26+Materials",
        LIST: "/{service_company_slug}/pricebook/?type=Parts+%26+Materials",
    },
    MISCELLANEOUS: {
        CREATE: "/{service_company_slug}/pricebook/create/?type=Miscellaneous",
        LIST: "/{service_company_slug}/pricebook/?type=Miscellaneous",
    },
    DISCOUNT: {
        CREATE: "/{service_company_slug}/pricebook/create/?type=Discounts",
        LIST: "/{service_company_slug}/pricebook/?type=Discounts",
    },
    TAX_RATES: {
        CREATE: "/{service_company_slug}/pricebook/create?type=Tax+Rates",
        LIST: "/{service_company_slug}/pricebook/?type=Tax+Rates",
    },
    LIST: "/{service_company_slug}/pricebook/",
    CREATE: "/{service_company_slug}/pricebook/create/",
    COUNT: "/{service_company_slug}/api/pricebook/count/",
    LIGHTWEIGHT_LIST: "/{service_company_slug}/api/pricebook/lightweight/",
    UPDATE: "/{service_company_slug}/pricebook/{id}/update/",
    REVIEW: "/{service_company_slug}/pricebook/{id}/review/",
}
