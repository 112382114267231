import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"
import { useMemo } from "react"

import { Icon } from "@atoms"

import { useFormField } from "@molecules/Form/Form"

import styles from "./InputHelpText.module.scss"
import { InputHelpTextProps } from "./InputHelpText.types"

export default function InputHelpText(props: InputHelpTextProps) {
    const { children, type = "normal", size, ...rest } = props

    const { formMessageId } = useFormField()

    const typeIcon = useMemo(() => {
        const typeMap: { [key in InputHelpTextProps["type"]]: IconProp | null } = {
            normal: icon({ name: "circle-question", style: "solid", family: "sharp" }),
            info: icon({ name: "circle-info", style: "solid", family: "sharp" }),
            warning: icon({ name: "warning", style: "solid", family: "sharp" }),
            error: icon({ name: "circle-exclamation", style: "solid", family: "sharp" }),
            success: icon({ name: "circle-check", style: "solid", family: "sharp" }),
            none: null,
        }
        return typeMap[type]
    }, [type])

    return (
        <div className={clsx(styles.base, styles[`${type}Icon`])}>
            {typeIcon && <Icon icon={typeIcon} size={size === "sm" ? 12 : 14} />}
            <p
                id={formMessageId}
                className={clsx(styles.text, styles[type], {
                    [styles.textSm]: size === "sm",
                    [styles.textMd]: size === "md",
                    [styles.textLg]: size === "lg",
                })}
                {...rest}
            >
                {children}
            </p>
        </div>
    )
}
