import { useMemo } from "react"

import useCurrentObjectsView from "@hooks/useCurrentObjectsView"

import generateRandomKey from "@utils/generateRandomKey"
import getColumnNameByColumnId from "@utils/getColumnNameByColumnId"
import getSortingEnabledColumnsByIds from "@utils/getSortingEnabledColumnsByIds"
import getTableSortingIconsPairByColumnId from "@utils/getTableSortingIconsPairByColumnId"
import getTableSortingLabelsPairByColumnId from "@utils/getTableSortingLabelsPairByColumnId"

import { Select } from "@molecules"

import { TableViewMenuColumn } from "@organisms/Table/TableViewMenu/TableViewMenu.types"

import styles from "./PopoverSortingColumns.module.scss"
import { PopoverSortingColumnsProps } from "./PopoverSortingColumns.types"

export default function PopoverSortingColumns(props: PopoverSortingColumnsProps) {
    const { currentObjectsView, currentObjectType, updateCurrentObjectsViewSorting } = useCurrentObjectsView()
    const { isVisible } = props
    const columns = useMemo(() => {
        return getSortingEnabledColumnsByIds(currentObjectsView?.columnOrder ?? [], currentObjectType)
    }, [currentObjectsView?.columnOrder, currentObjectType])

    const selectedColumn = useMemo(() => {
        return currentObjectsView?.sorting?.[0]
    }, [currentObjectsView])

    const selectedColumnName = useMemo(() => {
        return selectedColumn && getColumnNameByColumnId(selectedColumn?.id, currentObjectType)
    }, [selectedColumn])

    const selectedColumnSortingIcons = useMemo(() => {
        return selectedColumn && getTableSortingIconsPairByColumnId(selectedColumn?.id)
    }, [selectedColumn])

    const selectedColumnSortingLabels = useMemo(() => {
        return selectedColumn && getTableSortingLabelsPairByColumnId(selectedColumn?.id)
    }, [selectedColumn])

    const selectedColumnSortingText = useMemo(() => {
        return selectedColumn?.desc ? selectedColumnSortingLabels?.desc : selectedColumnSortingLabels?.asc
    }, [selectedColumn])

    const selectedColumnSortingIcon = useMemo(() => {
        return selectedColumn?.desc ? selectedColumnSortingIcons?.desc : selectedColumnSortingIcons?.asc
    }, [selectedColumn])

    const setCurrentSortToAscending = () => {
        selectedColumn && updateCurrentObjectsViewSorting([{ id: selectedColumn?.id, desc: false }])
    }

    const setCurrentSortToDescending = () => {
        selectedColumn && updateCurrentObjectsViewSorting([{ id: selectedColumn?.id, desc: true }])
    }

    const onSortingItemSelection = (column: TableViewMenuColumn) => {
        const notSelected = selectedColumn?.id !== column.value

        if (notSelected) {
            updateCurrentObjectsViewSorting([
                {
                    id: column.value,
                    desc: false,
                },
            ])
        }
    }

    return (
        <div className={styles.base}>
            <Select.Root>
                <Select.Input
                    text={selectedColumnName}
                    placeholder="Select Column"
                    className={styles.droplist}
                    isTabbable={isVisible}
                    size="sm"
                />
                {columns && (
                    <Select.List className={styles.droplist}>
                        <Select.ListHeader />
                        <Select.ListItemStack>
                            {columns.map((column) => {
                                return (
                                    <Select.ListItem
                                        key={generateRandomKey()}
                                        item={column}
                                        onSelect={() => onSortingItemSelection(column)}
                                        isSelected={selectedColumn?.id === column.value}
                                    />
                                )
                            })}
                        </Select.ListItemStack>
                    </Select.List>
                )}
            </Select.Root>
            <Select.Root>
                <Select.Input
                    icon={selectedColumnSortingIcon}
                    text={selectedColumnSortingText}
                    placeholder="Select Sort Order"
                    className={styles.droplist}
                    isTabbable={isVisible}
                    size="sm"
                />
                {columns && selectedColumn && (
                    <Select.List className={styles.droplist}>
                        <Select.ListItemStack>
                            <Select.ListItem
                                item={{
                                    icon: selectedColumnSortingIcons?.asc,
                                    value: selectedColumn?.id,
                                    label: selectedColumnSortingLabels?.asc ?? "",
                                }}
                                onSelect={setCurrentSortToAscending}
                                isSelected={!selectedColumn?.desc}
                            />
                            <Select.ListItem
                                item={{
                                    icon: selectedColumnSortingIcons?.desc,
                                    value: selectedColumn?.id,
                                    label: selectedColumnSortingLabels?.desc ?? "",
                                }}
                                onSelect={setCurrentSortToDescending}
                                isSelected={selectedColumn?.desc}
                            />
                        </Select.ListItemStack>
                    </Select.List>
                )}
            </Select.Root>
        </div>
    )
}
