import Spinner from "@legacy/core/components/Spinner";
import dayjs from "dayjs";
import { Component, Fragment } from "react";
import { renderClientString, renderServiceLocationString } from "../../clients/utils/utils";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import AttachmentHorizontalDisplay from "../../core/components/AttachmentHorizontalDisplay";
import Banner from "../../core/components/Banner";
import EmailDisplay from "../../core/components/EmailDisplay";
import LineItemListDisplayGroup from "../../core/components/LineItemListDisplayGroup";
import PaymentListDisplay from "../../core/components/PaymentListDisplay";
import PaymentListSelect from "../../core/components/PaymentListSelect";
import PhoneNumberDisplay from "../../core/components/PhoneNumberDisplay";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import LinkedObjectsDisplayField from "../../core/fields/LinkedObjectsDisplayField";
import { convertToKebabCase, currencyFormatterForServiceCompany, formatCurrencyForServiceCompany, formatLocalTime, renderInvoiceEstimateDate, valueIsDefined } from "../../core/utils/utils";
import EstimateObjectLink from "../../estimates/components/EstimateObjectLink";
import JobObjectLink from "../../jobs/components/JobObjectLink";
import { calculateInvoiceAmounts, getInvoiceStatusBarLabel } from "../utils/utils";
import InvoiceFeedAccordion from "./InvoiceFeedAccordion";


class InvoiceDetailsCard extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const {invoice, requestAction, switchToSecondaryForm, submitting, errors} = this.props
        const {amountPaidFromPayments, amountDue} = calculateInvoiceAmounts(invoice)

        if (submitting) {
            return (
                <div className="data-panel__action-feedback">
                    <Spinner centered={true} />
                </div>
            )
        }
        else {
            if (window.USING_PUBLIC_URL === true) {
                return (
                    <ButtonGroup>
                        {
                            invoice.service_company.accept_online_payments && invoice.service_company.online_payments_configured && invoice.accept_online_payments && amountDue.toFixed(2) > 0.50 && (
                                <ButtonGroupRow>
                                    <UniversalButton type="primary" text="Pay Now" handler={event => requestAction("INVOICE_PAY_ONLINE")} />
                                </ButtonGroupRow>
                            )
                        }
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("INVOICE_DOWNLOAD_PDF")} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
            else {
                if (invoice.status_label === "Draft") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Create and Send" handler={event => requestAction("INVOICE_CREATE")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("INVOICE_DOWNLOAD_PDF")} />
                                <UniversalButton type="secondary" text="Edit Draft" handler={event => requestAction("INVOICE_EDIT")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (invoice.status_label === "Unsent") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Send Invoice" handler={event => requestAction("INVOICE_SEND")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Revise Invoice" handler={event => requestAction("INVOICE_EDIT")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                            {amountPaidFromPayments === 0 && <UniversalButton type="danger" text="Void Invoice" handler={event => requestAction("INVOICE_VOID")} />}
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("INVOICE_DOWNLOAD_PDF")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (invoice.status_label === "Outstanding" || invoice.status_label === "Overdue") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Add Payment" handler={event => requestAction("INVOICE_ADD_PAYMENT")} />
                            </ButtonGroupRow>
                            {
                                amountPaidFromPayments === 0 && (
                                    <ButtonGroupRow>
                                        <UniversalButton type="secondary" text="Revise Invoice" handler={event => requestAction("INVOICE_EDIT")} />
                                    </ButtonGroupRow>
                                )
                            }
                            <ButtonGroupRow>
                                {amountPaidFromPayments === 0 && <UniversalButton type="danger" text="Void Invoice" handler={event => requestAction("INVOICE_VOID")} />}
                                {window.ACCOUNTING_INTEGRATION !== 1 && (
                                    <UniversalButton type="danger" text="Forgive Invoice" handler={event => requestAction("INVOICE_FORGIVE")} />
                                )}
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Resend Invoice" handler={event => requestAction("INVOICE_SEND")} />
                                <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("INVOICE_DOWNLOAD_PDF")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (invoice.status_label === "Paid") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Resend Invoice" handler={event => requestAction("INVOICE_SEND")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("INVOICE_DOWNLOAD_PDF")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (invoice.status_label === "Voided" || invoice.status_label === "Forgiven") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("INVOICE_DOWNLOAD_PDF")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
            }
        }
    }

    renderLastViewed = (lastViewed, preferredTimezone) => {
        const { localDateTimeString } = formatLocalTime(lastViewed, preferredTimezone, false, true, false, true)
        return localDateTimeString
    }

    render() {
        const {invoice, requestAction, switchToSecondaryForm, submitting, errors} = this.props
        const {subtotal, tax, total, amountPaid, amountDue} = calculateInvoiceAmounts(invoice)
        const downPayments = invoice.payments.filter(payment => payment.from_down_payment !== null)
        const payments = invoice.payments.filter(payment => payment.from_down_payment === null)

        let invoiceNetValue = null
        if (valueIsDefined(invoice.date_due) && valueIsDefined(invoice.date_issued)) {
            invoiceNetValue = dayjs(invoice.date_due).diff(dayjs(invoice.date_issued), "day")
        }

        return (
            <Fragment>
                <div id="invoice_details_react_wrapper">
                    <div className="data-panel-container">
                        <div className={`data-panel-status-bar invoices-${convertToKebabCase(invoice.status_label)}`} aria-label="Status"><span>{getInvoiceStatusBarLabel(invoice)}{window.USING_PUBLIC_URL === true && " Invoice"}</span></div>
                        <div className="data-panel with-status-bar" aria-label="Invoice Details">
                            {
                                invoice.service_company.logo && (
                                    <div className="data-panel__logo" aria-hidden="true">
                                        <div className="data-panel__logo__frame">
                                            <img src={invoice.service_company.logo} alt="Logo" />
                                        </div>
                                    </div>
                                )
                            }
                            <div className="data-panel__form" aria-label="Invoice Details">
                                <div className="data-panel__form__field" aria-label="Invoice Sender">
                                    <span className="data-panel__form__field__label" aria-hidden="true">Sender</span>
                                    <div className="data-panel__form__field__display-list" aria-label="Sender">
                                        <div className="data-panel__form__field__display-list__object invoice-contact-card" aria-label="Sender">
                                            <p>
                                                <strong>{invoice.service_company.name}</strong><br />
                                                <span>{invoice.service_company.physical_address_formatted_multiline}</span><br />
                                                {
                                                    invoice.service_company.phone !== "" && (
                                                        <Fragment><span><PhoneNumberDisplay phone={invoice.service_company.phone} phoneExtension={invoice.service_company.phone_extension} hyperlink={true} /></span><br /></Fragment>
                                                    )
                                                }
                                                {
                                                    invoice.service_company.email !== "" && (
                                                        <Fragment><span><EmailDisplay email={invoice.service_company.email} hyperlink={true} /></span><br /></Fragment>
                                                    )
                                                }
                                                {
                                                    invoice.service_company.contractor_license_number !== "" && (
                                                        <Fragment><span>License #{invoice.service_company.contractor_license_number}</span><br /></Fragment>
                                                    )
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="data-panel__form__field" aria-label="Invoice Recipient">
                                    <span className="data-panel__form__field__label" aria-hidden="true">Recipient (Bill To)</span>
                                    <div className="data-panel__form__field__display-list" aria-label="Recipient (Bill To)">
                                        <div className="data-panel__form__field__display-list__object invoice-contact-card" aria-label="Recipient (Bill To)">
                                            <p>
                                                <strong>{renderClientString(invoice.service_location.external_client)}</strong><br />
                                                <span>{invoice.billing_address_formatted_multiline}</span><br />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <BasicDisplayField
                                    fieldName="service_location"
                                    fieldLabel="Service Location"
                                    fieldValue={renderServiceLocationString(invoice.service_location, true)}
                                ></BasicDisplayField>
                                {
                                    invoice.job !== null && (
                                        <LinkedObjectsDisplayField
                                            fieldName="job"
                                            fieldLabel="Created From Job"
                                            objectComponentList={[<JobObjectLink key="parent_job_0" job={invoice.job} destination={window.USING_PUBLIC_URL === true ? window.PUBLIC_JOB_URL : DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, invoice.job.id)}></JobObjectLink>]}
                                        ></LinkedObjectsDisplayField>
                                    )
                                }
                                {
                                    invoice.estimate !== null && (
                                        <LinkedObjectsDisplayField
                                            fieldName="estimate"
                                            fieldLabel="Created From Estimate"
                                            objectComponentList={[<EstimateObjectLink key="parent_estimate_0" estimate={invoice.estimate} destination={window.USING_PUBLIC_URL === true ? window.PUBLIC_ESTIMATE_URL : DjangoUrls["estimates:estimates-detail"](window.MARKETPLACE_ENTITY_SLUG, invoice.estimate.id)}></EstimateObjectLink>]}
                                        ></LinkedObjectsDisplayField>
                                    )
                                }
                                <BasicDisplayField
                                    fieldName="id"
                                    fieldLabel="Roopairs Invoice ID"
                                    fieldValue={invoice.custom_id ? invoice.custom_id : invoice.id}
                                ></BasicDisplayField>
                                <BasicDisplayField
                                    fieldName="service_name"
                                    fieldLabel="Service Type"
                                    fieldValue={invoice.service_name}
                                ></BasicDisplayField>
                                <BasicDisplayField
                                    fieldName="date_issued"
                                    fieldLabel="Date Issued"
                                    fieldValue={renderInvoiceEstimateDate(invoice.date_issued, invoice.service_company.preferred_timezone)}
                                ></BasicDisplayField>
                                <BasicDisplayField
                                    fieldName="terms"
                                    fieldLabel="Terms"
                                    fieldValue= {invoiceNetValue !== null ? (invoiceNetValue !== 0 ? `Net ${invoiceNetValue}` : "Same Day") : ""}
                                ></BasicDisplayField>
                                <BasicDisplayField
                                    fieldName="date_due"
                                    fieldLabel="Date Due"
                                    fieldValue={renderInvoiceEstimateDate(invoice.date_due, invoice.service_company.preferred_timezone, invoice.status_label === "Overdue")}
                                ></BasicDisplayField>
                                {
                                    invoice.status_label === "Paid" && invoice.date_paid !== null && (
                                        <BasicDisplayField
                                            fieldName="date_paid"
                                            fieldLabel="Date Paid"
                                            fieldValue={renderInvoiceEstimateDate(invoice.date_paid, invoice.service_company.preferred_timezone)}
                                        ></BasicDisplayField>
                                    )
                                }
                                {
                                    !window.USING_PUBLIC_URL && invoice.last_viewed !== null && (
                                        <BasicDisplayField
                                            fieldName="last_viewed"
                                            fieldLabel="Last Seen"
                                            fieldValue={this.renderLastViewed(invoice.last_viewed, invoice.service_company.preferred_timezone)}
                                        ></BasicDisplayField>
                                    )
                                }
                                {
                                    invoice.last_status_transition_time !== null && (
                                        <BasicDisplayField
                                            fieldName="last_status_transition_time"
                                            fieldLabel={`${["Outstanding", "Overdue"].includes(invoice.state_label) ? "Sent" : invoice.state_label} On`}
                                            fieldValue={this.renderLastViewed(invoice.last_status_transition_time, invoice.service_company.preferred_timezone)}
                                        ></BasicDisplayField>
                                    )
                                }
                                {
                                    invoice.last_status_transition_message !== "" && (
                                        <BasicDisplayField
                                            fieldName="last_status_transition_message"
                                            fieldLabel={`${["Outstanding", "Overdue"].includes(invoice.state_label) ? "Sent" : invoice.state_label} Message`}
                                            fieldValue={invoice.last_status_transition_message}
                                        ></BasicDisplayField>
                                    )
                                }
                                <BasicDisplayField
                                    fieldName="last_modified_at"
                                    fieldLabel="Last Modified"
                                    fieldValue={this.renderLastViewed(invoice.last_modified_at, invoice.service_company.preferred_timezone)}
                                ></BasicDisplayField>
                                <LineItemListDisplayGroup
                                    object={invoice}
                                    formatCurrencyValue={currencyFormatterForServiceCompany(invoice.service_company)}
                                    useTaxes={invoice.service_company.use_taxes}
                                    isInvoiceLineItem={true}
                                ></LineItemListDisplayGroup>
                                {
                                    invoice.service_company.use_taxes && invoice.tax_name && (
                                        <BasicDisplayField
                                            fieldName="tax_name"
                                            fieldLabel="Tax Type"
                                            fieldValue={<span title={invoice.tax_breakdown.map(component => `[${component.rate}%] ${component.name}`).join("\n")}>[{invoice.tax_percent}%] {invoice.tax_name}</span>}
                                        ></BasicDisplayField>
                                    )
                                }
                                {
                                    downPayments.length !== 0 && (
                                        <div className="data-panel__form__field" id="div_id_down_payments" aria-label="Down Payments">
                                            <label htmlFor="id_down_payments" className="data-panel__form__field__label">Down Payments</label>
                                            <PaymentListDisplay fieldId="id_down_payments" fieldName="down_payments" object={invoice} payments={downPayments}></PaymentListDisplay>
                                        </div>
                                    )
                                }
                                <div className="data-panel__form__field" id="div_id_payments" aria-label="Payments">
                                    <label htmlFor="id_payments" className="data-panel__form__field__label">Payments</label>
                                    {
                                        payments.length === 0
                                        ?
                                        "(No payments made)"
                                        :
                                        <Fragment>
                                        {
                                            (window.USING_PUBLIC_URL === true || invoice.status_label === "Forgiven")
                                            ?
                                            <PaymentListDisplay fieldId="id_payments" fieldName="payments" object={invoice} payments={payments}></PaymentListDisplay>
                                            :
                                            <div className="data-panel__form__field__input data-panel__form__field__input--with-list-select">
                                                <PaymentListSelect fieldId="id_payments" fieldName="payments" onSelect={data => switchToSecondaryForm("EDIT_PAYMENT", data, null)} object={invoice} endpoint={DjangoUrls["invoices:api-invoices-payments-list"](window.MARKETPLACE_ENTITY_SLUG, invoice.id)}></PaymentListSelect>
                                            </div>
                                        }
                                        </Fragment>
                                    }
                                </div>
                                {
                                    invoice.comments !== "" && (
                                        <BasicDisplayField
                                            fieldName="comments"
                                            fieldLabel="Comments"
                                            fieldValue={invoice.comments}
                                        ></BasicDisplayField>
                                    )
                                }
                                {
                                    invoice.service_company.invoice_fine_print !== "" && (
                                        <BasicDisplayField
                                            fieldName="invoice_fine_print"
                                            fieldLabel={invoice.service_company.invoice_fine_print_label}
                                            fieldValue={invoice.service_company.invoice_fine_print}
                                        ></BasicDisplayField>
                                    )
                                }
                                {
                                    invoice.attachments.length !== 0 && invoice.service_company.feature_file_uploads_enabled && (
                                        <BasicDisplayField
                                            fieldName="attachments"
                                            fieldLabel="Attachments"
                                            fieldValue={<AttachmentHorizontalDisplay
                                                attachments={invoice.attachments}
                                                    />}
                                            >
                                        </BasicDisplayField>)
                                }
                                {
                                    invoice.accounting_sync_status !== null && (
                                        <BasicDisplayField
                                            fieldName="accounting_sync_status"
                                            fieldLabel="Accounting Sync Status"
                                            fieldValue={invoice.accounting_sync_status.needs_syncing? "Not Synced" : `Synced on ${this.renderLastViewed(invoice.accounting_sync_status.last_synced_at, invoice.service_company.preferred_timezone)}`}
                                        ></BasicDisplayField>
                                    )
                                }

                            </div>
                            <div className="data-panel__form data-panel__data-summary amount-summary" aria-label="Amount Summary">
                                <hr aria-hidden="true" />
                                <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--subtotal" aria-label="Subtotal">
                                    <div className="data-panel__data-summary__data__label"><span>Subtotal</span></div>
                                    <div className="data-panel__data-summary__data__value">{formatCurrencyForServiceCompany(subtotal, invoice.service_company)}</div>
                                </div>
                                {
                                    invoice.service_company.use_taxes && (
                                        <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--tax" aria-label="Tax">
                                            <div className="data-panel__data-summary__data__label"><span title={invoice.tax_breakdown.map(component => `[${component.rate}%] ${component.name}`).join("\n")}>Tax ({invoice.tax_percent ? `${invoice.tax_percent}%` : "none"})</span></div>
                                            <div className="data-panel__data-summary__data__value">{formatCurrencyForServiceCompany(tax, invoice.service_company)}</div>
                                        </div>
                                    )
                                }
                                <hr aria-hidden="true" />
                                <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--total" aria-label="Total">
                                    <div className="data-panel__data-summary__data__label"><span>Total</span></div>
                                    <div className="data-panel__data-summary__data__value">{formatCurrencyForServiceCompany(total, invoice.service_company)}</div>
                                </div>
                                <hr aria-hidden="true" />
                                <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--amount-paid" aria-label="Amount Paid">
                                    <div className="data-panel__data-summary__data__label"><span>Amount Paid</span></div>
                                    <div className="data-panel__data-summary__data__value">{formatCurrencyForServiceCompany(amountPaid * -1, invoice.service_company)}</div>
                                </div>
                                <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--amount-due" aria-label="Amount Due">
                                    <div className="data-panel__data-summary__data__label"><span>Amount Due</span></div>
                                    <div className="data-panel__data-summary__data__value">{formatCurrencyForServiceCompany(amountDue, invoice.service_company)}</div>
                                </div>
                            </div>
                            {
                                Object.keys(errors).length !== 0 && (
                                    <Banner type="danger" text="This invoice has errors and cannot be created. Please edit the draft to fix them." extraMargin={true} />
                                )
                            }
                            {this.renderButtons()}
                        </div>
                    </div>
                </div>
                {!(window.USING_PUBLIC_URL === true) &&
                    <div className="accordion-wrapper">
                        <InvoiceFeedAccordion invoiceID={invoice.id} />
                    </div>
                }
                {window.USING_PUBLIC_URL === true &&
                    <div className="powered-by-panel">
                        <span>Powered By</span>
                        <img className="logo--grey" src={window.LOGOTYPE_URL} width="148px" alt="Roopairs"></img>
                    </div>
                }
            </Fragment>
        )
    }

}

export default InvoiceDetailsCard;
