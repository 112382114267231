import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import CalendarContainer from "@legacy/calendar/CalendarContainer"

import { TopBar } from "@organisms"

import { AppFrame } from "@templates"

export default function Calendar() {
    return (
        <AppFrame.Root pageId="Calendar">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle
                            label="Calendar"
                            icon={icon({ name: "objects-align-left", style: "regular", family: "sharp" })}
                            iconActive={icon({ name: "objects-align-left", style: "solid", family: "sharp" })}
                        />
                    </TopBar.TopBarHeaderStart>
                </TopBar.Header>
            </TopBar.Root>
            <AppFrame.PageContent>
                <CalendarContainer />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
