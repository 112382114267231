import { BryntumSchedulerProProps } from "@bryntum/schedulerpro-react"
import clsx from "clsx"

import useIsDesktop from "@hooks/useIsDesktop"
import useUser from "@hooks/useUser"

import { Column } from "@organisms/ObjectsView/JobTimelineView/JobTimelineView.types"
import {
    COMPACT_TIMELINE_ROW_HEIGHT,
    REGULAR_TIMELINE_ROW_HEIGHT,
} from "@organisms/ObjectsView/JobTimelineView/JobTimelineViewConfig/JobTimelineViewConfig"

import useJobTimelineViewEventHandlers from "./useJobTimelineViewEventHandlers"
import useJobTimelineViewRenderers from "./useJobTimelineViewRenderers"
import useJobTimelineViewStates from "./useJobTimelineViewStates"
import useJobTimelineViewUIUtils from "./useJobTimelineViewUIUtils"

export default function useJobTimelineViewSchedulerProps(): {
    verticalProps: BryntumSchedulerProProps
    horizontalProps: BryntumSchedulerProProps
} {
    const { techniciansColumnStatus, jobCardStyle, schedulerZoom, setSchedulerZoom } = useJobTimelineViewStates()

    const {
        onEventDrop,
        onEventResizeEnd,
        onEventClick,
        onScheduleClick,
        onEventPartialResize,
        onEventDrag,
        overlappingEventSorter,
        onSchedulerPaint,
        onMouseMoveOverScheduler,
        onEventDragAbort,
    } = useJobTimelineViewEventHandlers()

    const { hideEventFeedback, showEventFeedback, hideEventMoveFeedback } = useJobTimelineViewUIUtils()

    const {
        renderJobTimelineViewCard,
        renderJobTimelineViewCardTooltip,
        renderTechniciansColumnCell,
        renderTechniciansColumnHeader,
        renderResourceTimeRange,
    } = useJobTimelineViewRenderers()

    const { user } = useUser()
    const isDesktop = useIsDesktop()

    const sharedProps: BryntumSchedulerProProps = {
        viewPreset: `preset${schedulerZoom}`,
        useInitialAnimation: false,
        enableEventAnimations: false,
        // @ts-expect-error bad typing
        onScheduleClick: onScheduleClick,
        eventRenderer: renderJobTimelineViewCard,
        resourceTimeRangeRenderer: renderResourceTimeRange,
        eventTooltipFeature: {
            cls: "job-timeline-view__job-preview-tooltip",
            template: renderJobTimelineViewCardTooltip,
            hideDelay: true,
            allowOver: false,
            showOnHover: true,
            hoverDelay: 0,
            textContent: true,
            scrollAction: "hide",
            preventTooltipOnTouch: true,
            disabled: !isDesktop,
            align: {
                align: "t-b",
                overlap: false,
                anchor: true,
                offset: 4,
            },
        },
        // @ts-expect-error bad typing
        onEventPartialResize: onEventPartialResize,
        onEventDragAbort: onEventDragAbort,
        onEventDragReset: hideEventMoveFeedback,
        // @ts-expect-error bad typing
        onEventDrop: onEventDrop,
        eventResizeFeature: {
            disabled: user?.isServiceTechnician,
            showTooltip: false,
        },
        onEventDrag: onEventDrag,
        eventDragFeature: {
            disabled: user?.isServiceTechnician,
            showTooltip: false,
            copyKey: "",
            dragHelperConfig: {
                dropTargetSelector: ".jsEventDroppableArea",
            },
        },
        onBeforeRenderRow: ({ row }) => {
            row.addCls("jsEventDroppableArea")
        },
        // @ts-expect-error bad typing
        onEventResizeEnd: onEventResizeEnd,
        // @ts-expect-error bad typing
        onEventClick: onEventClick,
        onMouseOut: hideEventFeedback,
        onEventMouseEnter: hideEventFeedback,
        onEventMouseLeave: showEventFeedback,
        listeners: {
            presetchange: ({ zoomDate, to }: { zoomDate: Date; to: { _id: string } }) => {
                if (zoomDate) {
                    // This regex extracts the first sequence of digits from the string since Bryntum Scheduler stores
                    // IDs as strings, not numbers, which are needed to calculate the zoom level.
                    const NUMBER_REGEX = /\d+/
                    const match = to._id.match(NUMBER_REGEX)
                    if (match) {
                        void setSchedulerZoom(Number(match[0]))
                    }
                }
            },
        },
    }

    const verticalProps: BryntumSchedulerProProps = {
        ...sharedProps,
        cls: clsx("jsJobTimelineView job-timeline-view--vertical", {
            "job-timeline-view--service-dispatcher": user?.isServiceDispatcher,
        }),
        mode: "vertical",
        eventLayout: "mixed",
    }

    const horizontalProps: BryntumSchedulerProProps = {
        ...sharedProps,
        cls: clsx("jsJobTimelineView", {
            "job-timeline-view__technicians-bar--collapsed": techniciansColumnStatus === "collapsed",
            "job-timeline-view--service-dispatcher": user?.isServiceDispatcher,
            "job-timeline-view--compact": jobCardStyle === "compact",
        }),
        overlappingEventSorter: overlappingEventSorter,
        rowHeight: jobCardStyle === "regular" ? REGULAR_TIMELINE_ROW_HEIGHT : COMPACT_TIMELINE_ROW_HEIGHT,
        listeners: {
            paint: onSchedulerPaint,
            scheduleMouseMove: onMouseMoveOverScheduler,
            ...sharedProps.listeners,
        },
        columns: [
            {
                type: "resourceInfo",
                id: "techniciansColumn",
                text: "Technicians",
                field: "name",
                showEventCount: false,
                width: techniciansColumnStatus === "collapsed" ? 56 : 256,
                collapsed: techniciansColumnStatus === "collapsed",
                compact: jobCardStyle === "compact",
                collapsible: false,
                readOnly: true,
                filterable: false,
                enableHeaderContextMenu: false,
                sortable: false,
                resizable: false,
                enableCellContextMenu: false,
                region: "left",
                cls: "job-timeline-view__technicians-bar",
                cellCls: "job-timeline-view__technicians-bar-cell-wrap",
                renderer: renderTechniciansColumnCell,
                headerRenderer: renderTechniciansColumnHeader,
            } as Partial<Column>,
        ],
    }

    return {
        verticalProps,
        horizontalProps,
    }
}
