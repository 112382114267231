import { useEffect } from "react"

import toast from "@molecules/Toast/Toast"

import { JobTimelineViewUnscheduledJobsWrapperProps } from "./JobTimelineView.types"
import useJobTimelineViewBryntumInstances from "./hooks/useJobTimelineViewBryntumInstances"
import useJobTimelineViewData from "./hooks/useJobTimelineViewData"
import useTimelineViewUnscheduledJobsUtils from "./hooks/useTimelineViewUnscheduledJobsUtils"

export default function JobTimelineViewUnscheduledJobsWrapper(props: JobTimelineViewUnscheduledJobsWrapperProps) {
    const { children } = props

    const { manageUnscheduledJobsProcess, initializeUnscheduledJobsGrid } = useTimelineViewUnscheduledJobsUtils()

    const { schedulerPro } = useJobTimelineViewBryntumInstances()

    const { unscheduledJobsData, isUnscheduledJobsError } = useJobTimelineViewData()

    useEffect(() => {
        const hasUnscheduledJobsData = unscheduledJobsData !== undefined && unscheduledJobsData?.pages?.length > 0
        if (hasUnscheduledJobsData) {
            manageUnscheduledJobsProcess()
        }
    }, [unscheduledJobsData?.pages])

    useEffect(() => {
        // Initializes the drag and drop functionality for the Grid -> Scheduler.
        const scheduler = schedulerPro.current?.instance

        if (scheduler) {
            initializeUnscheduledJobsGrid()
        }
    }, [schedulerPro.current?.instance])

    useEffect(() => {
        if (isUnscheduledJobsError) {
            toast({
                type: "error",
                size: "md",
                title: "Could not load unscheduled jobs",
            })
        }
    }, [isUnscheduledJobsError])

    return children
}
