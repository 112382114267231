import Cookies from "js-cookie"

import useRouter from "@hooks/useRouter"

import { AUTH_ROUTES } from "@routes/auth"
import { SETTINGS_ROUTES } from "@routes/settings"

import { AUTH_ENDPOINTS } from "@endpoints/auth"

import { SETTINGS_PREVIOUS_PAGE } from "@constants/storage"

import useLocalStorage from "./useLocalStorage"

export default function useUserNavigationUtils() {
    const router = useRouter()

    const [, setPreviousPage] = useLocalStorage<string | null>(SETTINGS_PREVIOUS_PAGE, null)

    const logout = async () => {
        const logoutEndpoint = AUTH_ENDPOINTS.LOGOUT

        const headers = new Headers()
        headers.append("X-CSRFToken", Cookies.get("csrftoken") ?? "")
        headers.append("Accept", "application/json")
        headers.append("Content-Type", "application/json")

        try {
            await fetch(logoutEndpoint, { method: "POST", headers: headers })
            router.push(AUTH_ROUTES.AUTH)
        } catch (error) {
            console.error("Error logging out", error)
        }
    }

    const openHelpSidebar = () => {
        window.Helpkit.show("sidebar")
    }

    const openWhatsNewChangeLog = () => {
        const openChangelog = new CustomEvent("openChangelog", {})
        window.dispatchEvent(openChangelog)
    }

    const goToSettings = () => {
        setPreviousPage(router.pathname)
        router.push(SETTINGS_ROUTES.main)
    }

    const goToContactSupport = () => {
        window.Helpkit.show("sidebar", "contact")
    }

    const goToCannyRequestFeature = () => {
        window.open("https://roopairs.canny.io/features", "_blank", "noopener,noreferrer")
    }

    return {
        logout,
        openHelpSidebar,
        openWhatsNewChangeLog,
        goToSettings,
        goToContactSupport,
        goToCannyRequestFeature,
    }
}
