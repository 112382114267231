import clsx from "clsx"
import dayjs from "dayjs"

import useUser from "@hooks/useUser"

import formatDate from "@utils/formatDate"

import { Table } from "@organisms"

import styles from "./DateTimeCell.module.scss"
import { DateTimeCellProps } from "./DateTimeCell.types"

export default function DateTimeCell(props: DateTimeCellProps) {
    const { value, showTime, isDangerous } = props

    const { user } = useUser()

    if (!value) {
        return <Table.Cells.NoValueCell />
    }

    const currentYear = dayjs().year()
    const parsedDate = dayjs(value)

    const isCurrentYear = parsedDate.year() === currentYear

    const { localDateString, localTimeString } = formatDate({
        datetime: value,
        preferredTimezone: user?.service_company?.preferred_timezone,
        addDay: false,
        addFullDay: false,
        addYear: !isCurrentYear,
        short: true,
    })

    return (
        <div
            className={clsx(styles.base, {
                [styles.dangerous]: isDangerous,
            })}
        >
            {localDateString} {showTime && `• ${localTimeString}`}
        </div>
    )
}
