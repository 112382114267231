"use client"

import * as DialogPrimitive from "@radix-ui/react-dialog"
import { createContext, useEffect, useState } from "react"

import { ModalContextValues, ModalProps } from "./Modal.types"
import ModalBody from "./ModalBody/ModalBody"
import ModalClose from "./ModalClose/ModalClose"
import ModalContent from "./ModalContent/ModalContent"
import ModalFooter from "./ModalFooter/ModalFooter"
import ModalHeader from "./ModalHeader/ModalHeader"
import ModalTrigger from "./ModalTrigger/ModalTrigger"

export const ModalContext = createContext<ModalContextValues | undefined>(undefined)
ModalContext.displayName = "Modal"

function Modal(props: ModalProps) {
    const { children, defaultOpen, open, onOpenChange, ...rest } = props

    const [isOpen, setIsOpen] = useState<boolean | undefined>(defaultOpen)

    useEffect(() => {
        if (open !== undefined) {
            setIsOpen(open)
        }
    }, [open])

    useEffect(() => {
        if (isOpen !== undefined) {
            onOpenChange?.(isOpen)
        }
    }, [isOpen])

    const closeModal = () => setIsOpen(false)
    const openModal = () => setIsOpen(true)

    return (
        <ModalContext.Provider value={{ isOpen: isOpen ?? false, setIsOpen, closeModal, openModal }}>
            <DialogPrimitive.Root {...rest} open={isOpen}>
                {children}
            </DialogPrimitive.Root>
        </ModalContext.Provider>
    )
}

export default {
    Root: Modal,
    Content: ModalContent,
    Body: ModalBody,
    Footer: ModalFooter,
    Header: ModalHeader,
    Trigger: ModalTrigger,
    Close: ModalClose,
}
