import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import { MobileHeader } from "@molecules/index"

import { BottomSheet } from "@organisms"
import useBottomSheet from "@organisms/BottomSheet/BottomSheet.store"
import TableViewMenuColumnsOrder from "@organisms/Table/TableViewMenu/TableViewMenuColumnsOrder/TableViewMenuColumnsOrder"

import styles from "./BottomSheetOrderColumns.module.scss"
import { BottomSheetOrderColumnsProps } from "./BottomSheetOrderColumns.types"

export default function BottomSheetOrderColumns(props: BottomSheetOrderColumnsProps) {
    const { isVisible } = props

    const { setContentActive } = useBottomSheet()

    return (
        <div className={styles.base}>
            <MobileHeader
                title="Columns"
                showBackButton={true}
                onBackClick={() => setContentActive(undefined)}
                isTabbable={isVisible}
                showDragIndicator={true}
            />
            <TableViewMenuColumnsOrder isVisible={isVisible} />
            <div className={styles.addColumnButton}>
                <BottomSheet.Item
                    icon={icon({ name: "plus", style: "light", family: "sharp" })}
                    label="Add Columns"
                    hasSubItems={true}
                    onClick={() => setContentActive("add-columns")}
                    isTabbable={isVisible}
                />
            </div>
        </div>
    )
}
