import { ColumnId, ColumnVisibility } from "@organisms/Table/Table.types"

import getColumnNameByColumnId from "./getColumnNameByColumnId"

interface GetSortingEnabledColumnsByIdsArgs {
    columnIds: ColumnId[]
    objectType: ObjectType
    columnVisibility: ColumnVisibility
}

export default function mapTableInvisibleColumns(args: GetSortingEnabledColumnsByIdsArgs) {
    const { columnIds, objectType, columnVisibility } = args

    return columnIds
        .filter((columnId) => columnVisibility[columnId] === false)
        .map((columnId) => {
            const columnName = getColumnNameByColumnId(columnId, objectType)
            return {
                value: columnId,
                label: columnName ?? "",
            }
        })
}
