import { LsonObject, createClient } from "@liveblocks/client"
import { createRoomContext } from "@liveblocks/react"
import Cookies from "js-cookie"

import { LIVEBLOCKS_ENDPOINTS } from "@endpoints/liveblocks"

const client = createClient({
    authEndpoint: async (room) => {
        const response = await fetch(LIVEBLOCKS_ENDPOINTS.AUTH, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-CSRFToken": Cookies.get("csrftoken") ?? "",
            },
            body: JSON.stringify({ room }),
        })
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return await response.json()
    },
})

// Presence represents the properties that exist on every user in the Room
// and that will automatically be kept in sync. Accessible through the
// `user.presence` property. Must be JSON-serializable.
type Presence = {
    online: boolean
}

// Optionally, Storage represents the shared document that persists in the
// Room, even after all users leave. Fields under Storage typically are
// LiveList, LiveMap, LiveObject instances, for which updates are
// automatically persisted and synced to all connected clients.
type Storage = LsonObject

// Optionally, UserMeta represents static/readonly metadata on each user, as
// provided by your own custom auth back end (if used). Useful for data that
// will not change during a session, like a user's name or avatar.
type UserMeta = {
    id?: string
    info: AvatarUser
}

// Optionally, the type of custom events broadcast and listened to in this
// room. Use a union for multiple events. Must be JSON-serializable.
type RoomEvent = null

export const { RoomProvider, useSelf, useOthers } = createRoomContext<Presence, Storage, UserMeta, RoomEvent>(client)
