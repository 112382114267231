// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuthSlide-base__VnBMj{position:absolute;top:0;height:0;max-height:0;opacity:0;transition:var(--duration-slow);transition-property:opacity,transform,max-height}.AuthSlide-animateFromLeft__vvK6D{transform:translateX(calc(var(--size-full) * -1))}.AuthSlide-animateFromRight__At3tL{transform:translateX(var(--size-full))}.AuthSlide-active__X0XVo{position:static;height:auto;max-height:-moz-fit-content;max-height:fit-content;transform:translateX(0vw);opacity:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "AuthSlide-base__VnBMj",
	"animateFromLeft": "AuthSlide-animateFromLeft__vvK6D",
	"animateFromRight": "AuthSlide-animateFromRight__At3tL",
	"active": "AuthSlide-active__X0XVo"
};
export default ___CSS_LOADER_EXPORT___;
