import { valueIsDefined } from "../../core/utils/utils";
import { getPriceBookTaxObjectLabel } from "../../pricebook/inputs/PriceBookTaxSearchOrCreateSelect";
import { getPricebookTaxById } from "../../pricebook/utils/utils";

function renderClientString(client) {
    return `${client.name}${client.is_active ? "" : " (inactive)"}`
    }

function renderServiceLocationString(serviceLocation, multiline=false) {
    if (multiline) {
        return `${serviceLocation.name}${valueIsDefined(serviceLocation.name) ? "\n" : ""}${serviceLocation.physical_address_formatted_multiline}`
    }
    else {
        const nameDisplay = valueIsDefined(serviceLocation.name) ? `${serviceLocation.name} / ` : ""
        return `${nameDisplay}${serviceLocation.physical_address_formatted}`
    }
}

function getContactKey(contact) {
    return `${contact.name || ""}_${contact.phone || ""}_${contact.phone_extension || ""}_${contact.email || ""}`
}

export function getClientDefaultTaxDisplay(pricebook_tax, default_pricebook_tax, priceBookTaxes) {
    if (valueIsDefined(pricebook_tax)) {
        return getPriceBookTaxObjectLabel(pricebook_tax)
    }
    else {
        let companyDefaultDisplay = "--"

        if (valueIsDefined(default_pricebook_tax) && getPricebookTaxById(default_pricebook_tax, priceBookTaxes)) {
            companyDefaultDisplay = `${parseFloat(getPricebookTaxById(default_pricebook_tax, priceBookTaxes).default_price)}%`
        }

        return `Same as company default (${companyDefaultDisplay})`
    }
}

export function getClientDefaultTermsDisplay(default_client_net, default_company_net) {
    if (valueIsDefined(default_client_net)) {
        return default_client_net === 0 ? "Same Day" : `Net ${default_client_net}`
    }
    else {
        let companyDefaultDisplay = "--"

        if (valueIsDefined(default_company_net)) {
            companyDefaultDisplay = default_company_net === 0 ? "Same Day" : `Net ${default_company_net}`
        }

        return `Same as company default (${companyDefaultDisplay})`
    }
}


function validateContact(allContacts, contact, contactIndex) {
    let isValid = true
    let errors = {}

    const contactKey = getContactKey(contact)
    const matchingContact = allContacts.find(comparison => getContactKey(contact) === getContactKey(comparison))
    const matchingIndex = allContacts.findIndex(comparison => getContactKey(contact) === getContactKey(comparison))

    if (contactKey === "___") {
        isValid = false
        errors.non_field_error = "You must add at least one contact detail to this contact (name, phone, or email)."
    }
    else if (matchingContact && contactIndex !== matchingIndex) {
        isValid = false
        errors.non_field_error = "Another contact with these same details already exists."
    }

    if (contact.receive_invoice_reminders_via_email && !contact.email) {
        isValid = false
        errors.receive_invoice_reminders_via_email = "An email address is required to receive invoice reminders via email."
    }

    if (contact.receive_invoice_reminders_via_sms && !contact.phone) {
        isValid = false
        errors.receive_invoice_reminders_via_sms = "A phone number is required to receive invoice reminders via SMS."
    }

    if (contact.receive_estimate_reminders_via_email && !contact.email) {
        isValid = false
        errors.receive_estimate_reminders_via_email = "An email address is required to receive estimate reminders via email."
    }

    if (contact.receive_estimate_reminders_via_sms && !contact.phone) {
        isValid = false
        errors.receive_estimate_reminders_via_sms = "A phone number is required to receive estimate reminders via SMS."
    }

    return { isValid, errors }
}


export { getContactKey, renderClientString, renderServiceLocationString, validateContact };
