import { CardList, Table } from "@organisms"

import JobTimelineView from "./JobTimelineView/JobTimelineView"
import { ObjectsViewProps } from "./ObjectsView.types"

export default function ObjectsView(props: ObjectsViewProps) {
    const {
        viewType,
        activeTab,
        searchKeywords,
        orderingKey,
        tabs,
        CardComponent,
        listTitle,
        objectCreateRoute,
        objectType,
        objectTypeVariation,
        setSearchKeywords,
        onShowOpen,
        onShowClosed,
        phase,
        setActiveTab,
    } = props

    // Get active tab
    const activeTabConfig = tabs?.find((tab) => tab.title === activeTab)

    if (viewType === "timeline") {
        return <JobTimelineView />
    } else if (viewType === "card") {
        return (
            activeTabConfig &&
            CardComponent && (
                <CardList.Root
                    listTitle={listTitle}
                    objectType={objectType}
                    objectTypeVariation={objectTypeVariation}
                    objectCreateRoute={objectCreateRoute}
                    phase={phase}
                    tabs={tabs}
                    activeTabConfig={activeTabConfig}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    orderingKey={orderingKey}
                    searchKeywords={searchKeywords}
                    setSearchKeywords={setSearchKeywords}
                    onShowOpen={onShowOpen}
                    onShowClosed={onShowClosed}
                    CardComponent={CardComponent}
                />
            )
        )
    } else if (viewType === "table") {
        return (
            activeTabConfig && (
                <Table.Root
                    listTitle={listTitle}
                    objectType={objectType}
                    objectTypeVariation={objectTypeVariation}
                    objectCreateRoute={objectCreateRoute}
                    phase={phase}
                    tabs={tabs}
                    activeTabConfig={activeTabConfig}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    orderingKey={orderingKey}
                    searchKeywords={searchKeywords}
                    setSearchKeywords={setSearchKeywords}
                    onShowOpen={onShowOpen}
                    onShowClosed={onShowClosed}
                />
            )
        )
    } else {
        return null
    }
}
