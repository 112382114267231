// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PopoverButton-base__bKcjP{display:flex;align-items:center;width:var(--size-full);padding:var(--size-1);color:var(--gray-11);text-align:left;background-color:rgba(0,0,0,0);border:0;border-radius:var(--border-radius-1_5);outline:0;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;user-select:none;font:var(--font-global-body-400);font-feature-settings:var(--feature-settings-global-body-400);letter-spacing:var(--letter-spacing-global-body-400)}.PopoverButton-base__bKcjP:focus-visible{color:var(--gray-12);background-color:rgba(0,0,0,0);outline:var(--size-0_5) solid var(--gray-12);outline-offset:calc(var(--size-0_5)*-1)}.PopoverButton-base__bKcjP:focus-visible:hover{background-color:rgba(0,0,0,0)}.PopoverButton-base__bKcjP:focus-visible .PopoverButton-menuItemInnerContainer__UzZHA{background-color:var(--gray-5);border-radius:var(--border-radius-0_5)}.PopoverButton-base__bKcjP:hover{color:var(--gray-12);background-color:var(--gray-5)}.PopoverButton-base__bKcjP:active{color:var(--gray-12);background-color:var(--gray-6)}.PopoverButton-iconAndText__jauL6{display:flex;flex:1;gap:var(--size-1_5);align-items:center}.PopoverButton-menuItemInnerContainer__UzZHA{display:flex;gap:var(--size-2_5);align-items:center;justify-content:space-between;width:var(--size-full);padding:var(--size-0_5) var(--size-1);border-radius:var(--border-radius-0_5)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "PopoverButton-base__bKcjP",
	"menuItemInnerContainer": "PopoverButton-menuItemInnerContainer__UzZHA",
	"iconAndText": "PopoverButton-iconAndText__jauL6"
};
export default ___CSS_LOADER_EXPORT___;
