import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"

import isValidDate from "@utils/isValidDate"

dayjs.extend(timezone)
dayjs.extend(utc)

export interface DateFormatOptions {
    short?: boolean
    addDay?: boolean
    addFullDay?: boolean
    addYear?: boolean
    datetime?: string | Date
    preferredTimezone?: string
}

export default function formatDate(options: DateFormatOptions) {
    const { datetime, preferredTimezone, short = false, addDay = false, addFullDay = false, addYear = false } = options

    const localTime = dayjs(datetime).tz(preferredTimezone)

    let dateFormatString: string
    let timeFormatString: string

    if (short) {
        dateFormatString = "MMM D"
        timeFormatString = "h:mm A"
    } else {
        dateFormatString = "MMM Do"
        timeFormatString = "h:mm A"
    }

    if (addDay || addFullDay) {
        dateFormatString = `${addFullDay ? "dddd" : "ddd"}, ` + dateFormatString
    }

    if (addYear) {
        dateFormatString = dateFormatString + ", YYYY"
    }

    const localDateString = localTime.format(dateFormatString)
    const localTimeString = localTime.format(timeFormatString)

    return {
        localDateString,
        localTimeString,
        localDateTimeString: `${localDateString} • ${localTimeString}`,
        isValidDate: isValidDate(datetime ?? null),
    }
}
