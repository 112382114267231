import clsx from "clsx"
import { useLayoutEffect, useRef, useState } from "react"

import generateRandomKey from "@utils/generateRandomKey"

import { NavigationItem } from "@molecules"

import styles from "./StackedNavigationItems.module.scss"
import { StackedNavigationItemsProps } from "./StackedNavigationItems.types"

export default function StackedNavigationItems(props: StackedNavigationItemsProps) {
    const { header, items, className } = props
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const itemsRef = useRef<HTMLDivElement>(null)
    const [activeItem, setActiveItem] = useState<string | null>(null)

    const toggleOpen = () => setIsOpen(!isOpen)

    const open = (itemLabel: string) => {
        if (activeItem !== itemLabel) {
            setActiveItem(itemLabel)
            setIsOpen(true)
        }
    }

    const [itemsStyle, setItemsStyle] = useState({})

    useLayoutEffect(() => {
        if (isOpen) {
            setItemsStyle({ maxHeight: itemsRef.current?.scrollHeight ?? 0 })
        } else {
            setItemsStyle({ maxHeight: 0 })
        }
    }, [isOpen])

    return (
        <div
            className={clsx(styles.base, className, {
                [styles.open]: isOpen,
            })}
        >
            <div className={styles.header}>
                <NavigationItem
                    label={header.label}
                    variant="primary"
                    hasSubItems={true}
                    onClick={toggleOpen}
                    isExpanded={isOpen}
                    icon={header.icon}
                    iconActive={header.iconActive}
                />
            </div>
            <div className={clsx(styles.items)} ref={itemsRef} style={itemsStyle}>
                {items.map((item) => (
                    <NavigationItem
                        key={generateRandomKey()}
                        label={item.label}
                        path={item.path}
                        isInStack={true}
                        variant="primary"
                        isExpanded={isOpen}
                        onActive={open}
                        icon={item.icon}
                        iconActive={item.iconActive}
                    />
                ))}
            </div>
        </div>
    )
}
