import { PriceBookItemTypes } from "@legacy/core/utils/enums"

import useObjectDetailsRoutes from "@hooks/useObjectDetailsRoute"
import useSlugExtractor from "@hooks/useSlugExtractor"

import { Table } from "@organisms"
import { Cells, Columns } from "@organisms/Table/Table.types"
import { GetObjectDetailsRouteFunction, Tab } from "@organisms/Table/TableViewControls/TableViewTab/TableViewTab.types"

import { PRICEBOOK_ITEMS_ROUTES } from "@routes/pricebook-items"

export const PRICEBOOK_ITEM_TYPE_URL_PARAM_MAP = {
    [PriceBookItemTypes.service]: "Services",
    [PriceBookItemTypes.part]: "Parts+%26+Materials",
    [PriceBookItemTypes.other]: "Miscellaneous",
    [PriceBookItemTypes.discount]: "Discounts",
    [PriceBookItemTypes.tax]: "Tax+Rates",
}

export type PricebookTypeName = "Services" | "Parts & Materials" | "Miscellaneous" | "Discounts" | "Tax Rates"
type PhaseTabs = { [key in "active" | "inactive"]: Tab<PricebookItem>[] }

export default function usePricebookTabs(
    pricebookType?: PricebookTypeName,
): [Tab<PricebookItem>[], Tab<PricebookItem>[]] {
    const [tabCountEndpoint, tabListEndpoint] = useSlugExtractor([
        PRICEBOOK_ITEMS_ROUTES.COUNT,
        PRICEBOOK_ITEMS_ROUTES.LIGHTWEIGHT_LIST,
    ])

    const getTabObjectDetailsRoute = useObjectDetailsRoutes("PriceBookItem") as GetObjectDetailsRouteFunction

    const headerByColumn = {
        description: "Short Description",
        status_label: "State",
        summary_template: "Summary",
        rate: "Rate",
        cost: "Cost",
        default_price: "Price",
        discount: "Discount",
        tax_rate: "Tax Rate",
        manufacturer: "OEM",
        part_number: "OEM Part #",
        default_job_duration: "Est. Duration",
        labels: "Labels",
        inventory_status: "Stock",
        last_modified_at: "Last Modified",
    }

    const sortingEnabledByColumn = {
        description: true,
        status_label: true,
        summary_template: false,
        cost: true,
        rate: true,
        tax_rate: true,
        discount: true,
        default_price: true,
        manufacturer: true,
        part_number: true,
        default_job_duration: true,
        labels: false,
        inventory_status: false,
        last_modified_at: true,
    }

    const widthByColumn = {
        description: 224,
        status_label: 128,
        summary_template: 288,
        rate: 144,
        cost: 144,
        default_price: 144,
        discount: 144,
        tax_rate: 128,
        manufacturer: 144,
        part_number: 144,
        default_job_duration: 144,
        labels: 240,
        inventory_status: 112,
        last_modified_at: 144,
    }

    const pinningByColumn = {
        description: true,
        status_label: false,
        summary_template: false,
        rate: false,
        cost: false,
        default_price: false,
        discount: false,
        tax_rate: false,
        manufacturer: false,
        part_number: false,
        default_job_duration: false,
        labels: false,
        inventory_status: false,
        last_modified_at: false,
    }

    const cellByColumn: Cells<PricebookItem> = {
        description: ({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />,
        status_label: ({ getValue }) => <Table.Cells.BadgesCell badges={[getValue()]} />,
        summary_template: ({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />,
        rate: ({ getValue, row }) => (
            <Table.Cells.NumericalDataCell
                value={Number(getValue())}
                type="value-hour"
                unitType={row.original.default_unit_type_label}
            />
        ),
        cost: ({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="currency" />,
        default_price: ({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="currency" />,
        discount: ({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="currency" />,
        tax_rate: ({ getValue }) => <Table.Cells.NumericalDataCell value={parseFloat(getValue())} type="percentage" />,
        manufacturer: ({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />,
        part_number: ({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />,
        default_job_duration: ({ getValue }) => <Table.Cells.NumericalDataCell type="duration" value={getValue()} />,
        labels: ({ getValue }) => (
            <Table.Cells.BadgesCell
                badges={(getValue() as PricebookItemLabel[]).map((label: PricebookItemLabel) => label.name)}
            />
        ),
        inventory_status: ({ getValue, row }) => (
            <Table.Cells.InventoryStatusCell value={getValue()} trackInventory={row.original.track_inventory} />
        ),
        last_modified_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
    }

    const columnByName: Columns<PricebookItem> = {
        description: {
            header: headerByColumn["description"],
            accessorKey: "description",
            id: "description",
            size: widthByColumn["description"],
            cell: cellByColumn["description"],
            enableSorting: sortingEnabledByColumn["description"],
            enablePinning: pinningByColumn["description"],
        },
        status_label: {
            header: headerByColumn["status_label"],
            accessorKey: "status_label",
            id: "status_label",
            size: widthByColumn["status_label"],
            cell: cellByColumn["status_label"],
            enableSorting: sortingEnabledByColumn["status_label"],
            enablePinning: pinningByColumn["status_label"],
        },
        summary_template: {
            header: headerByColumn["summary_template"],
            accessorKey: "summary_template",
            id: "summary_template",
            size: widthByColumn["summary_template"],
            cell: cellByColumn["summary_template"],
            enableSorting: sortingEnabledByColumn["summary_template"],
            enablePinning: pinningByColumn["summary_template"],
        },
        rate: {
            header: headerByColumn["rate"],
            accessorKey: "default_price",
            id: "rate",
            size: widthByColumn["rate"],
            cell: cellByColumn["rate"],
            enableSorting: sortingEnabledByColumn["rate"],
            enablePinning: pinningByColumn["rate"],
        },
        cost: {
            header: headerByColumn["cost"],
            accessorKey: "cost",
            id: "cost",
            size: widthByColumn["cost"],
            cell: cellByColumn["cost"],
            enableSorting: sortingEnabledByColumn["cost"],
            enablePinning: pinningByColumn["cost"],
        },
        default_price: {
            header: headerByColumn["default_price"],
            accessorKey: "default_price",
            id: "default_price",
            size: widthByColumn["default_price"],
            cell: cellByColumn["default_price"],
            enableSorting: sortingEnabledByColumn["default_price"],
            enablePinning: pinningByColumn["default_price"],
        },
        discount: {
            header: headerByColumn["discount"],
            accessorKey: "default_price",
            id: "discount",
            size: widthByColumn["discount"],
            cell: cellByColumn["discount"],
            enableSorting: sortingEnabledByColumn["discount"],
            enablePinning: pinningByColumn["discount"],
        },
        tax_rate: {
            header: headerByColumn["tax_rate"],
            accessorKey: "default_price",
            id: "tax_rate",
            size: widthByColumn["tax_rate"],
            cell: cellByColumn["tax_rate"],
            enableSorting: sortingEnabledByColumn["tax_rate"],
            enablePinning: pinningByColumn["tax_rate"],
        },
        manufacturer: {
            header: headerByColumn["manufacturer"],
            accessorKey: "manufacturer",
            id: "manufacturer",
            size: widthByColumn["manufacturer"],
            cell: cellByColumn["manufacturer"],
            enableSorting: sortingEnabledByColumn["manufacturer"],
            enablePinning: pinningByColumn["manufacturer"],
        },
        part_number: {
            header: headerByColumn["part_number"],
            accessorKey: "part_number",
            id: "part_number",
            size: widthByColumn["part_number"],
            cell: cellByColumn["part_number"],
            enableSorting: sortingEnabledByColumn["part_number"],
            enablePinning: pinningByColumn["part_number"],
        },
        default_job_duration: {
            header: headerByColumn["default_job_duration"],
            accessorKey: "default_job_duration",
            id: "default_job_duration",
            size: widthByColumn["default_job_duration"],
            cell: cellByColumn["default_job_duration"],
            enableSorting: sortingEnabledByColumn["default_job_duration"],
            enablePinning: pinningByColumn["default_job_duration"],
        },
        labels: {
            header: headerByColumn["labels"],
            accessorKey: "labels",
            id: "labels",
            size: widthByColumn["labels"],
            cell: cellByColumn["labels"],
            enableSorting: sortingEnabledByColumn["labels"],
            enablePinning: pinningByColumn["labels"],
        },
        inventory_status: {
            header: headerByColumn["inventory_status"],
            accessorKey: "inventory_status",
            id: "inventory_status",
            size: widthByColumn["inventory_status"],
            cell: cellByColumn["inventory_status"],
            enableSorting: sortingEnabledByColumn["inventory_status"],
            enablePinning: pinningByColumn["inventory_status"],
        },
        last_modified_at: {
            header: headerByColumn["last_modified_at"],
            accessorKey: "last_modified_at",
            id: "last_modified_at",
            size: widthByColumn["last_modified_at"],
            cell: cellByColumn["last_modified_at"],
            enableSorting: sortingEnabledByColumn["last_modified_at"],
            enablePinning: pinningByColumn["last_modified_at"],
        },
    }

    const servicesColumnDefinition = [
        columnByName["description"],
        columnByName["summary_template"],
        columnByName["rate"],
        columnByName["default_job_duration"],
        columnByName["labels"],
        columnByName["last_modified_at"],
    ]
    const allActiveServicesColumnDefinition = [...servicesColumnDefinition]
    allActiveServicesColumnDefinition.splice(1, 0, columnByName["status_label"])

    const partsAndMaterialsColumnDefinition = [
        columnByName["description"],
        columnByName["labels"],
        columnByName["cost"],
        columnByName["default_price"],
        columnByName["manufacturer"],
        columnByName["part_number"],
        columnByName["inventory_status"],
        columnByName["last_modified_at"],
    ]
    const allActivePartsAndMaterialsColumnDefinition = [...partsAndMaterialsColumnDefinition]
    allActivePartsAndMaterialsColumnDefinition.splice(1, 0, columnByName["status_label"])

    const miscellaneousColumnDefinition = [
        columnByName["description"],
        columnByName["labels"],
        columnByName["default_price"],
        columnByName["summary_template"],
        columnByName["last_modified_at"],
    ]
    const allActiveMiscellaneousColumnDefinition = [...miscellaneousColumnDefinition]
    allActiveMiscellaneousColumnDefinition.splice(1, 0, columnByName["status_label"])

    const discountsColumnDefinition = [
        columnByName["description"],
        columnByName["labels"],
        columnByName["discount"],
        columnByName["summary_template"],
        columnByName["last_modified_at"],
    ]
    const allActiveDiscountsColumnDefinition = [...discountsColumnDefinition]
    allActiveDiscountsColumnDefinition.splice(1, 0, columnByName["status_label"])

    const taxRatesColumnDefinition = [
        columnByName["description"],
        columnByName["labels"],
        columnByName["tax_rate"],
        columnByName["summary_template"],
        columnByName["last_modified_at"],
    ]
    const allActiveTaxRatesColumnDefinition = [...taxRatesColumnDefinition]
    allActiveTaxRatesColumnDefinition.splice(1, 0, columnByName["status_label"])

    const baseTab = {
        tabObjectName: "Item",
        showTab: true,
        countEndpoint: tabCountEndpoint,
        listEndpoint: tabListEndpoint,
        getDetailsRoute: getTabObjectDetailsRoute,
    }

    const servicesTabs: PhaseTabs = {
        active: [
            {
                ...baseTab,
                title: "All Active",
                objectsPhase: "active",
                endpointKwargs: [
                    ["pricebook_item_type", PriceBookItemTypes.service],
                    ["is_active", "true"],
                    ["ordering", "confirmed,description"],
                ],
                columnDefinition: allActiveServicesColumnDefinition,
            },
            {
                ...baseTab,
                title: "Review",
                objectsPhase: "active",
                endpointKwargs: [
                    ["pricebook_item_type", PriceBookItemTypes.service],
                    ["is_active", "true"],
                    ["confirmed", "false"],
                    ["ordering", "description"],
                ],
                columnDefinition: servicesColumnDefinition,
            },
            {
                ...baseTab,
                title: "Confirmed",
                objectsPhase: "active",
                endpointKwargs: [
                    ["pricebook_item_type", PriceBookItemTypes.service],
                    ["is_active", "true"],
                    ["confirmed", "true"],
                    ["ordering", "description"],
                ],
                columnDefinition: servicesColumnDefinition,
            },
        ],
        inactive: [
            {
                ...baseTab,
                title: "All Inactive",
                objectsPhase: "inactive",
                endpointKwargs: [
                    ["pricebook_item_type", PriceBookItemTypes.service],
                    ["is_active", "false"],
                    ["ordering", "confirmed,description"],
                ],
                columnDefinition: servicesColumnDefinition,
            },
        ],
    }

    const partsAndMaterialsTabs: PhaseTabs = {
        active: [
            {
                ...baseTab,
                title: "All Active",
                objectsPhase: "active",
                endpointKwargs: [
                    ["pricebook_item_type", PriceBookItemTypes.part],
                    ["is_active", "true"],
                    ["ordering", "confirmed,description"],
                ],
                columnDefinition: allActivePartsAndMaterialsColumnDefinition,
            },
            {
                ...baseTab,
                title: "Review",
                objectsPhase: "active",
                endpointKwargs: [
                    ["pricebook_item_type", PriceBookItemTypes.part],
                    ["is_active", "true"],
                    ["confirmed", "false"],
                    ["ordering", "description"],
                ],
                columnDefinition: partsAndMaterialsColumnDefinition,
            },
            {
                ...baseTab,
                title: "Confirmed",
                objectsPhase: "active",
                endpointKwargs: [
                    ["pricebook_item_type", PriceBookItemTypes.part],
                    ["is_active", "true"],
                    ["confirmed", "true"],
                    ["ordering", "description"],
                ],
                columnDefinition: partsAndMaterialsColumnDefinition,
            },
        ],
        inactive: [
            {
                ...baseTab,
                title: "All Inactive",
                objectsPhase: "inactive",
                endpointKwargs: [
                    ["pricebook_item_type", PriceBookItemTypes.part],
                    ["is_active", "false"],
                    ["ordering", "confirmed,description"],
                ],
                columnDefinition: partsAndMaterialsColumnDefinition,
            },
        ],
    }

    const miscellaneousTabs: PhaseTabs = {
        active: [
            {
                ...baseTab,
                title: "All Active",
                objectsPhase: "active",
                endpointKwargs: [
                    ["pricebook_item_type", PriceBookItemTypes.other],
                    ["is_active", "true"],
                    ["ordering", "confirmed,description"],
                ],
                columnDefinition: allActiveMiscellaneousColumnDefinition,
            },
            {
                ...baseTab,
                title: "Review",
                objectsPhase: "active",
                endpointKwargs: [
                    ["pricebook_item_type", PriceBookItemTypes.other],
                    ["is_active", "true"],
                    ["confirmed", "false"],
                    ["ordering", "description"],
                ],
                columnDefinition: miscellaneousColumnDefinition,
            },
            {
                ...baseTab,
                title: "Confirmed",
                objectsPhase: "active",
                endpointKwargs: [
                    ["pricebook_item_type", PriceBookItemTypes.other],
                    ["is_active", "true"],
                    ["confirmed", "true"],
                    ["ordering", "description"],
                ],
                columnDefinition: miscellaneousColumnDefinition,
            },
        ],
        inactive: [
            {
                ...baseTab,
                title: "All Inactive",
                objectsPhase: "inactive",
                endpointKwargs: [
                    ["pricebook_item_type", PriceBookItemTypes.other],
                    ["is_active", "false"],
                    ["ordering", "confirmed,description"],
                ],
                columnDefinition: miscellaneousColumnDefinition,
            },
        ],
    }

    const discountsTabs: PhaseTabs = {
        active: [
            {
                ...baseTab,
                title: "All Active",
                objectsPhase: "active",
                endpointKwargs: [
                    ["pricebook_item_type", PriceBookItemTypes.discount],
                    ["is_active", "true"],
                    ["ordering", "confirmed,description"],
                ],
                columnDefinition: allActiveDiscountsColumnDefinition,
            },
            {
                ...baseTab,
                title: "Review",
                objectsPhase: "active",
                endpointKwargs: [
                    ["pricebook_item_type", PriceBookItemTypes.discount],
                    ["is_active", "true"],
                    ["confirmed", "false"],
                    ["ordering", "description"],
                ],
                columnDefinition: discountsColumnDefinition,
            },
            {
                ...baseTab,
                title: "Confirmed",
                objectsPhase: "active",
                endpointKwargs: [
                    ["pricebook_item_type", PriceBookItemTypes.discount],
                    ["is_active", "true"],
                    ["confirmed", "true"],
                    ["ordering", "description"],
                ],
                columnDefinition: discountsColumnDefinition,
            },
        ],
        inactive: [
            {
                ...baseTab,
                title: "All Inactive",
                objectsPhase: "inactive",
                endpointKwargs: [
                    ["pricebook_item_type", PriceBookItemTypes.discount],
                    ["is_active", "false"],
                    ["ordering", "confirmed,description"],
                ],
                columnDefinition: discountsColumnDefinition,
            },
        ],
    }

    const taxRatesPricebookTabs: PhaseTabs = {
        active: [
            {
                ...baseTab,
                title: "All Active",
                objectsPhase: "active",
                endpointKwargs: [
                    ["pricebook_item_type", PriceBookItemTypes.tax],
                    ["is_active", "true"],
                    ["ordering", "confirmed,description"],
                ],
                columnDefinition: allActiveTaxRatesColumnDefinition,
            },
            {
                ...baseTab,
                title: "Review",
                objectsPhase: "active",
                endpointKwargs: [
                    ["pricebook_item_type", PriceBookItemTypes.tax],
                    ["is_active", "true"],
                    ["confirmed", "false"],
                    ["ordering", "description"],
                ],
                columnDefinition: taxRatesColumnDefinition,
            },
            {
                ...baseTab,
                title: "Confirmed",
                objectsPhase: "active",
                endpointKwargs: [
                    ["pricebook_item_type", PriceBookItemTypes.tax],
                    ["is_active", "true"],
                    ["confirmed", "true"],
                    ["ordering", "description"],
                ],
                columnDefinition: taxRatesColumnDefinition,
            },
        ],
        inactive: [
            {
                ...baseTab,
                title: "All Inactive",
                objectsPhase: "inactive",
                endpointKwargs: [
                    ["pricebook_item_type", PriceBookItemTypes.tax],
                    ["is_active", "false"],
                    ["ordering", "confirmed,description"],
                ],
                columnDefinition: taxRatesColumnDefinition,
            },
        ],
    }

    if (pricebookType) {
        const tabs: {
            [key in PricebookTypeName]: PhaseTabs
        } = {
            Services: servicesTabs,
            "Parts & Materials": partsAndMaterialsTabs,
            Miscellaneous: miscellaneousTabs,
            Discounts: discountsTabs,
            "Tax Rates": taxRatesPricebookTabs,
        }

        return [tabs[pricebookType].active, tabs[pricebookType].inactive]
    } else {
        return [[], []]
    }
}
