import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import PriceBookItemCreateContainer from "@legacy/pricebook/PriceBookItemCreateContainer"

import useIsDesktop from "@hooks/useIsDesktop"
import useNavigationLinks from "@hooks/useNavigationLinks"
import useRouter from "@hooks/useRouter"
import useSlugExtractor from "@hooks/useSlugExtractor"
import useToastQueue from "@hooks/useToastQueue"

import { TopBar } from "@organisms"

import { AppFrame } from "@templates"

import { PRICEBOOK_ITEMS_ROUTES } from "@routes/pricebook-items"

import { DECODED_PRICEBOOK_ITEM_URL_PARAM_TYPE_VALUES_MAP } from "@constants/decodedPricebookItemURLParamTypeValues"
import { LIST_SEARCH_PARAM_TYPE } from "@constants/searchParams"

export default function PricebookReview() {
    const isDesktop = useIsDesktop()

    const { push } = useRouter()
    const { pricebook: pricebookLink } = useNavigationLinks()

    const [listRoute] = useSlugExtractor([PRICEBOOK_ITEMS_ROUTES.LIST])
    const { addToastToQueue } = useToastQueue()

    const pricebookTypeFromURL = new URLSearchParams(window.location.search).get(LIST_SEARCH_PARAM_TYPE)
    const backDestination = pricebookTypeFromURL
        ? `${listRoute}?${LIST_SEARCH_PARAM_TYPE}=${DECODED_PRICEBOOK_ITEM_URL_PARAM_TYPE_VALUES_MAP[pricebookTypeFromURL]}`
        : listRoute

    return (
        <AppFrame.Root pageId="PricebookReview">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle
                            label={pricebookTypeFromURL ?? ""}
                            icon={icon({ name: "tag", style: "regular", family: "sharp" })}
                            iconActive={icon({ name: "tag", style: "solid", family: "sharp" })}
                            iconOnly={!isDesktop}
                            onNavigationItemSelect={(item) => {
                                push(item.value)
                            }}
                            navigationItems={pricebookLink.subLinks?.map((item) => ({
                                label: item.label,
                                value: item.path,
                                isActive: pricebookTypeFromURL === item.label,
                            }))}
                        />
                        <TopBar.VerticalDivider />
                        <TopBar.PageSubtitle label="Review Pricebook Item" backDestination={backDestination} />
                    </TopBar.TopBarHeaderStart>
                </TopBar.Header>
            </TopBar.Root>
            <AppFrame.PageContent>
                <PriceBookItemCreateContainer formMode="REVIEW_PRICEBOOKITEM" addToastToQueue={addToastToQueue} />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
