import { DateHelper } from "@bryntum/schedulerpro"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { JobStatuses } from "@legacy/core/utils/enums"
import clsx from "clsx"

import useUser from "@hooks/useUser"

import formatDate from "@utils/formatDate"
import formatDuration from "@utils/formatDuration"
import getJobStatusColorClass from "@utils/getJobStatusColorClass"
import getJobStatusOrSubStatusLabel from "@utils/getJobStatusOrSubStatusLabel"

import { Divider, Icon } from "@atoms"

import { JobStatusBadge, UserAvatar, UserAvatarStack } from "@molecules"

import useJobTimelineViewStates from "@organisms/ObjectsView/JobTimelineView/hooks/useJobTimelineViewStates"

import styles from "./JobPreviewCard.module.scss"
import { JobPreviewCardProps } from "./JobPreviewCard.types"

export default function JobPreviewCard(props: JobPreviewCardProps) {
    const {
        jobId,
        jobName,
        clientName,
        assignedTechnicians,
        jobAddress,
        jobDate,
        jobDuration,
        reasonForCall,
        subStatusLabel,
        statusLabel,
        statusColorOverride,
        createdAt,
        createdBy,
        status,
    } = props

    const { user } = useUser()

    const { todayInUserTimezone } = useJobTimelineViewStates()

    const isCurrentYear = new Date(jobDate).getFullYear() === new Date(todayInUserTimezone).getFullYear()

    const {
        localDateString: startDate,
        localTimeString: startTime,
        isValidDate,
    } = formatDate({
        datetime: new Date(jobDate),
        preferredTimezone: undefined,
        addDay: false,
        addFullDay: false,
        addYear: !isCurrentYear,
        short: true,
    })

    let localCreatedAt: string | undefined

    if (createdAt) {
        const { localDateString: localDateStringCreatedAt } = formatDate({
            datetime: new Date(createdAt),
            preferredTimezone: user?.service_company?.preferred_timezone,
            addDay: false,
            addFullDay: false,
            addYear: !isCurrentYear,
            short: true,
        })
        localCreatedAt = localDateStringCreatedAt
    }

    const isPastDue = DateHelper.isBefore(new Date(jobDate), new Date(todayInUserTimezone))

    const isScheduled = status === JobStatuses.scheduled
    const isOnHold = status === JobStatuses.on_hold

    function DateInfo({ title, value }: { title: string; value: string }) {
        return (
            <div className={styles.dateInfoWrapper}>
                <p className={styles.dateInfoLabel}>{title}</p>
                <p className={styles.dateInfoValue}>{value}</p>
            </div>
        )
    }

    const techniciansList =
        assignedTechnicians.length === 0
            ? [{ isUnknown: true, full_name: "Unassigned", avatar: "", gravatar: "" } satisfies AvatarUser]
            : assignedTechnicians

    const noReasonForCall = reasonForCall === ""

    return (
        <div
            className={clsx(styles.base, {
                [styles.danger]: isPastDue && isScheduled,
                [styles.warning]: isPastDue && isOnHold,
            })}
        >
            <div className={styles.topSection}>
                <div className={styles.jobAndStatus}>
                    <span className={styles.jobId}>{jobId}</span>

                    <div className={styles.status}>
                        <JobStatusBadge
                            status={getJobStatusOrSubStatusLabel({
                                status_label: statusLabel,
                                substatus_label: subStatusLabel,
                            } as Job)}
                            dotStatusOverride={getJobStatusColorClass({
                                status_color_override: statusColorOverride,
                                status_label: statusLabel,
                            } as Job)}
                            dateDue={jobDate}
                        />
                    </div>
                </div>

                <div className={styles.clientLocationAndTechs}>
                    <div className={styles.jobNameAndClient}>
                        <p className={styles.jobName}>{jobName}</p>
                        <p className={styles.jobClient}>for {clientName}</p>
                    </div>

                    <div className={styles.jobLocationDetails}>
                        <span className={styles.jobLocationIcon}>
                            <Icon icon={icon({ name: "map", style: "light", family: "sharp" })} />
                        </span>
                        <p className={styles.locationAddress}>{jobAddress}</p>
                    </div>

                    <UserAvatarStack
                        limit={3}
                        users={techniciansList}
                        avatarSize={20}
                        showTooltip={true}
                        variation="stack"
                    />
                </div>
            </div>

            <div className={styles.scheduledForWrapper}>
                {!(isPastDue && isScheduled) && <Divider />}
                <div className={styles.dataScheduledFor}>
                    <DateInfo title="Date" value={isValidDate ? startDate : "—"} />
                    <DateInfo title="Time" value={isValidDate ? startTime : "—"} />
                    <DateInfo title="Duration" value={String(formatDuration(jobDuration))} />
                </div>
                {!(isPastDue && isOnHold) && <Divider />}
            </div>

            {isPastDue && (isScheduled || isOnHold) && (
                <div className={styles.pastDueWrapper}>
                    <div className={styles.pastDueWrapperInside}>
                        <Icon icon={icon({ name: "triangle-exclamation", style: "solid", family: "sharp" })} />

                        <span className={styles.pastDueLabel}>Reschedule required</span>
                    </div>
                </div>
            )}

            <div className={styles.reasonsForCallWrapper}>
                <p className={styles.reasonsForCallLabel}>Reason For Call</p>
                <p
                    className={clsx(styles.reasonsForCallValue, {
                        [styles.noReasonForCall]: noReasonForCall,
                    })}
                >
                    {reasonForCall ? reasonForCall : "Not mentioned"}
                </p>
            </div>

            {createdBy && createdAt && (
                <div className={styles.createdBy}>
                    <span className={styles.labelCreatedBy}>Created on {localCreatedAt} by </span>
                    <span className={styles.jobCreator}>
                        <UserAvatar user={createdBy} size={20} shape="circle" />{" "}
                        <span className={styles.jobCreatorName}>{createdBy?.full_name}</span>
                    </span>
                </div>
            )}
        </div>
    )
}
