export const DEFAULT_DEBOUNCE_TIME = 250

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function debounce<T extends (...args: any) => any>(func: T, wait = DEFAULT_DEBOUNCE_TIME) {
    let timeout: ReturnType<typeof setTimeout>

    return function executedFunction(...args: Parameters<T>) {
        const later = () => {
            clearTimeout(timeout)
            func(...args)
        }

        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
    }
}
