import { useInfiniteQuery } from "@tanstack/react-query"
import { useEffect, useMemo, useState } from "react"

import useCurrentObjectsView from "@hooks/useCurrentObjectsView"
import useLocalStorage from "@hooks/useLocalStorage"
import useUser from "@hooks/useUser"

import extractURLSearchParam from "@utils/extractURLSearchParam"
import fetchObjectListData from "@utils/fetchObjectListData"
import getOrderingFromSortingState from "@utils/getOrderingFromSortingState"
import getSortingStateFromOrdering from "@utils/getSortingStateFromOrdering"

import { TopBar } from "@organisms"
import { ObjectListData } from "@organisms/ObjectsView/ObjectsView.types"

import { LIST_SEARCH_PARAM_ORDERING } from "@constants/searchParams"

import { SortingState, TableProps } from "./Table.types"
import BadgesCell from "./TableCells/BadgesCell/BadgesCell"
import DateTimeCell from "./TableCells/DateTimeCell/DateTimeCell"
import DownPaymentCell from "./TableCells/DownPaymentCell/DownPaymentCell"
import IDCell from "./TableCells/IDCell/IDCell"
import InventoryStatusCell from "./TableCells/InventoryStatusCell/InventoryStatusCell"
import LinkCell from "./TableCells/LinkCell/LinkCell"
import NoValueCell from "./TableCells/NoValueCell/NoValueCell"
import NumericalDataCell from "./TableCells/NumericalDataCell/NumericalDataCell"
import PaymentTermsCell from "./TableCells/PaymentTermsCell/PaymentTermsCell"
import SeenCell from "./TableCells/SeenCell/SeenCell"
import SkeletonCell from "./TableCells/SkeletonCell/SkeletonCell"
import StatusCell from "./TableCells/StatusCell/StatusCell"
import TruncatedTextCell from "./TableCells/TruncatedTextCell/TruncatedTextCell"
import UsersCell from "./TableCells/UsersCell/UsersCell"
import TableContent from "./TableContent/TableContent"
import TableViewControls from "./TableViewControls/TableViewControls"
import TableViewMenu from "./TableViewMenu/TableViewMenu"

function Table(props: TableProps) {
    const {
        listTitle,
        objectType,
        objectTypeVariation,
        objectCreateRoute,
        phase,
        tabs,
        activeTabConfig,
        activeTab,
        setActiveTab,
        orderingKey,
        searchKeywords,
        setSearchKeywords,
        onShowOpen,
        onShowClosed,
    } = props

    const [isRefetching, setIsRefetching] = useState(false)
    const { isLoading: isUserLoading } = useUser()

    const tabContentName = `${activeTabConfig?.title} ${listTitle}`
    const listEndpoint = activeTabConfig?.listEndpoint
    const endpointKwargs = activeTabConfig?.endpointKwargs

    // Get sorting
    const sortingOverride = useMemo(() => extractURLSearchParam(LIST_SEARCH_PARAM_ORDERING), [])
    const defaultOrdering = useMemo(
        () => endpointKwargs?.find((kwarg) => kwarg[0] === "ordering")?.[1] || "",
        [activeTabConfig],
    )
    const [ordering, setOrdering] = useLocalStorage(orderingKey, "", sortingOverride)

    const sorting = getSortingStateFromOrdering(ordering || (defaultOrdering as string))
    const setSorting = (newSorting: SortingState) => {
        setOrdering(getOrderingFromSortingState(newSorting))
    }

    const { data, error, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } =
        useInfiniteQuery<ObjectListData>({
            queryKey: [listEndpoint, endpointKwargs, tabContentName, searchKeywords, sorting],
            queryFn: ({ pageParam }) =>
                fetchObjectListData({
                    objectName: tabContentName,
                    endpoint: listEndpoint,
                    endpointKwargs,
                    searchKeywords,
                    sorting,
                    nextPage: pageParam as string,
                }),
            initialPageParam: "",
            staleTime: 60000,
            getNextPageParam: (lastPage) => lastPage.next,
            enabled: !!(activeTabConfig && activeTabConfig.showTab),
        })

    const currentData = useMemo(() => data?.pages.map((page) => page.results).flat(1) || [], [data])

    const handleRefetch = async () => {
        setIsRefetching(true)
        await refetch()
        setIsRefetching(false)
    }

    const { currentObjectsView, sortingChanged, setSortingChanged } = useCurrentObjectsView()

    useEffect(() => {
        if (sortingChanged) {
            setSorting(currentObjectsView?.sorting ?? [])

            setSortingChanged(false)
        }
    }, [sortingChanged])

    return (
        <>
            <TopBar.Portal>
                <TableViewControls
                    phase={phase}
                    tabs={tabs}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    searchKeywords={searchKeywords}
                    setSearchKeywords={setSearchKeywords}
                    objectType={objectType}
                    onShowOpen={onShowOpen}
                    onShowClosed={onShowClosed}
                />
            </TopBar.Portal>
            <TableContent
                activeTabConfig={activeTabConfig}
                currentData={currentData}
                setSorting={setSorting}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                objectType={objectType}
                objectTypeVariation={objectTypeVariation}
                activeTab={activeTab}
                tabContentName={tabContentName}
                tabs={tabs}
                error={error}
                refetchObjects={handleRefetch}
                objectCreateRoute={objectCreateRoute}
                isEmpty={currentData?.length === 0 && !isLoading}
                isLoading={isLoading || isUserLoading || isRefetching}
                isSearching={!!searchKeywords}
                isError={isError}
            />
        </>
    )
}

const Root = Table
const ViewMenu = TableViewMenu

export default {
    Root,
    ViewMenu,
    Cells: {
        IDCell,
        NoValueCell,
        TruncatedTextCell,
        LinkCell,
        InventoryStatusCell,
        PaymentTermsCell,
        BadgesCell,
        StatusCell,
        NumericalDataCell,
        DateTimeCell,
        SeenCell,
        UsersCell,
        DownPaymentCell,
        SkeletonCell,
    },
}
