import replaceSlugs from "@utils/replaceSlugs"

import useUser from "./useUser"

type RoutePath = string
type ID = string

export default function useSlugExtractor(routes: RoutePath[], id?: ID) {
    const { user } = useUser()

    const serviceCompanySlug = user?.service_company?.slug ?? "undefined"

    const slugValues: { [key: string]: string } = {
        service_company_slug: serviceCompanySlug,
    }

    if (id) {
        slugValues["id"] = id
    }

    return routes.map((route) => replaceSlugs(route, slugValues))
}
