// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PopoverHeader-base__GuxD7{position:relative;display:flex;flex-direction:column;gap:var(--size-1);padding:var(--size-1_5);-webkit-user-select:none;-moz-user-select:none;user-select:none}.PopoverHeader-base__GuxD7.PopoverHeader-withBorderBottom__xZFJ6::before{position:absolute;right:0;bottom:0;left:0;height:var(--size-0_25);background-color:var(--gray-3);content:\"\"}.PopoverHeader-base__GuxD7.PopoverHeader-withBackButton__rqghd .PopoverHeader-heading__W_EY0{padding:var(--size-0_5) 0}.PopoverHeader-heading__W_EY0{display:flex;gap:var(--size-1);align-items:center;width:var(--size-full);padding:var(--size-1_5) var(--size-1)}.PopoverHeader-title__syR_p{padding-left:var(--size-0_5);color:var(--gray-12);font:var(--font-global-heading-03);font-feature-settings:var(--feature-settings-global-heading-03);letter-spacing:var(--letter-spacing-global-heading-03)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "PopoverHeader-base__GuxD7",
	"withBorderBottom": "PopoverHeader-withBorderBottom__xZFJ6",
	"withBackButton": "PopoverHeader-withBackButton__rqghd",
	"heading": "PopoverHeader-heading__W_EY0",
	"title": "PopoverHeader-title__syR_p"
};
export default ___CSS_LOADER_EXPORT___;
