import { useMemo } from "react"

import useIsDesktop from "@hooks/useIsDesktop"

import generateRandomKey from "@utils/generateRandomKey"
import getObjectsViewIcon from "@utils/getObjectsViewIcon"
import getObjectsViewLabel from "@utils/getObjectsViewLabel"

import { Tab } from "@atoms"

import { Select, TabStack } from "@molecules"

import styles from "./TopBarViewSelector.module.scss"
import { TopBarViewSelectorProps } from "./TopBarViewSelector.types"

export default function TopBarViewSelector(props: TopBarViewSelectorProps) {
    const { viewTypes, selectedViewType, setViewType } = props

    const isDesktop = useIsDesktop()

    const selectedViewTypeIcon = useMemo(() => {
        return getObjectsViewIcon(selectedViewType)
    }, [selectedViewType])

    const selectedViewTypeLabel = useMemo(() => {
        return getObjectsViewLabel(selectedViewType)
    }, [selectedViewType])

    const listItems = viewTypes.map((viewType) => (
        <Select.ListItem
            key={generateRandomKey()}
            item={{
                iconInReactNode: useMemo(() => getObjectsViewIcon(viewType), []),
                value: viewType,
                label: useMemo(() => getObjectsViewLabel(viewType) ?? "", []),
            }}
            onSelect={() => setViewType(viewType)}
            isSelected={selectedViewType === viewType}
        />
    ))

    const tabStackTabs = viewTypes.map((viewType) => (
        <Tab
            key={viewType}
            iconInReactNode={getObjectsViewIcon(viewType)}
            isPressed={selectedViewType === viewType}
            onClick={() => setViewType(viewType)}
        >
            {isDesktop && getObjectsViewLabel(viewType)}
        </Tab>
    ))

    const showSelect = isDesktop ? viewTypes.length > 4 : viewTypes.length > 2

    return (
        <div className={styles.base}>
            {showSelect ? (
                <Select.Root>
                    <Select.Input
                        iconInReactNode={selectedViewTypeIcon}
                        text={selectedViewTypeLabel}
                        placeholder="Select view"
                        showLabel={isDesktop}
                        className={styles.droplist}
                        isTabbable={true}
                        size={isDesktop ? "sm" : "md"}
                    />
                    <Select.List className={styles.droplist}>
                        <Select.ListItemStack>{listItems}</Select.ListItemStack>
                    </Select.List>
                </Select.Root>
            ) : (
                <TabStack attached={true} size="sm">
                    {tabStackTabs}
                </TabStack>
            )}
        </div>
    )
}
