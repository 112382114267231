import useUser from "@hooks/useUser"

import useJobTimelineViewBryntumInstances from "./useJobTimelineViewBryntumInstances"
import useJobTimelineViewStates from "./useJobTimelineViewStates"
import useJobTimelineViewTimeRanges from "./useJobTimelineViewTimeRanges"

export default function useJobTimelineViewSchedulerConfig() {
    const { user } = useUser()

    const { schedulerPro } = useJobTimelineViewBryntumInstances()

    const { getTimeRanges } = useJobTimelineViewTimeRanges()

    const { currentDate } = useJobTimelineViewStates()

    const configureSchedulerTimezone = () => {
        return new Promise<void>((resolve) => {
            if (user && schedulerPro.current) {
                schedulerPro.current.instance.timeZone = user.service_company.preferred_timezone

                void schedulerPro.current.instance.project.commitAsync().then(() => {
                    resolve()
                })
            }
        })
    }

    const configureSchedulerDates = () => {
        const timeRange = getTimeRanges(currentDate)

        if (schedulerPro.current) {
            schedulerPro.current.instance.setStartDate(timeRange?.midnight)
            schedulerPro.current.instance.setEndDate(timeRange?.nextDayAtMidnight)
        }
    }

    return {
        configureSchedulerTimezone,
        configureSchedulerDates,
    }
}
