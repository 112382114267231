import { forwardRef, useImperativeHandle, useRef } from "react"

import toast from "@molecules/Toast/Toast"

import { IMAGE_FORMATS_ACCEPTED } from "@constants/avatarImageLimits"

import styles from "./HiddenImageInput.module.scss"
import { HiddenImageInputProps, HiddenImageInputRef, ImageDimensions } from "./HiddenImageInput.types"

function HiddenImageInput(props: HiddenImageInputProps, ref: React.Ref<HiddenImageInputRef>) {
    const { onFileChange, ...rest } = props

    const fileInputRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(
        ref,
        () =>
            ({
                selectImageFromOS: () => {
                    fileInputRef.current?.click()
                },
                resetInput: () => {
                    if (fileInputRef.current) {
                        fileInputRef.current.value = ""
                    }
                },
            }) as HiddenImageInputRef,
        [],
    )

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0]
            const img = new Image()
            const objectURL = URL.createObjectURL(file)
            const imageLoad = new Promise<ImageDimensions>((resolve, reject) => {
                img.onload = () => {
                    resolve({ width: img.width, height: img.height })
                    URL.revokeObjectURL(objectURL)
                }
                img.onerror = () => {
                    reject()
                    URL.revokeObjectURL(objectURL)
                }
                img.src = objectURL
            })

            try {
                const imageDimensions = await imageLoad

                onFileChange(file, imageDimensions)
            } catch (error) {
                toast({
                    type: "error",
                    size: "md",
                    title: "Could not upload image",
                })
            }
        }
    }

    return (
        <input
            type="file"
            accept={IMAGE_FORMATS_ACCEPTED.join(",")}
            ref={fileInputRef}
            className={styles.base}
            onChange={handleFileChange as unknown as () => void}
            {...rest}
        />
    )
}

export default forwardRef(HiddenImageInput)
