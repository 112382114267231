import clsx from "clsx"
import { useMemo } from "react"
import zxcvbn from "zxcvbn"

import styles from "./PasswordStrengthIndicator.module.scss"
import { PasswordStrengthIndicatorProps } from "./PasswordStrengthIndicator.types"

export default function PasswordStrengthIndicator(props: PasswordStrengthIndicatorProps) {
    const { password, size } = props

    const result = useMemo(() => (password !== "" ? zxcvbn(password) : null), [password])

    const strengthLabels = ["Weak", "Weak", "Average", "Strong", "Excellent"]
    const strengthLabel = result !== null ? strengthLabels[result.score] : "Strength"

    const strengthClasses = [styles.weak, styles.weak, styles.average, styles.strong, styles.excellent]
    const strengthClass = result !== null ? strengthClasses[result.score] : ""

    return (
        <div
            className={clsx(styles.base, strengthClass, {
                [styles.sizeSm]: size === "sm",
                [styles.sizeMd]: size === "md",
            })}
            aria-valuenow={result?.score}
            aria-valuemax={4}
            aria-valuemin={0}
            role="progressbar"
            aria-label={strengthLabel}
        >
            <div className={styles.strength}>{strengthLabel}</div>
            <div className={styles.indicator}>
                <div className={styles.ellipse} />
                <div className={styles.ellipse} />
                <div className={styles.ellipse} />
                <div className={styles.ellipse} />
                <div className={styles.ellipse} />
                <div className={styles.ellipse} />
                <div className={styles.ellipse} />
                <div className={styles.ellipse} />
            </div>
        </div>
    )
}
