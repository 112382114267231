import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useIsDesktop from "@hooks/useIsDesktop"

import { IconButton, Tooltip } from "@atoms/index"

import { Slider } from "@molecules/index"

import useJobTimelineViewStates from "@organisms/ObjectsView/JobTimelineView/hooks/useJobTimelineViewStates"

import { MAXIMUM_ZOOM, MINIMUM_ZOOM } from "@constants/schedulerZoom"

import styles from "./JobTimelineViewZoom.module.scss"

export default function JobTimelineViewZoom() {
    const { schedulerZoom, setSchedulerZoom } = useJobTimelineViewStates()

    const isDesktop = useIsDesktop()

    const isAtMinimumZoom = schedulerZoom === MINIMUM_ZOOM
    const isAtMaximumZoom = schedulerZoom === MAXIMUM_ZOOM

    const adjustZoom = (step: number) => {
        const newZoom = schedulerZoom + step
        if (newZoom >= MINIMUM_ZOOM && newZoom <= MAXIMUM_ZOOM) {
            void setSchedulerZoom(newZoom)
        }
    }

    const zoomIn = () => {
        adjustZoom(2)
    }

    const zoomOut = () => {
        adjustZoom(-2)
    }

    if (!isDesktop) {
        return null
    } else {
        return (
            <div className={styles.base}>
                <Tooltip label="Zoom out">
                    <IconButton
                        icon={icon({
                            name: "magnifying-glass-minus",
                            style: "regular",
                            family: "sharp",
                        })}
                        colorScheme="gray"
                        size="sm"
                        variant="ghost"
                        isDisabled={isAtMinimumZoom}
                        onClick={zoomOut}
                        noDebounce={true}
                    />
                </Tooltip>

                <Slider
                    className={styles.slider}
                    value={[schedulerZoom]}
                    max={60}
                    min={32}
                    step={2}
                    aria-labelledby="Zoom"
                    onValueChange={(value) => void setSchedulerZoom(value[0])}
                />

                <Tooltip label="Zoom in">
                    <IconButton
                        icon={icon({
                            name: "magnifying-glass-plus",
                            style: "regular",
                            family: "sharp",
                        })}
                        colorScheme="gray"
                        size="sm"
                        variant="ghost"
                        isDisabled={isAtMaximumZoom}
                        noDebounce={true}
                        onClick={zoomIn}
                    />
                </Tooltip>
            </div>
        )
    }
}
