import { InvalidateQueryFilters, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useEffect } from "react"

import toast from "@molecules/Toast/Toast"

import { NOTIFICATION_ENDPOINTS } from "@endpoints/notification"

import {
    NotificationPreferenceTopics,
    NotificationTopicsMap,
    NotificationTopicsType,
    NotificationTopicsValue,
} from "./SettingsNotification.types"
import { patchNotificationPreference } from "./SettingsNotifications.utils"

export const notificationPreferenceQueryKey = [
    "list-notification-methods",
    NOTIFICATION_ENDPOINTS.RETRIEVE_NOTIFICATION_PREFERENCES,
]

const useNotificationPreferencesQuery = <T extends NotificationTopicsValue | undefined>(
    topic?: NotificationTopicsType,
): {
    isLoading: boolean
    topicPreference: T
    allTopics: NotificationTopicsMap
} => {
    const { data, isError, isLoading } = useQuery({
        queryKey: notificationPreferenceQueryKey,
        queryFn: async (): Promise<NotificationPreferenceTopics> => {
            const response = await fetch(NOTIFICATION_ENDPOINTS.RETRIEVE_NOTIFICATION_PREFERENCES)
            return (await response.json()) as NotificationPreferenceTopics
        },
    })

    const notificationTopicsTypes: NotificationTopicsMap = {
        inventory: {
            enable_inventory_notification_low_inventory: data?.enable_inventory_notification_low_inventory ?? false,
        },
        jobs: {
            assignment: {
                enable_job_notification_job_assigned: data?.enable_job_notification_job_assigned ?? false,
                enable_job_notification_job_unassigned: data?.enable_job_notification_job_unassigned ?? false,
                enable_job_notification_job_updated: data?.enable_job_notification_job_updated ?? false,
                enable_job_notification_job_rescheduled: data?.enable_job_notification_job_rescheduled ?? false,
                enable_job_notification_job_unscheduled: data?.enable_job_notification_job_unscheduled ?? false,
                enable_job_notification_job_cancelled: data?.enable_job_notification_job_cancelled ?? false,
            },
            manager: {
                enable_job_notification_new_job_request: data?.enable_job_notification_new_job_request ?? false,
                enable_job_notification_job_started: data?.enable_job_notification_job_started ?? false,
                enable_job_notification_job_paused: data?.enable_job_notification_job_paused ?? false,
                enable_job_notification_job_placed_on_hold: data?.enable_job_notification_job_placed_on_hold ?? false,
                enable_job_notification_job_resumed: data?.enable_job_notification_job_resumed ?? false,
                enable_job_notification_job_completed: data?.enable_job_notification_job_completed ?? false,
                enable_job_notification_job_summary_updated:
                    data?.enable_job_notification_job_summary_updated ?? false,
            },
        },
        estimates: {
            enable_estimate_notification_estimate_approved:
                data?.enable_estimate_notification_estimate_approved ?? false,
            enable_estimate_notification_estimate_declined:
                data?.enable_estimate_notification_estimate_declined ?? false,
        },
    }

    useEffect(() => {
        if (isError) {
            toast({
                type: "error",
                size: "md",
                title: "Could not retrieve notification preference",
            })
        }
    }, [isError])

    return {
        topicPreference: (topic ? notificationTopicsTypes[topic] : undefined) as T,
        allTopics: notificationTopicsTypes,
        isLoading,
    }
}

const useNotificationPreferencesMutation = () => {
    const client = useQueryClient()

    const { mutate, isError } = useMutation<NotificationPreferenceTopics, Error, NotificationPreferenceTopics>({
        mutationFn: patchNotificationPreference,
        onSuccess: () => {
            void client.invalidateQueries([notificationPreferenceQueryKey] as InvalidateQueryFilters)
        },
    })

    useEffect(() => {
        if (isError) {
            toast({
                type: "error",
                size: "md",
                title: "Could not update notification preference",
            })
        }
    }, [isError])

    return { mutate, isError }
}

export { useNotificationPreferencesMutation, useNotificationPreferencesQuery }
