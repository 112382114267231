// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OTPInput-base__hBj4H{display:flex;gap:var(--size-2);align-items:flex-start;width:var(--size-full);max-width:var(--size-96);padding:var(--size-0);border-radius:var(--border-radius-0)}@media only screen and (min-width: 64rem){.OTPInput-base__hBj4H{max-width:none;max-width:initial}}.OTPInput-input__UMIJf:disabled{cursor:not-allowed}.OTPInput-input__UMIJf{width:var(--size-full) !important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "OTPInput-base__hBj4H",
	"input": "OTPInput-input__UMIJf"
};
export default ___CSS_LOADER_EXPORT___;
