import { DateHelper } from "@bryntum/schedulerpro"

import useUser from "@hooks/useUser"

import useJobTimelineViewBryntumInstances from "./useJobTimelineViewBryntumInstances"
import useJobTimelineViewStates from "./useJobTimelineViewStates"

export default function useJobTimelineViewNavigation() {
    const { user } = useUser()

    const { schedulerPro } = useJobTimelineViewBryntumInstances()

    const { todayInUserTimezone, currentDate } = useJobTimelineViewStates()

    const scrollToToday = () => {
        if (schedulerPro.current) {
            void schedulerPro.current.instance.scrollToDate(todayInUserTimezone, {
                block: "center",
                animate: true,
            })
        }
    }

    const scrollToStartOfWorkingDay = () => {
        if (user && schedulerPro.current) {
            const startOfDayStr = user.service_company.start_of_day
            const [startHour, startMinute] = startOfDayStr.split(":")
            const startOfDay = currentDate
            startOfDay.setHours(Number(startHour), Number(startMinute), 0, 0)

            void schedulerPro.current.instance.scrollToDate(startOfDay, {
                block: "center",
                animate: true,
            })
        }
    }

    const handleScrollPosition = () => {
        const isToday = DateHelper.isEqual(new Date(currentDate), new Date(todayInUserTimezone), "day")

        if (isToday) {
            void scrollToToday()
        } else {
            void scrollToStartOfWorkingDay()
        }
    }

    return { scrollToToday, scrollToStartOfWorkingDay, handleScrollPosition }
}
