import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"

import useIsDesktop from "@hooks/useIsDesktop"
import useRouter from "@hooks/useRouter"

import { IconButton } from "@atoms"

import styles from "./TopBarPageSubtitle.module.scss"
import { TopBarPageSubtitleProps } from "./TopBarPageSubtitle.types"

export default function TopBarPageSubtitle(props: TopBarPageSubtitleProps) {
    const { backDestination, label } = props

    const isDesktop = useIsDesktop()
    const { push } = useRouter()

    const onClick = () => {
        if (backDestination) {
            push(backDestination)
        }
    }

    return (
        <div
            className={clsx(styles.base, {
                [styles.noBackButton]: !backDestination,
            })}
        >
            {backDestination && (
                <IconButton
                    colorScheme="gray"
                    size={isDesktop ? "sm" : "md"}
                    variant="ghost"
                    className="back-button"
                    icon={icon({ name: "angle-left", style: "regular", family: "sharp" })}
                    onClick={onClick}
                    aria-label="Navigate back"
                    noDebounce={true}
                />
            )}
            <span className={`${styles.subtitle} page-subtitle`}>{label}</span>
        </div>
    )
}
