import { useEffect, useState } from "react"

export default function useLocalStorage<T>(key: string, initialValue: T, overrideValue?: T, ready = true) {
    const initializeValue = () => {
        if (!ready) {
            return undefined
        }

        if (typeof window === "undefined") {
            return overrideValue ?? initialValue
        }

        if (overrideValue !== null && overrideValue !== undefined) {
            return overrideValue
        }

        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key)

            // Parse stored json or if none return initialValue
            return item ? (JSON.parse(item) as T) : initialValue
        } catch (error) {
            // If error also return initialValue
            console.log(error)
            return overrideValue ?? initialValue
        }
    }

    // https://usehooks.com/useLocalStorage/
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(initializeValue as () => T)

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: T | ((val: T) => T)) => {
        if (ready) {
            try {
                // Allow value to be a function so we have same API as useState
                const valueToStore = value instanceof Function ? value(storedValue) : value

                // Save state
                setStoredValue(valueToStore)

                // Save to local storage
                if (typeof window !== "undefined") {
                    window.localStorage.setItem(key, JSON.stringify(valueToStore))
                }
            } catch (error) {
                // A more advanced implementation would handle the error case
                console.log(error)
            }
        }
    }

    useEffect(() => {
        if (overrideValue !== null && overrideValue !== undefined) {
            setValue(overrideValue)
        }
    }, [overrideValue])

    useEffect(() => {
        const newValue = initializeValue()
        setValue(newValue as T)
    }, [key])

    return [storedValue, setValue] as const
}
