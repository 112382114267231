import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import PurchaseOrderListCard from "@legacy/purchase_orders/components/PurchaseOrderListCard"
import { useEffect, useMemo } from "react"

import useIsDesktop from "@hooks/useIsDesktop"
import useLocalStorage from "@hooks/useLocalStorage"
import useSlugExtractor from "@hooks/useSlugExtractor"

import extractURLSearchParam from "@utils/extractURLSearchParam"

import { ObjectsView, TopBar } from "@organisms"
import { ObjectsViewType } from "@organisms/ObjectsView/ObjectsView.types"

import { AppFrame } from "@templates"

import { PURCHASE_ORDER_ROUTES } from "@routes/purchase-orders"

import {
    LIST_SEARCH_PARAM_ACTIVE_TAB,
    LIST_SEARCH_PARAM_KEYWORD_SEARCH,
    LIST_SEARCH_PARAM_PHASE,
} from "@constants/searchParams"
import {
    PURCHASE_ORDERS_LIST_CONTROLS_ACTIVE_TAB,
    PURCHASE_ORDERS_LIST_CONTROLS_KEYWORD_SEARCH,
    PURCHASE_ORDERS_LIST_CONTROLS_ORDERING,
    PURCHASE_ORDERS_LIST_CONTROLS_PHASE,
    PURCHASE_ORDERS_LIST_CONTROLS_VIEW,
} from "@constants/storage"

import usePurchaseOrderTabs from "./usePurchaseOrderTabs"

export default function PurchaseOrderList() {
    const isDesktop = useIsDesktop()

    const [createRoute] = useSlugExtractor([PURCHASE_ORDER_ROUTES.CREATE])
    const [openTabs, closedTabs] = usePurchaseOrderTabs()

    const phaseOverride = useMemo(() => extractURLSearchParam(LIST_SEARCH_PARAM_PHASE) as ObjectPhase, [])
    const activeTabOverride = useMemo(() => extractURLSearchParam(LIST_SEARCH_PARAM_ACTIVE_TAB), [])
    const searchKeywordsOverride = useMemo(() => extractURLSearchParam(LIST_SEARCH_PARAM_KEYWORD_SEARCH), [])

    const [viewType, setViewType] = useLocalStorage<ObjectsViewType>(PURCHASE_ORDERS_LIST_CONTROLS_VIEW, "table")
    const [phase, setPhase] = useLocalStorage<ObjectPhase>(PURCHASE_ORDERS_LIST_CONTROLS_PHASE, "open", phaseOverride)
    const [activeTab, setActiveTab] = useLocalStorage<string>(
        PURCHASE_ORDERS_LIST_CONTROLS_ACTIVE_TAB,
        "",
        activeTabOverride,
    )
    const [searchKeywords, setSearchKeywords] = useLocalStorage<string>(
        PURCHASE_ORDERS_LIST_CONTROLS_KEYWORD_SEARCH,
        "",
        searchKeywordsOverride,
    )

    const onShowOpen = () => {
        setPhase("open")
        setActiveTab(openTabs[0].title)
    }

    const onShowClosed = () => {
        setPhase("closed")
        setActiveTab(closedTabs[0].title)
    }

    const tabs = phase === "open" ? openTabs : closedTabs

    useEffect(() => {
        const isAnyTabAvailable = tabs && tabs.length > 0

        if (isAnyTabAvailable && !activeTab) {
            setActiveTab(tabs[0].title)
        }
    }, [tabs, activeTab])

    return (
        <AppFrame.Root pageId="PurchaseOrderList">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle
                            label="Purchase Orders"
                            icon={icon({ name: "basket-shopping", style: "regular", family: "sharp" })}
                            iconActive={icon({ name: "basket-shopping", style: "solid", family: "sharp" })}
                        />
                    </TopBar.TopBarHeaderStart>
                    <TopBar.TopBarHeaderEnd>
                        <TopBar.PagePresence />
                        <TopBar.SearchButton scope="Purchase Orders" />
                        <TopBar.ViewSelector
                            viewTypes={["table", "card"]}
                            selectedViewType={viewType}
                            setViewType={setViewType}
                        />
                        <TopBar.CTAButton destination={createRoute}>{isDesktop ? "New Order" : null}</TopBar.CTAButton>
                    </TopBar.TopBarHeaderEnd>
                </TopBar.Header>
            </TopBar.Root>
            <ObjectsView
                viewType={viewType}
                searchKeywords={searchKeywords}
                setActiveTab={setActiveTab}
                setSearchKeywords={setSearchKeywords}
                onShowOpen={onShowOpen}
                onShowClosed={onShowClosed}
                phase={phase}
                orderingKey={PURCHASE_ORDERS_LIST_CONTROLS_ORDERING}
                tabs={tabs}
                activeTab={activeTab}
                CardComponent={PurchaseOrderListCard}
                listTitle="Purchase Orders"
                objectCreateRoute={createRoute}
                objectType="purchase_order"
            />
        </AppFrame.Root>
    )
}
