import Banner from "@legacy/core/components/Banner";
import Spinner from "@legacy/core/components/Spinner";
import { Component, Fragment } from "react";
import deepcopy from "rfdc";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import HorizontalLayout from "../../core/components/HorizontalLayout";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import BasicSelectField from "../../core/fields/BasicSelectField";
import CharField from "../../core/fields/CharField";
import DurationField from "../../core/fields/DurationField";
import ListSelectField from "../../core/fields/ListSelectField";
import PercentField from "../../core/fields/PercentField";
import PriceField from "../../core/fields/PriceField";
import SwitchField from "../../core/fields/SwitchField";
import TagField from "../../core/fields/TagField";
import TextField from "../../core/fields/TextField";
import TagSearchInput from "../../core/inputs/TagSearchInput";
import { LineItemUnitTypes, PriceBookItemTypes } from "../../core/utils/enums";
import { formatLocalTime, getDefaultTaxable, valueIsDefined } from "../../core/utils/utils";
import InventoryLevelListEditSelect from "../../inventory/components/InventoryLevelListEditSelect";
import VendorPartCostListEditSelect from "../components/InlineVendorPartCostListEditSelect";
import PriceBookItemFeedAccordion from "../components/PriceBookItemFeedAccordion";
import PriceBookItemUsageHistoryAccordion from "../components/PriceBookItemUsageHistoryAccordion";

const FORM_MODE_CAPTIONS = {
    ADD_PRICEBOOKITEM: "Please provide the following information to create a PriceBook item.",
    EDIT_PRICEBOOKITEM: "Use the fields below to edit this PriceBook item. Note that changes made here will not be reflected on existing Estimates, Jobs, or Invoices.",
    REVIEW_PRICEBOOKITEM: "Please review the details of this PriceBook item and choose to either confirm or delete it. Changing or deleting this item does not affect existing Estimates, Jobs, or Invoices.",
    ADD_PRICEBOOKITEM_SERVICE: "Please provide the following information to create a PriceBook item.",
    EDIT_PRICEBOOKITEM_SERVICE: "Use the fields below to edit this PriceBook item. Note that changes made here will not be reflected on existing Estimates, Jobs, or Invoices.",
    ADD_PRICEBOOKITEM_TAX: "Please provide the following information to create a PriceBook item.",
    EDIT_PRICEBOOKITEM_TAX: "Use the fields below to edit this PriceBook item. Note that changes made here will not be reflected on existing Estimates, Jobs, or Invoices.",
}

const PRICEBOOK_ITEM_TYPES = [
    {"value": "", "label": "Choose PriceBook item type..."},
    {"value": PriceBookItemTypes.service, "label": "Service Charge"},
    {"value": PriceBookItemTypes.part, "label": "Part or Material"},
    {"value": PriceBookItemTypes.other, "label": "Miscellaneous Charge"},
    {"value": PriceBookItemTypes.discount, "label": "Discount"},
]

const PRICEBOOK_ITEM_TYPES_WITH_TAXES = [
    ...PRICEBOOK_ITEM_TYPES,
    {"value": PriceBookItemTypes.tax, "label": "Tax"},
]

const DESCRIPTION_PLACEHOLDERS = {
    [PriceBookItemTypes.service]: "Name used to describe this Service",
    [PriceBookItemTypes.part]: "Name used to describe this Part or Material",
    [PriceBookItemTypes.other]: "Name used to describe this Misc. Charge",
    [PriceBookItemTypes.discount]: "Name used to describe this Discount",
    [PriceBookItemTypes.tax]: "Name used to describe this Tax",
}

const SUMMARY_PLACEHOLDERS = {
    [PriceBookItemTypes.service]: "Summary specific to this Service Charge. e.g. 'Replaced all filters.' or 'Cleaned evaporator coils/condensers'.",
    [PriceBookItemTypes.part]: "Summary specific to this Part or Material. e.g. 'R134 Freon.' or 'Turbo Air - 30200R0100 - Air Compressor'.",
    [PriceBookItemTypes.other]: "Summary specific to this Misc. Charge. e.g. '30+ mile trip/gas charge' or 'Standard diagnostic fee for new customers'.",
    [PriceBookItemTypes.discount]: "Summary specific to this Discount. e.g. 'Thank you for your service, we hope you enjoy this Veteran's Discount'.",
    [PriceBookItemTypes.tax]: "Summary specific to this Tax. e.g. 'Standard Sales Tax on purchased parts in San Luis Obispo County'.",
}


class PriceBookItemForm extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const {
            mode,
            submitting,
            priceBookItem,
            errors,
            onFormDataChange,
            requestAction,
            switchToPrimaryForm,
            currencySymbol,
            showQuickBooksRevenueAccountSelect=false,
            showQuickBooksTaxAgencyVendorSelect=false,
            useTaxes=false,
            lockItemTypeSelection=false,
            pricebookDefaultTaxableService=false,
            pricebookDefaultTaxablePart=false,
            pricebookDefaultTaxableOther=false,
            returnScroll
        } = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return (
                    <div className="data-panel__action-feedback">
                        <Spinner centered={true} />
                    </div>
                )
            }
            else {
                if (mode === "ADD_PRICEBOOKITEM") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Create" handler={event => requestAction("PRICEBOOKITEM_CREATE")} />
                                <UniversalButton type="secondary" text="Cancel" handler={event => requestAction("PRICEBOOKITEM_CANCEL_CREATE")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (mode === "EDIT_PRICEBOOKITEM" || mode === "REVIEW_PRICEBOOKITEM") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text={mode === "REVIEW_PRICEBOOKITEM" ? "Confirm" : "Save"} handler={event => requestAction("PRICEBOOKITEM_UPDATE")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                {mode === "REVIEW_PRICEBOOKITEM" && (
                                    <UniversalButton type="danger" text="Delete" handler={event => requestAction("PRICEBOOKITEM_DELETE")} />
                                )}
                                <UniversalButton type="secondary" text="Cancel" handler={event => requestAction("PRICEBOOKITEM_CANCEL_EDITS")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (mode === "ADD_PRICEBOOKITEM_SERVICE") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Create" handler={event => requestAction("PRICEBOOK_SERVICE_CREATE")} />
                                <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (mode === "EDIT_PRICEBOOKITEM_SERVICE") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Save" handler={event => requestAction("PRICEBOOK_SERVICE_UPDATE")} />
                                <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (mode === "ADD_PRICEBOOKITEM_TAX") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Create" handler={event => requestAction("PRICEBOOK_TAX_CREATE")} />
                                <UniversalButton type="secondary" text="Cancel" handler={event => requestAction("PRICEBOOK_TAX_CANCEL_CREATE")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (mode === "EDIT_PRICEBOOKITEM_TAX") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Save" handler={event => requestAction("PRICEBOOK_TAX_UPDATE")} />
                                <UniversalButton type="secondary" text="Cancel" handler={event => requestAction("PRICEBOOK_TAX_CANCEL_CREATE")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
            }
        }
    }

    getCaptionQuickbBooksDetails = (priceBookItem, showQuickBooksRevenueAccountSelect, showQuickBooksTaxAgencyVendorSelect) => {
        if (showQuickBooksTaxAgencyVendorSelect && priceBookItem.pricebook_item_type === PriceBookItemTypes.tax) {
            return "Note that created Taxes won't be available for use during invoice or estimate creation until they've been synced with QuickBooks."
        }
        else if (showQuickBooksRevenueAccountSelect && priceBookItem.pricebook_item_type !== undefined) {
            return "Note that created PriceBook items won't be available for use in invoice or estimate line items until they've been synced with QuickBooks."
        }
        else {
            return ""
        }
    }

    renderSyncTime = (syncTime, preferredTimezone) => {
        if (syncTime) {
            const { localDateTimeString } = formatLocalTime(syncTime, preferredTimezone, false, true, false, true)
            return localDateTimeString
        }
        else {
            return "\u2014"
        }
    }

    render() {
        const {
            mode,
            submitting,
            priceBookItem,
            errors,
            onFormDataChange,
            requestAction,
            switchToPrimaryForm,
            currencySymbol,
            showQuickBooksRevenueAccountSelect=false,
            showQuickBooksTaxAgencyVendorSelect=false,
            useTaxes=false,
            lockItemTypeSelection=false,
            pricebookDefaultTaxableService=false,
            pricebookDefaultTaxablePart=false,
            pricebookDefaultTaxableOther=false,
            returnScroll,
            isInventoryByDefault
        } = this.props

        // HorizontalLayout makes child fields width-constrained. If we're only showing the price field, use Fragment instead.
        const PriceContainer = priceBookItem.pricebook_item_type === PriceBookItemTypes.part ? HorizontalLayout : Fragment

        const newVendorPartCostExistsInList = (priceBookItem.vendor_part_costs || []).some(vendor_part_cost => vendor_part_cost.__new__ || false)

        const isEditingPricebookItem = mode === "EDIT_PRICEBOOKITEM"


        return (
            <Fragment>
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="PriceBookItem Create/Update">
                    <div className="data-panel__form" aria-label="PriceBookItem Create/Update Form">
                        <p className="data-panel__form__caption">{FORM_MODE_CAPTIONS[mode]}<br /><br />{mode.startsWith("ADD_") ? this.getCaptionQuickbBooksDetails(priceBookItem, showQuickBooksRevenueAccountSelect, showQuickBooksTaxAgencyVendorSelect) : ""}</p>
                        <SwitchField
                            fieldName="is_active"
                            fieldLabel="Active Status"
                            fieldValue={priceBookItem.is_active !== undefined ? priceBookItem.is_active === true : false}
                            fieldOnChange={is_active => onFormDataChange("is_active", is_active ? true : false)}
                            uncheckedText="Inactive"
                            checkedText="Active"
                            disabled={!isEditingPricebookItem}
                            errors={errors}
                        ></SwitchField>
                        <BasicSelectField
                            fieldName="pricebook_item_type"
                            fieldLabel="Item Type"
                            fieldValue={priceBookItem.pricebook_item_type || ""}
                            fieldOnChange={pricebook_item_type => onFormDataChange("pricebook_item_type", parseInt(pricebook_item_type) || "")}
                            choices={useTaxes ? PRICEBOOK_ITEM_TYPES_WITH_TAXES : PRICEBOOK_ITEM_TYPES}
                            disabled={mode !== "ADD_PRICEBOOKITEM" || lockItemTypeSelection || !priceBookItem.is_active}
                            errors={errors}
                        ></BasicSelectField>
                        {
                            priceBookItem.pricebook_item_type &&  (
                                <Fragment>
                                    <CharField
                                        fieldName="description"
                                        fieldLabel="Short Description"
                                        fieldValue={priceBookItem.description || ""}
                                        fieldOnChange={description => onFormDataChange("description", description || "")}
                                        maxLength={100}
                                        placeholder={DESCRIPTION_PLACEHOLDERS[priceBookItem.pricebook_item_type]}
                                        disabled={!priceBookItem.is_active}
                                        errors={errors}
                                    ></CharField>
                                    <TagField
                                        fieldName="labels"
                                        fieldLabel="Labels"
                                        inputComponent={
                                            <TagSearchInput
                                                defaultSelectedTags={priceBookItem.labels?.map(label => label.name) ?? []}
                                                onSelectionChange={labelNames => onFormDataChange("labels", labelNames ? labelNames.map(labelName => ({"name": labelName})) : [])}
                                                choicesEndpoint={DjangoUrls["pricebook:api-pricebookitem-labels-list"](window.MARKETPLACE_ENTITY_SLUG)}
                                                placeholder="Add Labels..."
                                                noOptionsMessage="No Labels Found"
                                                isMulti={true}
                                                disabled={!priceBookItem.is_active}
                                                extraFilters={[["pricebook_item_type", priceBookItem.pricebook_item_type]]}
                                            ></TagSearchInput>
                                        }
                                        optional={true}
                                        errors={errors}
                                    ></TagField>
                                    {
                                        priceBookItem.pricebook_item_type === PriceBookItemTypes.part && window.FEATURE_INVENTORY_ENABLED === true && (
                                            <SwitchField
                                                fieldName="track_inventory"
                                                fieldLabel="Part Type"
                                                fieldValue={priceBookItem.track_inventory}
                                                fieldOnChange={track_inventory => onFormDataChange("track_inventory", track_inventory)}
                                                uncheckedText="Non-Inventory"
                                                checkedText="Inventory"
                                                disabled={!priceBookItem.is_active || isEditingPricebookItem && isInventoryByDefault}
                                                errors={errors}
                                            ></SwitchField>
                                        )
                                    }
                                    {isEditingPricebookItem && isInventoryByDefault && (
                                        <Banner
                                            type="warning"
                                            text={"Your connected accounting system does not support changing a part from inventory to non-inventory. Please deactivate this part and create another if you wish to switch to non-inventory."}
                                            extraMargin={true}
                                        />
                                    )}
                                    {
                                        priceBookItem.pricebook_item_type === PriceBookItemTypes.service && (
                                            <SwitchField
                                                fieldName="default_unit_type"
                                                fieldLabel="Default Unit Type"
                                                fieldValue={priceBookItem.default_unit_type !== undefined ? priceBookItem.default_unit_type === LineItemUnitTypes.flat_rate : false}
                                                fieldOnChange={default_unit_type => onFormDataChange("default_unit_type", default_unit_type ? LineItemUnitTypes.flat_rate : LineItemUnitTypes.hourly)}
                                                uncheckedText="Hourly"
                                                checkedText="Flat Rate"
                                                disabled={!priceBookItem.is_active}
                                                errors={errors}
                                            ></SwitchField>
                                        )
                                    }
                                    {
                                        priceBookItem.pricebook_item_type === PriceBookItemTypes.tax
                                        ?
                                        <PercentField
                                            fieldName="default_price"
                                            fieldLabel="Tax Percentage"
                                            fieldValue={priceBookItem.default_price || ""}
                                            fieldOnChange={default_price => onFormDataChange("default_price", default_price || 0)}
                                            disabled={!priceBookItem.is_active}
                                            errors={errors}
                                        ></PercentField>
                                        :
                                        <PriceContainer>
                                            {
                                                priceBookItem.pricebook_item_type === PriceBookItemTypes.part && (
                                                    <PriceField
                                                        fieldName="cost"
                                                        fieldLabel="Unit Cost"
                                                        fieldValue={priceBookItem.cost || ""}
                                                        fieldOnChange={cost => onFormDataChange("cost", cost || 0)}
                                                        currencySymbol={currencySymbol}
                                                        disabled={!priceBookItem.is_active}
                                                        optional={true}
                                                        errors={errors}
                                                    ></PriceField>
                                                )
                                            }
                                            <PriceField
                                                fieldName="default_price"
                                                fieldLabel={`Default ${(priceBookItem.pricebook_item_type === PriceBookItemTypes.service && priceBookItem.default_unit_type === LineItemUnitTypes.hourly) ? "Rate" : "Unit Price"}`}
                                                fieldValue={priceBookItem.default_price || ""}
                                                fieldOnChange={default_price => onFormDataChange("default_price", default_price || 0)}
                                                currencySymbol={currencySymbol}
                                                credit={priceBookItem.pricebook_item_type === PriceBookItemTypes.discount}
                                                disabled={!priceBookItem.is_active}
                                                errors={errors}
                                            ></PriceField>
                                        </PriceContainer>
                                    }
                                    {
                                        useTaxes && priceBookItem.pricebook_item_type !== PriceBookItemTypes.discount && priceBookItem.pricebook_item_type !== PriceBookItemTypes.tax && (
                                            <SwitchField
                                                fieldName="default_is_taxable"
                                                fieldLabel="Tax"
                                                fieldValue={valueIsDefined(priceBookItem.default_is_taxable) ? priceBookItem.default_is_taxable : getDefaultTaxable(priceBookItem, pricebookDefaultTaxableService, pricebookDefaultTaxablePart, pricebookDefaultTaxableOther)}
                                                fieldOnChange={default_is_taxable => onFormDataChange("default_is_taxable", default_is_taxable)}
                                                uncheckedText="Non-Taxable"
                                                checkedText="Taxable"
                                                disabled={!priceBookItem.is_active}
                                                errors={errors}
                                            ></SwitchField>
                                        )
                                    }
                                    {
                                        priceBookItem.pricebook_item_type === PriceBookItemTypes.service && (
                                            <DurationField
                                                fieldName="default_job_duration"
                                                fieldLabel="Estimated Job Duration"
                                                fieldValue={priceBookItem.default_job_duration || ""}
                                                fieldOnChange={default_job_duration => onFormDataChange("default_job_duration", default_job_duration || null)}
                                                unit="hours"
                                                unitMultiplier={3600}
                                                disabled={!priceBookItem.is_active}
                                                optional={true}
                                                errors={errors}
                                            ></DurationField>
                                        )
                                    }
                                    {
                                        priceBookItem.pricebook_item_type === PriceBookItemTypes.part && (
                                            <Fragment>
                                                <CharField
                                                    fieldName="manufacturer"
                                                    fieldLabel="Manufacturer"
                                                    fieldValue={priceBookItem.manufacturer || ""}
                                                    fieldOnChange={manufacturer => onFormDataChange("manufacturer", manufacturer || "")}
                                                    maxLength={100}
                                                    disabled={!priceBookItem.is_active}
                                                    optional={true}
                                                    errors={errors}
                                                ></CharField>
                                                <CharField
                                                    fieldName="part_number"
                                                    fieldLabel="Manufacturer Part Number"
                                                    fieldValue={priceBookItem.part_number || ""}
                                                    fieldOnChange={part_number => onFormDataChange("part_number", part_number || "")}
                                                    maxLength={100}
                                                    disabled={!priceBookItem.is_active}
                                                    optional={true}
                                                    errors={errors}
                                                ></CharField>
                                            </Fragment>
                                        )
                                    }
                                    <TextField
                                        fieldName="summary_template"
                                        fieldLabel={priceBookItem.pricebook_item_type === PriceBookItemTypes.service ? "Service Summary Template" : "Detailed Summary Template"}
                                        fieldValue={priceBookItem.summary_template || ""}
                                        fieldOnChange={summary_template => onFormDataChange("summary_template", summary_template || "")}
                                        placeholder={SUMMARY_PLACEHOLDERS[priceBookItem.pricebook_item_type]}
                                        rows={3}
                                        disabled={!priceBookItem.is_active}
                                        optional={true}
                                        errors={errors}
                                    ></TextField>
                                    <TextField
                                        fieldName="notes"
                                        fieldLabel="Internal Notes"
                                        fieldValue={priceBookItem.notes || ""}
                                        fieldOnChange={notes => onFormDataChange("notes", notes || "")}
                                        rows={3}
                                        disabled={!priceBookItem.is_active}
                                        optional={true}
                                        errors={errors}
                                    ></TextField>
                                    {
                                        showQuickBooksRevenueAccountSelect && priceBookItem.pricebook_item_type !== PriceBookItemTypes.tax && (
                                            <BasicSelectField
                                                fieldName="quickbooks_desktop_revenue_account_id"
                                                fieldLabel="QuickBooks Revenue Account"
                                                fieldValue={priceBookItem.quickbooks_desktop_revenue_account_id || ""}
                                                fieldOnChange={quickbooks_desktop_revenue_account_id => onFormDataChange("quickbooks_desktop_revenue_account_id", quickbooks_desktop_revenue_account_id || "")}
                                                choices={window.QUICKBOOKS_DESKTOP_REVENUE_ACCOUNTS}
                                                disabled={!priceBookItem.is_active}
                                                errors={errors}
                                            ></BasicSelectField>
                                        )
                                    }
                                    {
                                        showQuickBooksRevenueAccountSelect && priceBookItem.pricebook_item_type === PriceBookItemTypes.part && (
                                            <Fragment>
                                                <BasicSelectField
                                                    fieldName="quickbooks_desktop_cogs_account_id"
                                                    fieldLabel="QuickBooks Cost of Goods Account"
                                                    fieldValue={priceBookItem.quickbooks_desktop_cogs_account_id|| ""}
                                                    fieldOnChange={quickbooks_desktop_cogs_account_id => onFormDataChange("quickbooks_desktop_cogs_account_id", quickbooks_desktop_cogs_account_id || "")}
                                                    choices={window.QUICKBOOKS_DESKTOP_COGS_ACCOUNTS}
                                                    disabled={!priceBookItem.is_active}
                                                    errors={errors}
                                                ></BasicSelectField>
                                                <BasicSelectField
                                                    fieldName="quickbooks_desktop_inventory_asset_account_id"
                                                    fieldLabel="QuickBooks Inventory Asset Account"
                                                    fieldValue={priceBookItem.quickbooks_desktop_inventory_asset_account_id || ""}
                                                    fieldOnChange={quickbooks_desktop_inventory_asset_account_id => onFormDataChange("quickbooks_desktop_inventory_asset_account_id", quickbooks_desktop_inventory_asset_account_id || "")}
                                                    choices={window.QUICKBOOKS_DESKTOP_INVENTROY_ASSET_ACCOUNTS}
                                                    disabled={!priceBookItem.is_active}
                                                    errors={errors}
                                                ></BasicSelectField>
                                            </Fragment>
                                        )
                                    }
                                    {
                                        showQuickBooksTaxAgencyVendorSelect && priceBookItem.pricebook_item_type === PriceBookItemTypes.tax && (
                                            <BasicSelectField
                                                fieldName="quickbooks_desktop_tax_agency_vendor_id"
                                                fieldLabel="QuickBooks Tax Collection Agency"
                                                fieldValue={priceBookItem.quickbooks_desktop_tax_agency_vendor_id || ""}
                                                fieldOnChange={quickbooks_desktop_tax_agency_vendor_id => onFormDataChange("quickbooks_desktop_tax_agency_vendor_id", quickbooks_desktop_tax_agency_vendor_id || "")}
                                                choices={window.QUICKBOOKS_DESKTOP_VENDORS}
                                                disabled={!priceBookItem.is_active}
                                                errors={errors}
                                            ></BasicSelectField>
                                        )
                                    }
                                    {
                                        priceBookItem.pricebook_item_type === PriceBookItemTypes.part && (
                                            <ListSelectField
                                                fieldName="vendor_part_costs"
                                                fieldLabel="Vendor Part Information"
                                                inputComponent={<VendorPartCostListEditSelect fieldName="vendor_part_costs" vendorPartCosts={priceBookItem.vendor_part_costs || []} preferredVendorId={priceBookItem.preferred_vendor} updateVendorPartCosts={vendor_part_costs => onFormDataChange("vendor_part_costs", vendor_part_costs)} currencySymbol={currencySymbol} />}
                                                showButton={!newVendorPartCostExistsInList && priceBookItem.is_active}
                                                buttonLabel="Add Vendor Cost"
                                                buttonAction={event => {
                                                    let newVendorPartCosts = deepcopy()(priceBookItem.vendor_part_costs || [])
                                                    newVendorPartCosts.push({
                                                        "id": null,
                                                        "name": "",
                                                        "phone": "",
                                                        "phone_extension": "",
                                                        "email": "",
                                                        "primary": false,
                                                        "__new__": true
                                                    })
                                                    onFormDataChange("vendor_part_costs", newVendorPartCosts)
                                                }}
                                                errors={errors}
                                            ></ListSelectField>
                                        )
                                    }
                                    {
                                        priceBookItem.pricebook_item_type === PriceBookItemTypes.part && priceBookItem.track_inventory && (
                                            <ListSelectField
                                                fieldName="inventory_levels"
                                                fieldLabel="Inventory Levels"
                                                inputComponent={<InventoryLevelListEditSelect fieldName="inventory_levels" inventoryLevels={priceBookItem.inventory_levels} updateInventoryLevels={inventory_levels => onFormDataChange("inventory_levels", inventory_levels)} />}
                                                showButton={false}
                                                errors={errors}
                                            ></ListSelectField>
                                        )
                                    }
                                    {
                                        priceBookItem.accounting_sync_status && (
                                            <BasicDisplayField
                                                fieldName="accounting_sync_status"
                                                fieldLabel="Accounting Sync Status"
                                                fieldValue={priceBookItem.accounting_sync_status.needs_syncing ? "Not Synced" : `Synced on ${this.renderSyncTime(priceBookItem.accounting_sync_status.last_synced_at, window.PREFERRED_TIMEZONE)}`}
                                            ></BasicDisplayField>
                                        )
                                    }
                                </Fragment>
                            )
                        }
                    </div>
                    {priceBookItem.pricebook_item_type && this.renderButtons()}
                </div>
            </div>
            {
                (mode === "EDIT_PRICEBOOKITEM" || mode === "REVIEW_PRICEBOOKITEM") && priceBookItem.track_inventory === true && (
                    <div className="accordion-wrapper">
                        <PriceBookItemFeedAccordion priceBookItemID={priceBookItem.id} />
                    </div>
                )
            }
            {
                (mode === "EDIT_PRICEBOOKITEM" || mode === "REVIEW_PRICEBOOKITEM") && priceBookItem.pricebook_item_type !== PriceBookItemTypes.tax && (
                    <div className="accordion-wrapper">
                        <PriceBookItemUsageHistoryAccordion priceBookItemID={priceBookItem.id} />
                    </div>
                )
            }
            </Fragment>
        )
    }

}

export default PriceBookItemForm;
