import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useUser from "@hooks/useUser"

import { CALENDAR_ROUTE } from "@routes/calendar"
import { CLIENTS_ROUTES } from "@routes/clients"
import { ESTIMATES_ROUTES } from "@routes/estimates"
import { HOME_ROUTE } from "@routes/home"
import { INVOICES_ROUTES } from "@routes/invoices"
import { JOBS_ROUTES } from "@routes/jobs"
import { PRICEBOOK_ITEMS_ROUTES } from "@routes/pricebook-items"
import { PURCHASE_ORDER_ROUTES } from "@routes/purchase-orders"
import { REPORTS_ROUTE } from "@routes/reports"
import { VENDOR_ROUTES } from "@routes/vendor"

import { APIDeckTaxStrategies, AccountingIntegrations } from "@constants/choices"

import useSlugExtractor from "./useSlugExtractor"

export type NavigationLink = {
    label: string
    path: string
    subLinks?: NavigationLink[]
    predicate?: (user: User) => boolean
    icon?: IconProp
    iconActive?: IconProp
}

export default function useNavigationLinks() {
    const { user } = useUser()

    const isUsingTaxRates =
        user?.service_company?.use_taxes &&
        !(
            user?.service_company?.accounting_integration === AccountingIntegrations.apideck &&
            user?.service_company?.apideck_tax_strategy !== APIDeckTaxStrategies.normal
        )

    const [
        homeRoute,
        calendarRoute,
        clientsListRoute,
        estimatesListRoute,
        jobsListRoute,
        invoicesListRoute,
        purchaseOrdersListRoute,
        vendorsListRoute,
        pricebookServicesListRoute,
        pricebookPartsOrMaterialsListRoute,
        pricebookMiscellaneousListRoute,
        pricebookDiscountsListRoute,
        pricebookTaxRatesListRoute,
        reportsRoute,
    ] = useSlugExtractor([
        HOME_ROUTE,
        CALENDAR_ROUTE,
        CLIENTS_ROUTES.LIST,
        ESTIMATES_ROUTES.LIST,
        JOBS_ROUTES.LIST,
        INVOICES_ROUTES.LIST,
        PURCHASE_ORDER_ROUTES.LIST,
        VENDOR_ROUTES.LIST,
        PRICEBOOK_ITEMS_ROUTES.SERVICE.LIST,
        PRICEBOOK_ITEMS_ROUTES.PART_OR_MATERIAL.LIST,
        PRICEBOOK_ITEMS_ROUTES.MISCELLANEOUS.LIST,
        PRICEBOOK_ITEMS_ROUTES.DISCOUNT.LIST,
        PRICEBOOK_ITEMS_ROUTES.TAX_RATES.LIST,
        REPORTS_ROUTE,
    ])

    const home: NavigationLink = {
        path: homeRoute,
        label: "Home",
        icon: icon({ name: "house", style: "regular", family: "sharp" }),
        iconActive: icon({ name: "house", style: "solid", family: "sharp" }),
    }

    const calendar: NavigationLink = {
        path: calendarRoute,
        label: "Calendar",
        predicate: (user: User) => user?.isServiceDispatcher ?? false,
        icon: icon({ name: "objects-align-left", style: "regular", family: "sharp" }),
        iconActive: icon({ name: "objects-align-left", style: "solid", family: "sharp" }),
    }

    const clients: NavigationLink = {
        path: clientsListRoute,
        label: "Clients",
        predicate: (user: User) => user?.isServiceDispatcher ?? false,
        icon: icon({ name: "user-circle", style: "regular", family: "sharp" }),
        iconActive: icon({ name: "user-circle", style: "solid", family: "sharp" }),
    }

    const estimates: NavigationLink = {
        path: estimatesListRoute,
        label: "Estimates",
        predicate: (user: User) => user?.isServiceDispatcher ?? false,
        icon: icon({ name: "file-lines", style: "regular", family: "sharp" }),
        iconActive: icon({ name: "file-lines", style: "solid", family: "sharp" }),
    }

    const jobs: NavigationLink = {
        path: jobsListRoute,
        label: "Jobs",
        icon: icon({ name: "wrench-simple", style: "regular", family: "sharp" }),
        iconActive: icon({ name: "wrench-simple", style: "solid", family: "sharp" }),
    }

    const invoices: NavigationLink = {
        path: invoicesListRoute,
        label: "Invoices",
        predicate: (user: User) => user?.isServiceDispatcher ?? false,
        icon: icon({ name: "circle-dollar", style: "regular", family: "sharp" }),
        iconActive: icon({ name: "circle-dollar", style: "solid", family: "sharp" }),
    }

    const purchase_orders: NavigationLink = {
        path: purchaseOrdersListRoute,
        label: "Purchase Orders",
        predicate: (user: User) => user?.isServiceDispatcher ?? false,
        icon: icon({ name: "basket-shopping", style: "regular", family: "sharp" }),
        iconActive: icon({ name: "basket-shopping", style: "solid", family: "sharp" }),
    }

    const vendors: NavigationLink = {
        path: vendorsListRoute,
        label: "Vendors",
        predicate: (user: User) => user?.isServiceDispatcher ?? false,
        icon: icon({ name: "store", style: "regular", family: "sharp" }),
        iconActive: icon({ name: "store", style: "solid", family: "sharp" }),
    }

    const pricebook: NavigationLink = {
        path: "",
        label: "Pricebook",
        predicate: (user: User) => user?.isServiceDispatcher ?? false,
        icon: icon({ name: "tag", style: "regular", family: "sharp" }),
        iconActive: icon({ name: "tag", style: "solid", family: "sharp" }),
        subLinks: [
            {
                label: "Services",
                path: pricebookServicesListRoute,
            },
            {
                label: "Parts & Materials",
                path: pricebookPartsOrMaterialsListRoute,
            },
            {
                label: "Miscellaneous",
                path: pricebookMiscellaneousListRoute,
            },
            {
                label: "Discounts",
                path: pricebookDiscountsListRoute,
            },
            ...(isUsingTaxRates
                ? [
                      {
                          label: "Tax Rates",
                          path: pricebookTaxRatesListRoute,
                      },
                  ]
                : []),
        ],
    }

    const reports: NavigationLink = {
        path: reportsRoute,
        label: "Reports",
        predicate: (user: User) =>
            (user?.isServiceDispatcher ?? false) && user.service_company.vizzly_dashboard_id !== "",
        icon: icon({ name: "chart-mixed", style: "regular", family: "sharp" }),
        iconActive: icon({ name: "chart-mixed", style: "solid", family: "sharp" }),
    }

    return {
        home,
        calendar,
        clients,
        estimates,
        jobs,
        invoices,
        purchase_orders,
        vendors,
        pricebook,
        reports,
    }
}
