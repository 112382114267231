import { icon as fontAwesomeIcon } from "@fortawesome/fontawesome-svg-core/import.macro"
import * as Popover from "@radix-ui/react-popover"
import clsx from "clsx"
import { useMemo } from "react"

import { Icon } from "@atoms"

import styles from "./SelectInput.module.scss"
import { SelectInputProps } from "./SelectInput.types"

export default function SelectInput(props: SelectInputProps) {
    const {
        icon,
        iconInReactNode,
        text,
        placeholder,
        showLabel = true,
        isOpen,
        isDisabled = false,
        className,
        isTabbable,
        size,
    } = props

    const desiredIcon = useMemo(() => {
        if (iconInReactNode) {
            return iconInReactNode
        } else if (icon) {
            return (
                <div className={styles.icon}>
                    <Icon icon={icon} size={14} />
                </div>
            )
        } else {
            return null
        }
    }, [icon, iconInReactNode])

    return (
        <Popover.Trigger asChild={true}>
            <button
                className={clsx(styles.base, className, {
                    [styles.open]: isOpen,
                    [styles.selected]: !!text,

                    [styles.sizeSm]: size === "sm",
                    [styles.sizeMd]: size === "md",
                    [styles.sizeLg]: size === "lg",
                })}
                disabled={isDisabled}
                tabIndex={isTabbable ? 0 : -1}
            >
                <div className={styles.iconAndText}>
                    {desiredIcon}
                    {showLabel && <div className={styles.text}>{text ? text : placeholder}</div>}
                </div>

                <div className={styles.arrow}>
                    <Icon
                        icon={fontAwesomeIcon({ name: "angle-down", style: "regular", family: "sharp" })}
                        size={12}
                    />
                </div>
            </button>
        </Popover.Trigger>
    )
}
