import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import VendorCreateContainer from "@legacy/vendors/VendorCreateContainer"

import useIsDesktop from "@hooks/useIsDesktop"
import useSlugExtractor from "@hooks/useSlugExtractor"
import useToastQueue from "@hooks/useToastQueue"

import { TopBar } from "@organisms"

import { AppFrame } from "@templates"

import { VENDOR_ROUTES } from "@routes/vendor"

export default function VendorEdit() {
    const isDesktop = useIsDesktop()

    const vendorId = window.VENDOR_ID

    const [listRoute, detailsRoute] = useSlugExtractor([VENDOR_ROUTES.LIST, VENDOR_ROUTES.DETAILS], vendorId)
    const { addToastToQueue } = useToastQueue()

    return (
        <AppFrame.Root pageId="VendorEdit">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle
                            label="Vendors"
                            icon={icon({ name: "store", style: "regular", family: "sharp" })}
                            iconActive={icon({ name: "store", style: "solid", family: "sharp" })}
                            destination={listRoute}
                            iconOnly={!isDesktop}
                        />
                        <TopBar.VerticalDivider />
                        <TopBar.PageSubtitle label="Edit Vendor" backDestination={detailsRoute} />
                    </TopBar.TopBarHeaderStart>
                </TopBar.Header>
            </TopBar.Root>
            <AppFrame.PageContent>
                <VendorCreateContainer formMode="EDIT_VENDOR" addToastToQueue={addToastToQueue} />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
