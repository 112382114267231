import { icon as macroIcon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"

import { Icon, NavigationLink } from "@atoms"

import styles from "./BreadcrumbItem.module.scss"
import { BreadcrumbItemProps } from "./BreadcrumbItem.types"

export default function BreadcrumbItem(props: BreadcrumbItemProps) {
    const { label, path, hasSubItems, onClick, isActive, isDisabled, isOpen, showSlash, icon, iconActive, iconOnly } =
        props

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        isDisabled ? event.preventDefault() : onClick?.(event)
    }

    return (
        <div className={styles.base}>
            <NavigationLink
                title={label}
                path={path}
                className={clsx(styles.button, {
                    [styles.disabled]: isDisabled,
                    [styles.open]: isOpen,
                    [styles.hasSubItems]: hasSubItems,
                    [styles.notClickable]: !onClick && !path,
                    [styles.iconOnly]: iconOnly,
                })}
                isActive={isActive}
                activeClassName={styles.active}
                onClick={handleClick}
                isDisabled={isDisabled}
                isTabbable={!!path || !!onClick}
            >
                {(isActive) => (
                    <div className={styles.buttonInnerContainer}>
                        {icon && iconActive ? (
                            <div className={styles.icon}>
                                <Icon size={16} icon={icon} iconOnActive={iconActive} isActive={true} />
                            </div>
                        ) : null}

                        {iconOnly ? null : (
                            <div className={styles.textAndChevron}>
                                <span className={styles.label}>{label}</span>{" "}
                            </div>
                        )}
                        {hasSubItems && (
                            <div className={styles.textAndChevron}>
                                <Icon
                                    size={12}
                                    icon={macroIcon({ name: "angle-down", style: "regular", family: "sharp" })}
                                    iconOnActive={macroIcon({ name: "angle-down", style: "solid", family: "sharp" })}
                                    isActive={isActive}
                                />
                            </div>
                        )}
                    </div>
                )}
            </NavigationLink>
            {showSlash && <span className={styles.slash}>/</span>}
        </div>
    )
}
