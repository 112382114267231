// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PopoverSubHeader-base__YYQoM{position:relative;padding:0 calc(var(--size-3) + var(--size-0_5));color:var(--gray-12);-webkit-user-select:none;-moz-user-select:none;user-select:none}.PopoverSubHeader-base__YYQoM.PopoverSubHeader-withBorderBottom__jt8Xr::before{position:absolute;right:0;bottom:0;left:0;height:var(--size-0_25);background-color:var(--gray-3);content:\"\"}.PopoverSubHeader-iconAndHeading__tPH4h{display:flex;gap:var(--size-1);align-items:center;padding:var(--size-2) 0}.PopoverSubHeader-title__hyrvR{font:var(--font-global-heading-02);font-feature-settings:var(--feature-settings-global-heading-02);letter-spacing:var(--letter-spacing-global-heading-02)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "PopoverSubHeader-base__YYQoM",
	"withBorderBottom": "PopoverSubHeader-withBorderBottom__jt8Xr",
	"iconAndHeading": "PopoverSubHeader-iconAndHeading__tPH4h",
	"title": "PopoverSubHeader-title__hyrvR"
};
export default ___CSS_LOADER_EXPORT___;
