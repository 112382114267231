// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JobTimelineViewZoom-base__en2It{display:flex;display:flex;gap:var(--size-1);align-items:center}.JobTimelineViewZoom-slider__kpPEl{width:var(--size-32)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "JobTimelineViewZoom-base__en2It",
	"slider": "JobTimelineViewZoom-slider__kpPEl"
};
export default ___CSS_LOADER_EXPORT___;
