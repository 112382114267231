import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"

import useIsDesktop from "@hooks/useIsDesktop"

import { ifSpaceOrEnter } from "@utils/keyboard"

import cardStyles from "@atoms/Card/Card.module.scss"
import { Icon, RadioItem } from "@atoms/index"

import { ReactComponent as DarkSchemeImage } from "@assets/images/app-dark-scheme-preview.svg"
import { ReactComponent as LightSchemeImage } from "@assets/images/app-light-scheme-preview.svg"
import { ReactComponent as SystemSchemeImage } from "@assets/images/app-system-scheme-preview.svg"

import { colorSchemes } from "@constants/colorSchemes"

import styles from "./AppearanceSelectorCard.module.scss"
import { AppearanceSelectorCardProps } from "./AppearanceSelectorCard.types"

export default function AppearanceSelectorCard(props: AppearanceSelectorCardProps) {
    const { isSelected, onClick, variant } = props

    const isDesktop = useIsDesktop()

    const handleButtonClick = () => {
        onClick(colorSchemes[variant])
    }

    const content = (() => {
        if (variant === "light") {
            return {
                label: "Light",
                description: "Bright environment optimized.",
                icon: icon({
                    name: "sun-bright",
                    family: "sharp",
                    style: "regular",
                }),
                image: <LightSchemeImage />,
            }
        } else if (variant === "dark") {
            return {
                label: "Dark",
                description: "Dark environment optimised.",
                icon: icon({
                    name: "moon",
                    family: "sharp",
                    style: "regular",
                }),
                image: <DarkSchemeImage />,
            }
        } else if (variant === "default") {
            return {
                label: "System",
                description: "Sync with your system.",
                icon: icon({
                    name: "desktop",
                    family: "sharp",
                    style: "regular",
                }),
                image: <SystemSchemeImage />,
            }
        }
    })()

    return (
        content && (
            <div
                // We can't this div as button because the radio Item will already render a button
                // and we will get errors from the react's validateDOMNesting
                role="button"
                tabIndex={0}
                className={clsx(cardStyles.base, styles.base, {
                    [styles.selected]: isSelected,
                })}
                onClick={handleButtonClick}
                onKeyDown={(e) => ifSpaceOrEnter(e, handleButtonClick)}
            >
                <div className={styles.iconRadioAndText}>
                    <div className={styles.iconAndRadio}>
                        <Icon size={isDesktop ? 16 : 20} icon={content.icon} />
                        <div className={styles.radio}>
                            <RadioItem
                                size="sm"
                                value={variant}
                                checked={isSelected}
                                // because we want the div role button to receive focus instead
                                tabIndex={-1}
                            />
                        </div>
                    </div>
                    <div className={styles.labelAndDescription}>
                        <span className={styles.label}>{content.label}</span>
                        <span className={styles.description}>{content.description}</span>
                    </div>
                </div>
                <div className={styles.image}>{content.image}</div>
            </div>
        )
    )
}
