import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import { NavigationItem } from "@molecules"

export default function HelpCenter() {
    return (
        <NavigationItem
            onClick={() => window.Helpkit.show("sidebar")}
            variant="secondary"
            label="Help Center"
            icon={icon({ name: "question-circle", style: "regular", family: "sharp" })}
            iconActive={icon({ name: "question-circle", style: "solid", family: "sharp" })}
        />
    )
}
