import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useUser from "@hooks/useUser"

import { countObjectBooleanProperties, countObjectProperties } from "@utils/countObjectAttributes"

import { NotificationTopicCard } from "@molecules/index"

import { useNotificationPreferencesQuery } from "@pages/Settings/SettingsNotifications/SettingsNotifications.store"

import { SETTINGS_ROUTES } from "@routes/settings"

import styles from "./NotificationTopicsCards.module.scss"

export default function NotificationTopicsCards() {
    const { allTopics } = useNotificationPreferencesQuery()

    const { user } = useUser()

    return (
        <div className={styles.base}>
            {(user?.isServiceDispatcher || user?.is_working) && (
                <NotificationTopicCard
                    icon={icon({ name: "wrench-simple", style: "solid", family: "sharp" })}
                    title="Jobs"
                    notificationsInUse={
                        (user?.is_working ? countObjectBooleanProperties(allTopics.jobs.assignment) : 0) +
                        (user?.isServiceDispatcher ? countObjectBooleanProperties(allTopics.jobs.manager) : 0)
                    }
                    totalOfNotifications={
                        (user?.is_working ? countObjectProperties(allTopics.jobs.assignment) : 0) +
                        (user?.isServiceDispatcher ? countObjectProperties(allTopics.jobs.manager) : 0)
                    }
                    path={SETTINGS_ROUTES.notificationsJobs}
                />
            )}

            {user?.isServiceDispatcher && (
                <NotificationTopicCard
                    icon={icon({ name: "file-lines", style: "solid", family: "sharp" })}
                    title="Estimates"
                    notificationsInUse={countObjectBooleanProperties(allTopics.estimates)}
                    totalOfNotifications={countObjectProperties(allTopics.estimates)}
                    path={SETTINGS_ROUTES.notificationsEstimates}
                />
            )}
            {user?.isServiceDispatcher && (
                <NotificationTopicCard
                    icon={icon({ name: "tag", style: "solid", family: "sharp" })}
                    title="Inventory"
                    notificationsInUse={countObjectBooleanProperties(allTopics.inventory)}
                    totalOfNotifications={countObjectProperties(allTopics.inventory)}
                    path={SETTINGS_ROUTES.notificationsInventory}
                />
            )}
        </div>
    )
}
