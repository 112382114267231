// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@assets/images/avatar-mask-overlapping.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Icon-base__NAgw9{display:flex;align-items:center;justify-content:center;width:var(--container-size);height:var(--container-size)}.Icon-overlapping__PwSJ1{--mask: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");margin-left:-0.2em;mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");mask-image:var(--mask);-webkit-mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");-webkit-mask-image:var(--mask);-webkit-mask-position:top left;mask-position:top left;-webkit-mask-repeat:no-repeat;mask-repeat:no-repeat;-webkit-mask-size:var(--size-full);mask-size:var(--size-full)}.Icon-overlapping__PwSJ1:last-child{--mask: unset}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "Icon-base__NAgw9",
	"overlapping": "Icon-overlapping__PwSJ1"
};
export default ___CSS_LOADER_EXPORT___;
