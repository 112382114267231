import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { convertToKebabCase, formatLocalTime, snakeCaseToTitleCase } from "../../core/utils/utils";

dayjs.extend(isSameOrAfter)

function determineScheduledBucket(job) {
    const eta = dayjs(job.estimated_arrival_time)
    const endOfToday = dayjs().startOf("day").add(1, "day")
    const endOfTomorrow = dayjs().startOf("day").add(2, "day")
    const endOfThisWeek = dayjs().startOf("isoWeek").add(1, "week")
    const endOfNextWeek = dayjs().startOf("isoWeek").add(2, "week")
    const endOfThisMonth = dayjs().startOf("month").add(1, "month")

    // Past Due
    if (eta.isBefore()) {
        return "PAST_DUE"
    }
    // Today
    else if (eta.isSameOrAfter() && eta.isBefore(endOfToday)) {
        return "TODAY"
    }
    // Tomorrow
    else if (eta.isSameOrAfter(endOfToday) && eta.isBefore(endOfTomorrow)) {
        return "TOMORROW"
    }
    // Later This Week
    else if (eta.isSameOrAfter(endOfTomorrow) && eta.isBefore(endOfThisWeek)) {
        return "LATER_THIS_WEEK"
    }
    // Next Week
    else if (eta.isSameOrAfter(endOfThisWeek) && eta.isBefore(endOfNextWeek)) {
        return "NEXT_WEEK"
    }
    // Later This Month
    else if (eta.isSameOrAfter(endOfThisWeek) && eta.isBefore(endOfThisMonth)) {
        return "LATER_THIS_MONTH"
    }
    else {
        return "FUTURE"
    }
}

export const getJobStatusOrSubStatusLabel = (job) => {
    return job.substatus_label ? job.substatus_label : job.status_label
}

export const getJobStatusChipLabel = (job) => {
    return getJobStatusOrSubStatusLabel(job) === "Upcoming" ? `Upcoming - ${snakeCaseToTitleCase(determineScheduledBucket(job))}` : getJobStatusOrSubStatusLabel(job)
}

export const getJobStatusColorClass = (job) => {
    return job.status_color_override ? job.status_color_override : `jobs-${convertToKebabCase(job.status_label)}`
}

function getJobStatusBarLabel(job) {
    let statusBarLabel = getJobStatusOrSubStatusLabel(job)

    if (job.status_label === "Draft") {
        statusBarLabel = "Draft Preview"
    }
    else if (job.status_label === "Uninvoiced") {
        statusBarLabel = "Completed and Uninvoiced"
    }
    else if (job.status_label === "Unestimated") {
        statusBarLabel = "Completed and Unestimated"
    }
    else if (job.status_label === "Finalized") {
        if (job.is_job_walk) {
            statusBarLabel = "Completed and Estimated"
        }
        else {
            statusBarLabel = "Completed and Invoiced"
        }
    }

    return statusBarLabel
}

function getUnprefixedJobTitle(title) {
    return title.replace(/^\[.+?\] /, "")
}

export function getDefaultRecurrenceWeekdays(job, weekdayOptions) {
    let defaultOptions = []

    const recurrence = job.weekly_recurrence || []

    recurrence.forEach(weekday => {
        let defaultOption = weekdayOptions.find(weekdayOption => weekdayOption.value === weekday)
        if (defaultOption) {
            defaultOptions.push(defaultOption)
        }
    })

    return defaultOptions
}

function getDefaultAssignedTechnicians(job, workingTechnicianOptions) {
    let defaultOptions = []

    const assignedTechnicians = job.assigned_technicians || []

    assignedTechnicians.forEach(technicianID => {
        let defaultOption = workingTechnicianOptions.find(technicianOption => technicianOption.value === technicianID)
        if (defaultOption) {
            defaultOptions.push(defaultOption)
        }
    })

    return defaultOptions
}

function renderJobTime(jobTime, preferredTimezone, alertPastDue=false) {
    const { localDateTimeString } = formatLocalTime(jobTime, preferredTimezone, false, true, false, true)
    const isPastDue = alertPastDue && dayjs(jobTime).isBefore()

    return <span className={isPastDue ? "text-overdue" : ""}>{localDateTimeString}</span>
}

export { determineScheduledBucket, getDefaultAssignedTechnicians, getJobStatusBarLabel, getUnprefixedJobTitle, renderJobTime };
