import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { ClientTypes } from "@legacy/core/utils/enums"

import { PRICEBOOK_ITEM_TYPE_MAP } from "@constants/mappings"

export function getSearchResultIcon(indexName: ObjectIndexName): IconDefinition {
    switch (indexName) {
        case "Estimate":
            return icon({ name: "file-lines", style: "regular", family: "sharp" })
        case "Job":
            return icon({ name: "wrench-simple", style: "regular", family: "sharp" })
        case "Invoice":
            return icon({ name: "circle-dollar", style: "regular", family: "sharp" })
        case "PurchaseOrder":
            return icon({ name: "basket-shopping", style: "regular", family: "sharp" })
        case "Vendor":
            return icon({ name: "store", style: "regular", family: "sharp" })
        case "Client":
            return icon({ name: "user-circle", style: "regular", family: "sharp" })
        case "PriceBookItem":
            return icon({ name: "tag", style: "regular", family: "sharp" })
    }
}

export function getSearchResultPrimaryText(indexName: ObjectIndexName, index: ObjectsIndex): string {
    switch (indexName) {
        case "Estimate":
            return index.custom_id || index.id
        case "Job":
            return index.custom_id || index.id
        case "Invoice":
            return index.custom_id || index.id
        case "PurchaseOrder":
            return index.custom_id || index.id
        case "Vendor":
            return index.name
        case "Client":
            return index.name
        case "PriceBookItem":
            return index.description
    }
}

export function getSearchResultSecondaryText(indexName: ObjectIndexName, index: ObjectsIndex): string {
    switch (indexName) {
        case "Estimate":
            return index.client
        case "Job":
            return index.service_name
        case "Invoice":
            return index.client
        case "PurchaseOrder":
            return index.vendor
        case "Vendor":
            return ""
        case "Client":
            return index.client_type === ClientTypes.business ? "Business" : "Individual"
        case "PriceBookItem":
            return PRICEBOOK_ITEM_TYPE_MAP[index.pricebook_item_type]
    }
}
