import { ColumnId } from "@organisms/Table/Table.types"
import { TableViewMenuColumn } from "@organisms/Table/TableViewMenu/TableViewMenu.types"

import { sortingEnabledByColumnId } from "@constants/mappings/table/sortingEnabledByColumnId.mapping"

import getColumnNameByColumnId from "./getColumnNameByColumnId"

export default function getSortingEnabledColumnsByIds(
    columnIds: ColumnId[],
    objectType: ObjectType,
): TableViewMenuColumn[] {
    return columnIds
        .filter((columnId) => sortingEnabledByColumnId[columnId])
        .map((columnId) => {
            const columnName = getColumnNameByColumnId(columnId, objectType)
            return {
                value: columnId,
                label: columnName ?? "",
            }
        })
}
