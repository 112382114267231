import useUser from '@hooks/useUser';
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import ObjectListCard from '../../core/components/ObjectListCard';
import AlertingRenderer from '../../core/renderers/AlertingRenderer';
import CustomIDRenderer from '../../core/renderers/CustomIDRenderer';
import DateTimeRenderer from '../../core/renderers/DateTimeRenderer';
import LastSeenRenderer from '../../core/renderers/LastSeenRenderer';
import StatusChipRenderer from '../../core/renderers/StatusChipRenderer';
import TagListRenderer from '../../core/renderers/TagListRenderer';

dayjs.extend(timezone)


function PurchaseOrderListCard(props) {
    const { data, showState } = props
    const purchaseOrder = data

    const { user:currentUser } = useUser()

    const footerContext = {
        label: null,
        value: null
    }

    if (!["Pending", "Open"].includes(purchaseOrder.state_label)) {
        footerContext.label = "Est. Arrival:"
        footerContext.value = (
            <AlertingRenderer alertCondition={purchaseOrder.state_label === "In Transit" && purchaseOrder.estimated_arrival_time && dayjs.tz(purchaseOrder.estimated_arrival_time, currentUser.service_company.preferred_timezone).isBefore()}>
                <DateTimeRenderer type="datetime" value={purchaseOrder.estimated_arrival_time} options={{addYear: true}} />
            </AlertingRenderer>
        )
    }

    return <ObjectListCard
        objectName="Purchase Order"
        objectID={<CustomIDRenderer instance={purchaseOrder} />}
        showState={showState}
        stateLabel={purchaseOrder.state_label}
        headerContext={<LastSeenRenderer value={purchaseOrder.last_viewed} />}
        statusChip={<StatusChipRenderer value={purchaseOrder.status_label} />}
        bodyTitle={purchaseOrder.vendor_name}
        bodySubtextPrimary={<TagListRenderer tags={purchaseOrder.associated_jobs} />}
        footerContextLabel={footerContext.label}
        footerContextValue={footerContext.value}
        footerPrimary={`${purchaseOrder.item_count} items`}
    />
}

export default PurchaseOrderListCard;
