// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CounterBadge-base__CZC1X{display:flex;gap:var(--size-0_25);align-items:center;background:var(--gray-a3);-webkit-user-select:none;-moz-user-select:none;user-select:none;font:var(--font-global-caption-medium-475);font-feature-settings:var(--feature-settings-global-caption-medium-475);letter-spacing:var(--letter-spacing-global-caption-medium-475)}.CounterBadge-sizeMd__z2mcu{padding:var(--size-0_5) var(--size-1_5);border-radius:var(--border-radius-1)}.CounterBadge-sizeMd__z2mcu .CounterBadge-current__JZsvI{font:var(--font-global-caption-medium-475);font-feature-settings:var(--feature-settings-global-caption-medium-475);letter-spacing:var(--letter-spacing-global-caption-medium-475)}.CounterBadge-sizeMd__z2mcu .CounterBadge-total__Eyb94{font:var(--font-global-caption-medium-375);font-feature-settings:var(--feature-settings-global-caption-medium-375);letter-spacing:var(--letter-spacing-global-caption-medium-375)}.CounterBadge-sizeLg__HMWYw{padding:var(--size-0_5) var(--size-2);border-radius:var(--border-radius-1_5)}.CounterBadge-sizeLg__HMWYw .CounterBadge-current__JZsvI{color:var(--gray-12);font:var(--font-global-body-475);font-feature-settings:var(--feature-settings-global-body-475);letter-spacing:var(--letter-spacing-global-body-475)}.CounterBadge-sizeLg__HMWYw .CounterBadge-total__Eyb94{color:var(--gray-a10);font:var(--font-global-body-375);font-feature-settings:var(--feature-settings-global-body-375);letter-spacing:var(--letter-spacing-global-body-375)}.CounterBadge-current__JZsvI{color:var(--gray-12)}.CounterBadge-total__Eyb94{color:var(--gray-a10)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "CounterBadge-base__CZC1X",
	"sizeMd": "CounterBadge-sizeMd__z2mcu",
	"current": "CounterBadge-current__JZsvI",
	"total": "CounterBadge-total__Eyb94",
	"sizeLg": "CounterBadge-sizeLg__HMWYw"
};
export default ___CSS_LOADER_EXPORT___;
