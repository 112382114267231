import { MutationFunction } from "@tanstack/react-query"
import Cookies from "js-cookie"

import { PASSWORD_ENDPOINTS } from "@endpoints/password"

import { HasPasswordResponse } from "./SetPasswordDialogsTrigger.types"

const getHasPassword: MutationFunction<HasPasswordResponse> = async (): Promise<HasPasswordResponse> => {
    const response = await fetch(PASSWORD_ENDPOINTS.HAS_PASSWORD, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
        },
    })

    if (!response.ok) {
        throw new Error("Could not get password status.")
    }

    return response.json() as Promise<HasPasswordResponse>
}

export { getHasPassword }
