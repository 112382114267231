import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useIsDesktop from "@hooks/useIsDesktop"

import navigatorVibrate from "@utils/navigatorVibrate"

import { ButtonProps } from "@atoms/Button/Button.types"
import { Button } from "@atoms/index"

import toast from "@molecules/Toast/Toast"

import useOTPForm from "@organisms/OTPForm/OTPForm.store"

export default function OTPPasteButton(props: Partial<ButtonProps>) {
    const isDesktop = useIsDesktop()

    const { setOtpInputValue, setIsCodeInvalid, isVerifyingCode } = useOTPForm()

    const handlePaste = async () => {
        try {
            const clipboardValue = await navigator.clipboard.readText()

            const clipboardValueContainOnlyNumbers = /^\d{6}$/.test(clipboardValue)

            if (clipboardValueContainOnlyNumbers) {
                setOtpInputValue(clipboardValue)
            } else {
                navigatorVibrate()
                setIsCodeInvalid(true)
            }
        } catch (error) {
            toast({
                size: "md",
                type: "error",
                title: "Could not paste",
            })

            console.error(error)
        }
    }

    return (
        <Button
            leftIcon={icon({
                name: "paste",
                family: "sharp",
                style: "regular",
            })}
            colorScheme="gray"
            variant="subtle"
            size={isDesktop ? "md" : "xl"}
            onClick={() => void handlePaste()}
            isDisabled={isVerifyingCode}
            {...props}
        >
            Paste
        </Button>
    )
}
