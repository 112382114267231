// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppStartOfWeekSelector-button__mr7nX{width:-moz-fit-content;width:fit-content}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "AppStartOfWeekSelector-button__mr7nX"
};
export default ___CSS_LOADER_EXPORT___;
