import { MutationFunction } from "@tanstack/react-query"
import Cookies from "js-cookie"

import { USER_ENDPOINT } from "@endpoints/user"

const patchColorMode: MutationFunction<User, User["color_mode"]> = async (
    colorModePreference: User["color_mode"],
): Promise<User> => {
    const response = await fetch(USER_ENDPOINT, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
        },
        body: JSON.stringify({ color_mode: colorModePreference }),
    })

    if (!response.ok) {
        throw new Error("There was an error while trying to update the user color mode.")
    }

    return (await response.json()) as User
}

export { patchColorMode }
