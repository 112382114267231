// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KeywordSearch-base__RM7PQ{width:var(--size-full)}@media only screen and (min-width: 64rem){.KeywordSearch-base__RM7PQ:not(.KeywordSearch-alwaysFullWidth__kPeL7){width:var(--size-56)}}.KeywordSearch-innerContainer__zomHq{display:flex;gap:var(--size-1_5);align-items:center;padding:var(--size-0) var(--size-2_5);overflow:hidden;color:var(--gray-11);background-color:var(--gray-4);border-radius:var(--border-radius-1_5)}.KeywordSearch-innerContainer__zomHq:focus-within{color:var(--gray-12)}.KeywordSearch-input__QQvmU{width:var(--size-full);height:var(--size-9);padding:var(--size-0);color:var(--gray-11);background-color:rgba(0,0,0,0);border:var(--size-0);font:var(--font-mobile-body-475);font-feature-settings:var(--feature-settings-mobile-body-475);letter-spacing:var(--letter-spacing-mobile-body-475)}@media only screen and (min-width: 64rem){.KeywordSearch-input__QQvmU{font:var(--font-global-body-475);font-feature-settings:var(--feature-settings-global-body-475);letter-spacing:var(--letter-spacing-global-body-475)}}.KeywordSearch-input__QQvmU::-moz-placeholder{color:var(--gray-9)}.KeywordSearch-input__QQvmU::placeholder{color:var(--gray-9)}.KeywordSearch-input__QQvmU:focus-visible{border:var(--size-0);outline:none}@media only screen and (min-width: 64rem){.KeywordSearch-input__QQvmU{height:var(--size-8)}}.KeywordSearch-iconContainer__Br3Me{flex-shrink:0}.KeywordSearch-eraseButton__dVHav{margin-left:auto}.KeywordSearch-typing__Erh1R{color:var(--gray-12)}.KeywordSearch-typing__Erh1R .KeywordSearch-input__QQvmU{color:var(--gray-12)}.KeywordSearch-typed__HmeLr{color:var(--gray-11)}.KeywordSearch-typed__HmeLr .KeywordSearch-input__QQvmU{color:var(--gray-12)}.KeywordSearch-typing__Erh1R,.KeywordSearch-typed__HmeLr{padding-right:var(--size-0)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "KeywordSearch-base__RM7PQ",
	"alwaysFullWidth": "KeywordSearch-alwaysFullWidth__kPeL7",
	"innerContainer": "KeywordSearch-innerContainer__zomHq",
	"input": "KeywordSearch-input__QQvmU",
	"iconContainer": "KeywordSearch-iconContainer__Br3Me",
	"eraseButton": "KeywordSearch-eraseButton__dVHav",
	"typing": "KeywordSearch-typing__Erh1R",
	"typed": "KeywordSearch-typed__HmeLr"
};
export default ___CSS_LOADER_EXPORT___;
